import CityEntity from "../../../domain/entities/CityEntity"
import ZoneEntity from "../../../domain/entities/ZoneEntity";
import ZoneDto from "./ZoneDto";

const fromJSON = (data: any): CityEntity => {
    const zones = data.zones ?  data.zones.map((item: any) => ZoneDto.fromJSON(item)) : [];
    const neighborhoods = zones.flatMap((zone: ZoneEntity) => zone.neighborhoods ?? []);
    return {
        id: data.fake_id,
        name: data.name,
        zones: zones,
        neighborhoods: neighborhoods,
    }
}

export default {
    fromJSON,
}