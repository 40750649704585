import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import GetPropertyByIdApiImpl from "./GetPropertyByIdApiImpl";
import IsPropertySeenApiImpl from "./IsPropertySeenApiImpl";
import MarkAsSeenPropertyApiImpl from "./MarkAsSeenPropertyApiImpl";

const GetPropertyAndCheckIfISeenApiImpl = async (id: string, hasSeenKey: string): Promise<PropertyEntity | undefined> => {
    const hasBeenSeen = await IsPropertySeenApiImpl(id, hasSeenKey);
    if (!hasBeenSeen) {
        MarkAsSeenPropertyApiImpl(id, hasSeenKey);
    }
    const response = await GetPropertyByIdApiImpl(id, !hasBeenSeen);
    return response;
}

export default GetPropertyAndCheckIfISeenApiImpl;