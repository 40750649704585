import { injectable } from "inversify";
import PropertyRepository from "../../../domain/repositories/PropertyRepository";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import LocationEntity from "../../../domain/entities/LocationEntity";
import PaginateResultEntity from "../../../domain/entities/PaginateResultEntity";
import PropertyEntity from "../../../domain/entities/PropertyEntity";
import MultimediaEntity, { MultimediaEntityType } from "../../../domain/entities/MultimediaEntity";
import MultimediaInterestPointEntity from "../../../domain/entities/MultimediaInterestPointEntity";
import { personFake } from "../blog/BlogRepositoryFake";
import { neighborhoodsFake } from "../mastersTables/MastersTablesRepositoryFake";
import Testing from "../../../presentation/utils/Testing";
import TypeOfBusinessEntity from "../../../domain/entities/TypeOfBusinessEntity";

const typeOfBusinessFake: TypeOfBusinessEntity[] = [{
    id: '1',
    name: 'Venta',
    color: 'var(--color-orange)',
}, {
    id: '2',
    name: 'Arriendo',
    color: 'var(--color-blue)',
}];
const multimediaInterestPointsFake: MultimediaInterestPointEntity = {
    interestPointName: "Cocina",
    positionX: 10,
    positionY: 10,
}
const multimediaPropertyFake: MultimediaEntity = {
    id: "1",
    interestsPoints: Array.from({ length: 5 }, (_, i) => {
        return { ...multimediaInterestPointsFake, id: `${i}` }
    }),
    type: MultimediaEntityType.image,
    url: "https://lac.com.co/data/properties/MQ==-Paraiso_de_comfandi_C/frtjen1QuBMkPvTk7Gt4VVPuA3Cr3AFKpyJhoIRN.jpg",
};

export const locationFake: LocationEntity = {
    mapsLat: 3.4909365,
    mapsLong: -76.5069223,
    id: "1",
    address: "Cra 9 # 9-09",
    neighborhood: neighborhoodsFake[0],
    propertyId: "1",
    apartment: "101",
    mapsAddress: "Cra 9 # 9-09",
    mapsName: "Cra 9 # 9-09",
    placeId: "23232",
}
const propertyFake: PropertyEntity = {
    id: "1",
    name: "Casa en el bosque",
    description: "Casa en el bosque con vista al lago",
    privateDescription: "Casa en el bosque con vista al lago private",
    restrooms: 3,
    rooms: 4,
    garage: 2,
    area: 200,
    privateArea: 150,
    buildedArea: 180,
    typeKitchen: "Integral",
    buildYear: 2020,
    stratum: 4,
    floor: 3,
    floors: 4,
    parkings: 2,
    newProperty: true,
    outstanding: true,
    priceAdmin: 500000,
    likedCount: 20,
    seenCount: 100,
    multimedias: Array.from({ length: 5 }, (_, i) => {
        return { ...multimediaPropertyFake, id: `${i}` }
    }),
    location: locationFake,
    typeProperty: {
        id: "1",
        name: "Casa"
    },
    contact: personFake,
    liked: true,
    seen: false,
    vis: true,
    businesses: [
        {
            price: 300000000,
            typeOfBusiness: typeOfBusinessFake[0],
        },
        {
            price: 3000000,
            typeOfBusiness: typeOfBusinessFake[1],
        }
    ]
}

locationFake.property = propertyFake;

@injectable()
class PropertyRepositoryFake implements PropertyRepository {
    oldSearchProperties = async (params: { word: string; page: number; itemsPerPage: number; typeOfProperty?: string | undefined; typeOfBusiness?: string | undefined; isNew?: boolean | undefined; cityId?: string | undefined; zoneId?: string | undefined; neighborhoodId?: string | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }): Promise<PaginateResultEntity<PropertyEntity>> => {
        await Testing.sleeper(1000);
        if (params.page > 3) {
            return {
                data: [],
                page: params.page,
                total: 3 * params.itemsPerPage,
                totalPage: 3
            }
        } else {
            return {
                data: Array.from({ length: params.itemsPerPage }, (_, i) => {
                    return { ...propertyFake, id: `${i}` }
                },),
                page: params.page,
                total: 3 * params.itemsPerPage,
                totalPage: params.itemsPerPage,
            }

        }
    }
    searchProperties = async (params: {
        word: string, page: number, itemsPerPage: number,
        tagsId: string[],
        cityId?: string | undefined, zoneIds: string[], neighborhoodIds: string[],
        priceRange?: { min: number, max: number },
        rooms?: string[], restrooms?: string[],
        metersRange?: { min: number, max: number },
        garages?: string[], stratum?: string[],
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        sortBy: string
    }): Promise<PaginateResultEntity<PropertyEntity>> => {
        await Testing.sleeper(1000);
        if (params.page > 3) {
            return {
                data: [],
                page: params.page,
                total: 3 * params.itemsPerPage,
                totalPage: 3
            }
        } else {
            return {
                data: Array.from({ length: params.itemsPerPage }, (_, i) => {
                    return { ...propertyFake, id: `${i}` }
                },),
                page: params.page,
                total: 3 * params.itemsPerPage,
                totalPage: params.itemsPerPage,
            }

        }
    }
    getPropertyById = async (id: string): Promise<PropertyEntity | undefined> => {
        await Testing.sleeper(1000);
        return propertyFake;
    }
    likeProperty = async (property: PropertyEntity): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    unLikeProperty = async (id: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getLikedProperties = async (): Promise<PropertyEntity[]> => {
        await Testing.sleeper(1000);
        return Array.from({ length: 5 }, (_, i) => {
            return { ...propertyFake, id: `${i}` }
        });
    }
    getOutstandedAndRecentProperties = async (): Promise<Either<ExceptionEntity, { outstanded: PropertyEntity[]; recent: PropertyEntity[]; mostLiked: PropertyEntity[]; }>> => {
        await Testing.sleeper(1000);
        return right({
            outstanded: Array.from({ length: 5 }, (_, i) => {
                return { ...propertyFake, id: `${i}` }
            }),
            recent: Array.from({ length: 5 }, (_, i) => {
                return { ...propertyFake, id: `${i}` }
            }),
            mostLiked: Array.from({ length: 5 }, (_, i) => {
                return { ...propertyFake, id: `${i}` }
            }),
        });
    }
    getAllPropertiesLocations = async (paramsSearch: { word: string; page: number; itemsPerPage: number; typeOfPropertiesId: string[]; typeOfBusinessId: string[]; isNew?: boolean | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }, mapLimits: { latMax: number; latMin: number; longMax: number; longMin: number; }): Promise<LocationEntity[]> => {
        await Testing.sleeper(1000);
        return Array.from({ length: 5 }, (_, i) => {
            return { ...locationFake, id: `${i}` }
        });
    }
    applyToRentProperty = async (params: { message: string; propertyId: string; rentSolicitudeFile: File; tenant: { name: string; lastname: string; email: string; dial: string; phone: string; identification: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; }; coDebtor: { name: string; lastname: string; email: string; dial: string; phone: string; identification: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; }; }): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }

}

export default PropertyRepositoryFake;