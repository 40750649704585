import { injectable } from "inversify";
import ContactRepository from "../../../domain/repositories/ContactRepository";
import ContactRepositoryFake from "./ContactRepositoryFake";
import { Either } from "fp-ts/lib/Either";
import ContactInfoEntity from "../../../domain/entities/ContactInfoEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SendContactApiImpl from "./api/impl/SendContactApiImpl";
import SendContactWithPropertyApiImpl from "./api/impl/SendContactWithPropertyApiImpl";
import GetContactPreferencesApiImpl from "./api/impl/GetContactPreferencesApiImpl";
import StoreContactPreferencesApiImpl from "./api/impl/StoreContactPreferencesApiImpl";

@injectable()
class ContactRepositoryImpl implements ContactRepository {
    PREFERENCES_KEY: string = 'contact_preferences';
    store = (contactInfo: ContactInfoEntity, subscribe: boolean): Promise<Either<ExceptionEntity, void>> => SendContactApiImpl(contactInfo, subscribe);
    storeContactMeForProperty = (contactInfo: ContactInfoEntity, subscribe: boolean, propertyId: string): Promise<Either<ExceptionEntity, void>> => SendContactWithPropertyApiImpl(contactInfo, subscribe, propertyId);
    getContactPreferences = (): Promise<ContactInfoEntity | undefined> => GetContactPreferencesApiImpl(this.PREFERENCES_KEY);
    storeContactPreferences = (contactInfo: ContactInfoEntity): Promise<Either<ExceptionEntity, void>> => StoreContactPreferencesApiImpl(contactInfo, this.PREFERENCES_KEY);
    
}

export default ContactRepositoryImpl;