import { FC, useContext } from 'react';
import "./TermsAndConditionPageStyles.scss";
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';

const _EnglishTC = () => {
    return <>
        <div>
            <h2 className='h3'>Content</h2>
            <ol>
                <ul>
                    <li><a href="#welcome">Welcome</a></li>
                    <li><a href="#license">License of Use</a></li>
                    <li><a href="#acceptable_use">Acceptable Use</a></li>
                    <li><a href="#access_restrictions">Access Restrictions</a></li>
                    <li><a href="#limitation_indemnification">Limitation of Liability and Indemnity</a></li>
                    <li><a href="#privacy_policy">Privacy Policy</a></li>
                    <li><a href="#testing_status">Testing Status and Payment Receipts</a></li>
                    <li><a href="#variation">Variation</a></li>
                    <li><a href="#assignment">Assignment</a></li>
                    <li><a href="#severability">Severability</a></li>
                    <li><a href="#applicable_law">Applicable Law and Jurisdiction</a></li>
                </ul>
            </ol>
        </div>
        <div>
            <h2 id="welcome">Welcome</h2>
            <p>Welcome to LAC real estate advisory. These terms and conditions govern your use of our website; by accessing this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our website.</p>

            <h2 id="license">License of Use</h2>
            <p>Unless otherwise stated, LAC real estate advisory and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>
            <p>You may view, download for caching purposes only, and print pages, files or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>
            <p>You must not:</p>
            <ul>
                <li>Republish material from this website (including republication on another website).</li>
                <li>Sell, rent, or sub-license material from the website.</li>
                <li>Show any material from the website in public.</li>
                <li>Reproduce, duplicate, copy, or otherwise exploit material on this website for commercial purposes.</li>
                <li>Edit or otherwise modify any material on the website.</li>
                <li>Redistribute material from this website, except for content specifically and expressly made available for redistribution.</li>
            </ul>

            <h2 id="acceptable_use">Acceptable Use</h2>
            <p>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, fraudulent, or harmful, or in connection with any unlawful, fraudulent, or harmful purpose or activity.</p>
            <p>You must not use this website to copy, store, host, transmit, send, use, publish, or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or other malicious software.</p>
            <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction, and data harvesting) on or in relation to this website without our express written consent.</p>
            <p>You must not use this website to transmit or send unsolicited commercial communications.</p>

            <h2 id="access_restrictions">Access Restrictions</h2>
            <p>Access to certain areas of this website is restricted. We reserve the right to restrict access to other areas of this website, or indeed this entire website, at our discretion.</p>
            <p>If we provide you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential.</p>

            <h2 id="limitation_indemnification">Limitation of Liability and Indemnity</h2>
            <p>Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up-to-date.</p>
            <p>We will not be liable to you (whether under the law of contract, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:</p>
            <p>You hereby indemnify and undertake to keep indemnified LAC real estate advisory and its officers, directors, employees, agents, and representatives from and against any and all claims, liabilities, damages, losses, costs, and expenses, including legal fees, that arise or result from your improper use of the website, your violation of these terms and conditions, or your violation of any rights of another.</p>

            <h2 id="privacy_policy">Privacy Policy</h2>
            <p>Our privacy policy, which sets out how we will handle your information, can be found elsewhere on our website. By agreeing to these terms and conditions, you are also agreeing to and understand our privacy policy.</p>

            <h2 id="testing_status">Testing Status and Payment Receipts</h2>
            <p>The website and app are currently in testing. Payment receipts may be sent but may fail. In case of doubts, it is recommended to verify the physical documents.</p>

            <h2 id="electronic_billing">Electronic Billing</h2>
            <p>Electronic invoices will not be generated automatically. They can be requested directly from LAC.</p>

            <h2 id="variation">Variation</h2>
            <p>We may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of our website from the date of the publication of the revised terms and conditions on our website. Please check this page regularly to ensure you are familiar with the current version.</p>

            <h2 id="assignment">Assignment</h2>
            <p>We may transfer, sub-contract, or otherwise deal with our rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>

            <h2 id="severability">Severability</h2>
            <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect to the fullest extent permitted by law.</p>

            <h2 id="applicable_law">Applicable Law and Jurisdiction</h2>
            <p>These terms and conditions will be governed by and construed in accordance with the law, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts.</p>
        </div>

    </>

}

const _SpanishTC = () => {
    return <>
        <div>
            <h2 className='h3'>Contenido</h2>
            <ol>
                <ul>
                    <li><a href="#bienvenida">Bienvenida</a></li>
                    <li><a href="#licencia">Licencia de Uso</a></li>
                    <li><a href="#uso_aceptable">Uso Aceptable</a></li>
                    <li><a href="#restricciones_acceso">Restricciones de Acceso</a></li>
                    <li><a href="#limitaciones_indemnizacion">Limitaciones de Responsabilidad e Indemnización</a></li>
                    <li><a href="#politica_privacidad">Política de Privacidad</a></li>
                    <li><a href="#estado_pruebas">Estado de Pruebas y Comprobantes de Pago</a></li>
                    <li><a href="#variacion">Variación</a></li>
                    <li><a href="#asignacion">Asignación</a></li>
                    <li><a href="#divisibilidad">Divisibilidad</a></li>
                    <li><a href="#ley_aplicable">Ley Aplicable y Jurisdicción</a></li>
                </ul>
            </ol>
        </div>
        <div>
            <h2 id="bienvenida">Bienvenida</h2>
            <p>Bienvenido a LAC asesorías inmobiliarias. Estos términos y condiciones rigen su uso de nuestro sitio web; al acceder a este sitio web, usted acepta estos términos y condiciones en su totalidad. Si no está de acuerdo con estos términos y condiciones o cualquier parte de estos términos y condiciones, no debe usar nuestro sitio web.</p>

            <h2 id="licencia">Licencia de Uso</h2>
            <p>A menos que se indique lo contrario, LAC asesorías inmobiliarias y/o sus licenciantes son propietarios de los derechos de propiedad intelectual en el sitio web y el material en el sitio web. Sujeto a la licencia a continuación, todos estos derechos de propiedad intelectual están reservados.</p>
            <p>Puede ver, descargar para fines de almacenamiento en caché solamente, e imprimir páginas, archivos u otro contenido del sitio web para su uso personal, sujeto a las restricciones establecidas a continuación y en otros lugares en estos términos y condiciones.</p>
            <p>Usted no debe:</p>
            <ul>
                <li>Republicar material de este sitio web (incluyendo republicación en otro sitio web).</li>
                <li>Vender, alquilar o sublicenciar material del sitio web.</li>
                <li>Mostrar cualquier material del sitio web en público.</li>
                <li>Reproducir, duplicar, copiar o explotar material en este sitio web para fines comerciales.</li>
                <li>Editar o modificar cualquier material en el sitio web.</li>
                <li>Redistribuir material de este sitio web, excepto contenido específicamente y expresamente disponible para la redistribución.</li>
            </ul>

            <h2 id="uso_aceptable">Uso Aceptable</h2>
            <p>Usted no debe usar este sitio web de ninguna manera que cause, o pueda causar, daño al sitio web o afecte la disponibilidad o accesibilidad del sitio web; o de cualquier manera que sea ilegal, fraudulenta o dañina, o en relación con cualquier propósito o actividad ilegal, fraudulenta o dañina.</p>
            <p>Usted no debe usar este sitio web para copiar, almacenar, alojar, transmitir, enviar, usar, publicar o distribuir ningún material que consista en (o esté vinculado a) cualquier spyware, virus informático, troyanos, gusanos, registradores de teclas, rootkits u otro software malicioso.</p>
            <p>Usted no debe realizar ninguna actividad de recolección de datos sistemática o automatizada (incluyendo, entre otros, raspado, extracción, recopilación de datos y recolección de datos) en o en relación con este sitio web sin nuestro expreso consentimiento por escrito.</p>
            <p>Usted no debe usar este sitio web para transmitir o enviar comunicaciones comerciales no solicitadas.</p>

            <h2 id="restricciones_acceso">Restricciones de Acceso</h2>
            <p>El acceso a ciertas áreas de este sitio web está restringido. Nos reservamos el derecho de restringir el acceso a otras áreas de este sitio web, o incluso este sitio web completo, a nuestra discreción.</p>
            <p>Si le proporcionamos un ID de usuario y una contraseña para que pueda acceder a áreas restringidas de este sitio web u otro contenido o servicios, debe garantizar que el ID de usuario y la contraseña se mantengan confidenciales.</p>

            <h2 id="limitaciones_indemnizacion">Limitaciones de Responsabilidad e Indemnización</h2>
            <p>Aunque hacemos todo lo posible para garantizar que la información en este sitio web sea correcta, no garantizamos su integridad o exactitud; ni nos comprometemos a garantizar que el sitio web permanezca disponible o que el material en el sitio web se mantenga actualizado.</p>
            <p>No seremos responsables ante usted (ya sea bajo la ley de contrato, la ley de responsabilidad extracontractual o de otro modo) en relación con el contenido del sitio web, el uso del sitio web, los sitios web enlazados a este sitio web o cualquier material contenido en ellos.</p>
            <p>Usted acepta indemnizar y eximir de responsabilidad a LAC asesorías inmobiliarias y a sus funcionarios, directores, empleados, agentes y representantes de y contra todas y cada una de las reclamaciones, responsabilidades, daños, pérdidas, costos y gastos, incluidos los honorarios razonables de abogados, que surjan de o estén relacionados con su uso indebido del sitio web, su violación de estos términos y condiciones, o su violación de cualquier derecho de otro.</p>

            <h2 id="politica_privacidad">Política de Privacidad</h2>
            <p>Nuestra política de privacidad, que establece cómo manejaremos su información, se puede encontrar en otra parte en nuestro sitio web. Al aceptar estos términos y condiciones, también está aceptando y comprendiendo nuestra política de privacidad.</p>

            <h2 id="estado_pruebas">Estado de Pruebas y Comprobantes de Pago</h2>
            <p>La página y la aplicación actualmente se encuentran en pruebas. Los comprobantes de pago pueden ser enviados, pero pueden presentar fallas. En caso de dudas, se recomienda verificar los documentos físicos.</p>

            <h2 id="facturacion_electronica">Facturación Electrónica</h2>
            <p>Las facturas electrónicas no se generarán automáticamente. Pueden ser solicitadas directamente a LAC.</p>

            <h2 id="variacion">Variación</h2>
            <p>Podemos revisar estos términos y condiciones de vez en cuando. Los términos y condiciones revisados se aplicarán al uso de nuestro sitio web desde la fecha de publicación de los términos y condiciones revisados en nuestro sitio web. Verifique esta página regularmente para asegurarse de que está familiarizado con la versión actual.</p>

            <h2 id="asignacion">Asignación</h2>
            <p>Podemos transferir, subcontratar o de otro modo tratar con nuestros derechos y/u obligaciones bajo estos términos y condiciones sin notificarle ni obtener su consentimiento.</p>

            <h2 id="divisibilidad">Divisibilidad</h2>
            <p>Si una disposición de estos términos y condiciones se considera inaplicable bajo la ley aplicable, no afectará la aplicabilidad de las demás disposiciones; en su lugar, la disposición inaplicable se modificará o interpretará en la medida mínima necesaria para que sea aplicable.</p>

            <h2 id="ley_aplicable">Ley Aplicable y Jurisdicción</h2>
            <p>Estos términos y condiciones se regirán e interpretarán de acuerdo con la ley, y cualquier disputa relacionada con estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales.</p>
        </div>
    </>
}

const TermsAndConditionsPage: FC<{}> = ({ }) => {
    const { i18n, localization } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="terms_and_condition_page container-fluid">
        <div className="w-100 bg-light">
            <div className="row">
                <div className="col-12 o-banner-container" style={{backgroundImage: 'url(/assets/images/terms_policy_image.png'}}>
                    <div className='container'>
                        <h1 className='o-banner-information-title h5 text-white text_bold'>{i18n(KeyWordLocalization.TermsAndConditionsPageTitle)}</h1>
                        <h2 className='h6 text-white font_bold'>{i18n(KeyWordLocalization.FavoritesPageLAC)}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='py-5 container policy_and_terms_container'>
            {localization.code == 'en' ? <_EnglishTC /> : <_SpanishTC />}
        </div>
    </div>
};

export default TermsAndConditionsPage;