import di from "../../../../../dependencyInjection/DependencyInjection";
import PaginateResultEntity from "../../../../../domain/entities/PaginateResultEntity";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const OldSearchPropertiesApiImpl = async (params: { word: string; page: number; itemsPerPage: number; typeOfProperty?: string | undefined; typeOfBusiness?: string | undefined; isNew?: boolean | undefined; cityId?: string | undefined; zoneId?: string | undefined; neighborhoodId?: string | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }): Promise<PaginateResultEntity<PropertyEntity>> => {
    try {
        console.log('its been called');
        const body = {
            "searcher": params.word,
            "page": params.page,
            "itemsPerPage": params.itemsPerPage,
            "typeOfProperty": params.typeOfProperty,
            "typeOfBusiness": params.typeOfBusiness,
            "isNew": params.isNew,
            "cityId": params.cityId,
            "zoneId": params.zoneId,
            "neighborhoodId": params.neighborhoodId,
            "minPrice": params.minPrice,
            "maxPrice": params.maxPrice
        };
        const relativeUrl = "/getPropertiesFilterPage";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body);
        const parsedData = response.data.map((property: any) => PropertyDto.fromJSON(property));
        const mapped: PaginateResultEntity<PropertyEntity> = {
            data: parsedData,
            page: response.current_page,
            total: response.total,
            totalPage: response.last_page,
        }
        return mapped;
    } catch (e) {
        const response: PaginateResultEntity<PropertyEntity> = {
            data: [],
            page: params.page,
            total: 0,
            totalPage: 0,
        }
        return response;
    }
}

export default OldSearchPropertiesApiImpl;