import { FC, useContext } from "react";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../../../providers/localization/KeyWordLocalization";
import "./HelperSearchPropertyComponentStyles.scss";

const HelpSearchPropertyComponent: FC = () => {

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const sendWhatsapp = () => {
        const phoneNumber = '573234686680';
        const message = `${i18n(KeyWordLocalization.HelperSearchPropertyComponentWhatsapp)}`;
        const whatsappUrl = encodeURI(`https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}`);

        window.open(whatsappUrl, '_blank');
    }

    return <div className="row help_search_property_component align-items-center mt-md-5 mb-2">
        <div className="col-12 col-xl-6 d-flex flex-column align-items-center">
            <h2 className="help_title">{i18n(KeyWordLocalization.HelpSearchPropertyComponentTitle)}</h2>
            <div className="mt-4 mb-4">{i18n(KeyWordLocalization.HelpSearchPropertyComponentBody)}</div>
            <div className="mbtn btn_orange loan-btn pt-2 pb-2" onClick={sendWhatsapp}>
                <span>{i18n(KeyWordLocalization.HelpSearchPropertyComponentButton)}</span>
            </div>
        </div>
        <div className="col-6 d-none d-xl-block">
            <img className="img-fluid" src="/assets/images/helper_property_component_image.png" alt={i18n(KeyWordLocalization.HelpSearchPropertyComponentHelpImageTitle)} title={i18n(KeyWordLocalization.HelpSearchPropertyComponentHelpImageTitle)} />
        </div>
    </div>
}

export default HelpSearchPropertyComponent;