import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";
import StringUtils from "../../utils/StringUtils";

export interface PropertiesMetaTagComponentProps {

}

const PropertiesMetaTagComponent: FC<PropertiesMetaTagComponentProps> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const queryParams = new URLSearchParams(window.location.search);
    const tags = queryParams.get("tags");
    const { businesses, types, city } = useParams();

    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const _seoTitle = (typeProperties: string[], typeBusinesses: string[], city: string | undefined, isUniqueOportunity: boolean) => {
        const cityText = city ? +city : undefined;
        const typeProperty = typeProperties.length == 1 ? typeProperties[0] : i18n(KeyWordLocalization.PropertiesMetaTagComponentInvestmentProperty);
        if (isUniqueOportunity) {
            const tm = i18n(KeyWordLocalization.PropertiesMetaTagComponentInvestmentTitle, {
                typeProperty: typeProperty,
                city: cityText ?? i18n(KeyWordLocalization.OtherColombia),
            });
            setTitle(tm);
            return;
        }
        const typeBusiness = typeBusinesses.length == 1 ? i18n(KeyWordLocalization.OtherIn) + " " + typeBusinesses[0] : "";
        const tm = i18n(KeyWordLocalization.PropertiesMetaTagComponentTitle, {
            typeProperty: StringUtils.makeFirstLetterUpperCase(typeProperty),
            typeBusiness: typeBusiness,
            city: cityText ?? i18n(KeyWordLocalization.OtherColombia)
        })
        setTitle(tm);
        return;
    }

    const _seoDescription = (typeProperties: string[], typeBusinesses: string[], city: string | undefined, isUniqueOportunity: boolean) => {
        const cityText = city ?? i18n(KeyWordLocalization.OtherCaliOrJamundi);
        if (isUniqueOportunity) {
            const tm = i18n(KeyWordLocalization.PropertiesMetaTagComponentInvestmentDescription, {
                city: cityText
            });
            setDescription(tm);
            return;
        }
        const typeProperty = typeProperties.length == 1 ? typeProperties[0] : i18n(KeyWordLocalization.SEOProperty);
        const typeBusiness = typeBusinesses.length == 1 ? typeBusinesses[0] : "";
        const tm = i18n(KeyWordLocalization.PropertiesMetaTagComponentDescription, {
            typeProperty: typeProperty,
            typeBusiness: typeBusiness,
            city: cityText ?? i18n(KeyWordLocalization.OtherCaliOrJamundi)
        })
        setDescription(tm);
        return;
    }

    useEffect(() => {
        const tagsFiltred = tags?.toLocaleLowerCase().split(",").includes(i18n("unique_oportunity").toLowerCase()) ?? false;
        const typeProperties = types?.split(",") ?? [];
        const typeBusinesses = businesses?.split(",") ?? [];
        _seoTitle(typeProperties, typeBusinesses, city, tagsFiltred);
        _seoDescription(typeProperties, typeBusinesses, city, tagsFiltred);
    }, [tags, businesses, types, city])

    return <Helmet>
        <title>{title}</title>
        <link rel="image_src" href="/assets/promotional/link_preview.jpg" type="image/png" />
        <meta name="description" content={description} />
        <meta name="keywords" content={i18n(KeyWordLocalization.PropertiesMetaTagComponentKeywords, {
            typeProperty: title,
            city: city ?? i18n(KeyWordLocalization.OtherCaliOrJamundi),
            typeBusiness: businesses,
            tags: tags ?? ""
        })} />
        <link rel="canonical" href={window.location.pathname} />
        <meta name="robots" content="index, follow" />
    </Helmet>
}

export default PropertiesMetaTagComponent;