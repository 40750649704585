import { Either, right, left } from "fp-ts/lib/Either";
import ContactInfoEntity from "../../../../../domain/entities/ContactInfoEntity";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";

//TODO test api
const StoreContactPreferencesApiImpl = async (contactInfo: ContactInfoEntity, preferencesKey: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        localStorage.setItem(preferencesKey, JSON.stringify(contactInfo));
        return right(undefined);
    } catch (error) {
        return left(ExceptionEntityFromError(error));
    }
}

export default StoreContactPreferencesApiImpl;