import './ContactPropertyComponentStyles.scss';
import { FC, useContext } from "react";
import ContactPropertyComponentProps from "./ContactPropertyComponentProps";
import PersonEntity from "../../../../../../domain/entities/PersonEntity";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import ContactPersonItemComponent from "./contactPersonItem/ContactPersonItemComponent";
import ContactFormPropertyComponent from "./contactFormProperty/ContactFormPropertyComponent";
import KeyWordLocalization from '../../../../../providers/localization/KeyWordLocalization';

const marlonData: PersonEntity = {
    fullname: 'Marlon Méndez',
    cellphone: '316 288 5158',
    email: 'marlon.mendez@lac.com.co',
}

const adrianaData: { person: PersonEntity, cellphone2: string } = {
    person: {
        fullname: 'Adriana Castañeda',
        cellphone: '316 288 5158',
        email: 'asesorias@lac.com.co',
    },
    cellphone2: '312 295 8733',
}

const ContactPropertyComponent: FC<ContactPropertyComponentProps> = ({ property }) => {
    const { i18n, localization } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="contact_property_component">
        <div className="d-md-none">
            <div className="title_detailed">Contacto</div>
        </div>
        <div className="bg_light">
            {localization.code == "es" && <ContactPersonItemComponent person={adrianaData.person} phone2={adrianaData.cellphone2} />}
            {localization.code == "en" || property.contact == undefined && <ContactPersonItemComponent person={marlonData} />}
            {property.contact != undefined && <ContactPersonItemComponent person={property.contact} />}
        </div>
        <div className="contact_box bg_light my-3">
            <strong>{i18n(KeyWordLocalization.ContactPropertyComponentContactMe)}</strong>
            <ContactFormPropertyComponent />
        </div>
    </div>
}

export default ContactPropertyComponent;