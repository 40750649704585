import './PickerBoxComponentStyles.scss';
import { FC } from "react";
import PickerBoxComponentProps from "./PickerBoxComponentProps";
import { ErrorMessage } from "@hookform/error-message";

const PickerBoxComponent: FC<PickerBoxComponentProps> = ({ formFunctions, multiple = false, label, options, keyName, onChange }) => {
    const { register, handleSubmit, getValues, formState: { errors }, reset, setValue, watch } = formFunctions;
    const value = watch(keyName);

    const _handleClick = (option: any) => {
        if (!option.enabled) return;
        const currentValue = getValues()[keyName];
        if (!multiple) {
            setValue(keyName, option.value);
            if (currentValue != option.value) onChange?.(option.value);
        } else {
            let values = value ? value.toString().split(',') : [];
            if (values.includes(option.value.toString())) {
                values = values.filter((v: any) => v != option.value.toString());
            } else {
                values.push(option.value.toString());
            }
            setValue(keyName, values.join(','));
            onChange?.(option.values);
        }
    }

    const _isSelect = (option: any) => {
        if (!multiple) {
            return value?.toString() == option.value.toString();
        } else {
            return value?.toString().split(',').includes(option.value.toString());
        }
    }

    return <div className="picker_box form-group">
        {label && <label>{label}</label>}
        <div className="w-100 d-flex flex-wrap">
            {options.map((option, index) => <div key={index}
                onClick={() => _handleClick(option)}
                className={`card_option_picker hover ${_isSelect(option) && 'selected'} ${!option.enabled && 'disabled'}`}>
                {option.label}
            </div>)}
        </div>
        <input className='form-control' type="hidden" {...register(keyName)} />
        <ErrorMessage as="aside" errors={errors} name={keyName} />
    </div>

}

export default PickerBoxComponent;