import { injectable } from "inversify";
import LocalizationEntity from "../../entities/LocalizationEntity";
import LocalizationRepository from "../../repositories/LocalizationRepository";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface props {
    localizationRepository: LocalizationRepository;
    localizationProvider: LocalizationProvider;
}
@injectable()
class GetAvailableLanguagesUseCase {
    _localizationRepository: LocalizationRepository;
    _localizationProvider: LocalizationProvider;

    constructor(_: props) {
        this._localizationRepository = _.localizationRepository;
        this._localizationProvider = _.localizationProvider;
    }
    public call = async (): Promise<LocalizationEntity[]> => {
        if(this._localizationProvider.Actions.localizations.length > 0){
            return this._localizationProvider.Actions.localizations;
        }
        const response = await this._localizationRepository.getAvailableLanguages();
        this._localizationProvider.Actions.setLocalizations(response);
        return response;
    }
}

export default GetAvailableLanguagesUseCase;

export const GetAvailableLanguagesUseCaseName = "GetAvailableLanguagesUseCase";