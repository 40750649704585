import { injectable } from "inversify";
import BlogRepository from "../../repositories/BlogRepository";
import PaginateResultEntity from "../../entities/PaginateResultEntity";
import BlogEntity from "../../entities/BlogEntity";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface Props {
    blogRepository: BlogRepository;
    localizationProvider: LocalizationProvider;
}
@injectable()
export default class GetBlogByIdUseCase {
    _blogRepository: BlogRepository;
    _localizationProvider: LocalizationProvider;

    constructor(_props: Props) {
        this._blogRepository = _props.blogRepository;
        this._localizationProvider = _props.localizationProvider;
    }

    public call = async (id: string): Promise<BlogEntity | null> => {
        const currentLanguage = this._localizationProvider.Actions.localization.code;
        const response = await this._blogRepository.getById(id, currentLanguage);
        return response;
    }
}

export const GetBlogByIdUseCaseName = "GetBlogByIdUseCase";