import { injectable } from "inversify";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import PropertyRepository from "../../repositories/PropertyRepository";
import PropertyEntity from "../../entities/PropertyEntity";

interface props {
    propertyRepository: PropertyRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class GetPropertyByIdUseCase {
    _propertyRepository: PropertyRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (id: string):Promise<PropertyEntity | undefined> => {
        const response = await this._propertyRepository.getPropertyById(id);
        if (response == undefined) return response;
        response.liked = this._preferencesProvider.Actions.likedProperties.some((liked) => liked.id == response.id);
        return response;
    }
}

export default GetPropertyByIdUseCase;

export const GetPropertyByIdUseCaseName = "GetPropertyByIdUseCase";