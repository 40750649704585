import "./SideFilterComponentStyles.scss";
import { FC, useContext, useEffect } from "react";
import SideFilterComponentProps from "./SideFilterComponentProps";
import PickerBoxComponent from "../../../../components/form/pickerBox/PickerBoxComponent";
import RangePickerComponent from "../../../../components/form/rangePicker/RangePickerComponent";
import MastersContext from "../../../../../domain/providers/master/MastersContext";
import MastersContextType from "../../../../../domain/providers/master/MastersContextType";
import StringUtils from "../../../../utils/StringUtils";
import AutoCompleteWithCheckComponent from "../../../../components/form/autocompleteWithCheck/AutoCompleteWithCheckComponent";
import { AutoCompleteItem } from "../../../../components/form/autocompleteWithCheck/AutoCompleteWithCheckProps";
import ZoneEntity from "../../../../../domain/entities/ZoneEntity";
import NeighborhoodEntity from "../../../../../domain/entities/NeighborhoodEntity";
import LocalizationContext from "../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../../providers/localization/KeyWordLocalization";

export const propertiesKeysFilters = {
    page: 'page',
    word: KeyWordLocalization.SideFilterWordSearch,
    city: 'city',
    zones: 'zones',
    neighborhoods: 'neighborhoods',
    itemsPerPage: 20,
    price: {
        key: 'price',
        max: 1000000000,
        min: 100000,
    },
    rooms: {
        key: 'rooms',
        maxToShow: 5,
    },
    restrooms: {
        key: 'restrooms',
        maxToShow: 4,
    },
    area: {
        key: 'area',
        max: 400,
        min: 10,
    },
    garages: {
        key: 'garages',
        maxToShow: 4,
    },
    stratum: {
        key: 'stratum',
        maxToShow: 6,
    },
    status: 'status',
    tags: 'tags',
    sort: {
        key: KeyWordLocalization.SideFilterWordSort,
        values: {
            newest: KeyWordLocalization.SideFilterWordSortNewest,
            oldest: KeyWordLocalization.SideFilterWordSortOldest,
            price_asc: KeyWordLocalization.SideFilterWordSortPriceAsc,
            price_desc: KeyWordLocalization.SideFilterWordSortPriceDesc,
            more_liked: KeyWordLocalization.SideFilterWordSortMoreLiked,
            more_seen: KeyWordLocalization.SideFilterWordSortMoreSeen
        }
    },
    typeBusinesses: 'type_business',
    typeProperties: 'type_property',
}
const SideFilterComponent: FC<SideFilterComponentProps> = ({ formFunctions, onChangeValueFilter, toggleModal }) => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const { cities, neighborhoods, tags } = useContext(MastersContext) as MastersContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const _fillArray = (length: number, showMore: boolean) => {
        const initArray = Array((showMore ? length - 1 : length)).fill(null).map((i, index) => {
            return {
                label: (index + 1).toString(),
                value: (index + 1),
                enabled: true
            }
        });
        if (!showMore) return initArray;
        return [...initArray, { label: length + '+', value: length + '+', enabled: true }]
    }

    const _handleSearchZone = (word: string): AutoCompleteItem<ZoneEntity>[] => {
        let zonesResponse: ZoneEntity[] = [];
        const values = getValues();
        if (values[propertiesKeysFilters.city]) {
            const currentCity = cities.find((city) => city.id == values[propertiesKeysFilters.city]);
            if (currentCity) zonesResponse = currentCity.zones;
        } else return [];
        const zonesFiltered = zonesResponse.filter((zone) => zone.name.toLowerCase().includes(word.toLowerCase()));
        const zonesMapped = zonesFiltered.map((zone) => {
            return {
                label: StringUtils.makeFirstLetterUpperCase(zone.name),
                id: zone.id,
                aditionalValue: zone,
            }
        });
        return zonesMapped.sort((a, b) => a.label.localeCompare(b.label));
    }

    const _handleChangeCity = (cityId: string) => {
        setValue('zones', "");
        setValue('neighborhoods', "");
        onChangeValueFilter?.();
    }

    const _handleChangeZone = (zoneId: string) => {
        if (zoneId != getValues().zones) {
            setValue('neighborhoods', []);
            onChangeValueFilter?.();
        }
    }

    const _handleSearchNeighborhoods = (word: string): AutoCompleteItem<NeighborhoodEntity>[] => {
        let neighborhoodsResponse = neighborhoods;
        const values = getValues();
        if (values[propertiesKeysFilters.zones] && values[propertiesKeysFilters.zones].length > 0) {
            neighborhoodsResponse = values[propertiesKeysFilters.zones].flatMap((zone: AutoCompleteItem<ZoneEntity>) => zone.aditionalValue.neighborhoods);
        } else if (values[propertiesKeysFilters.city]) {
            const currentCity = cities.find((city) => city.id == values[propertiesKeysFilters.city]);
            if (currentCity) neighborhoodsResponse = currentCity.neighborhoods;
        }
        console.log('neighborhoodsResponse', {neighborhoodsResponse, neighborhoods, values})
        const neighborhoodsFiltered = neighborhoodsResponse.filter((neighborhood) => neighborhood.name.toLowerCase().includes(word.toLowerCase()));
        const neighborhoodsMapped = neighborhoodsFiltered.map((neighborhood) => {
            return {
                label: StringUtils.makeFirstLetterUpperCase(neighborhood.name),
                id: neighborhood.id,
                aditionalValue: neighborhood,
            }
        });
        return neighborhoodsMapped.sort((a, b) => a.label.localeCompare(b.label));
    }

    return (
        <div className="side_filter_component pb-3 modal-fullscreen-sm-down" id="filter-modal">
            <div className="h6 line_filter">
                <span className="filter_title b-4">{i18n(KeyWordLocalization.SideComponentTags)}</span>
                <div className="my-3 w-100"></div>
                <PickerBoxComponent formFunctions={formFunctions} onChange={_handleChangeCity} keyName={propertiesKeysFilters.tags} multiple label="" options={tags.map((tag) => {
                    return {
                        label: i18n(tag.name),
                        value: tag.id,
                        enabled: true
                    }
                })} />

            </div>
            <div className="h6 line_filter">
                <span className="filter_title b-4">{i18n(KeyWordLocalization.SideComponentLocation)}</span>
                <div className="my-3 w-100"></div>
                <PickerBoxComponent formFunctions={formFunctions} onChange={_handleChangeCity} keyName={propertiesKeysFilters.city} label={i18n(KeyWordLocalization.SideFilterCity)} options={cities.map((city) => {
                    return {
                        label: StringUtils.makeFirstLetterUpperCase(city.name),
                        value: city.id,
                        enabled: true
                    }
                })} />
                {watch('city') && <AutoCompleteWithCheckComponent labelInHint formInfo={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.zones} label={i18n(KeyWordLocalization.SideComponentZones)} onSearch={_handleSearchZone} />}
                <AutoCompleteWithCheckComponent labelInHint formInfo={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.neighborhoods} label={i18n(KeyWordLocalization.SideComponentNeighborhood)} onSearch={_handleSearchNeighborhoods} />
            </div>

            <div className="h6 line_filter">
                <span className="filter_title b-4">{i18n(KeyWordLocalization.SideFilterCharacteristics)}</span>
                <RangePickerComponent formFunctions={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.price.key} isCurrency label={i18n(KeyWordLocalization.SideFilterPrice)} min={0} max={propertiesKeysFilters.price.max} />
                <PickerBoxComponent formFunctions={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.rooms.key} label={i18n(KeyWordLocalization.SideFilterRooms)} multiple options={_fillArray(propertiesKeysFilters.rooms.maxToShow, true)} />
                <PickerBoxComponent formFunctions={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.restrooms.key} label={i18n(KeyWordLocalization.SideFilterBathrooms)} multiple options={_fillArray(propertiesKeysFilters.restrooms.maxToShow, true)} />
                <RangePickerComponent formFunctions={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.area.key} label={`${i18n(KeyWordLocalization.SideFilterRooms)}&sup2;`} min={0} max={propertiesKeysFilters.area.max} />
                <PickerBoxComponent formFunctions={formFunctions} onChange={onChangeValueFilter} keyName={propertiesKeysFilters.garages.key} label={i18n(KeyWordLocalization.SideFilterGarage)} multiple options={_fillArray(propertiesKeysFilters.garages.maxToShow, true)} />
                <PickerBoxComponent formFunctions={formFunctions} multiple onChange={onChangeValueFilter} keyName={propertiesKeysFilters.stratum.key} label={i18n(KeyWordLocalization.SideFilterStratum)} options={_fillArray(propertiesKeysFilters.stratum.maxToShow, false)} />
                <PickerBoxComponent formFunctions={formFunctions} multiple onChange={onChangeValueFilter} keyName={propertiesKeysFilters.status} label="Estado" options={[
                    { label: "Nuevo", value: "new", enabled: true },
                    { label: "Usado", value: "used", enabled: true },
                ]} />
            </div>
            <button type="submit" className="mbtn btn btn_orange w-100 d-none">{i18n(KeyWordLocalization.SideFilterSearch)}</button>
            <button type="submit" onClick={toggleModal} className="mbtn btn btn_orange w-100 d-block d-md-none">{i18n(KeyWordLocalization.SideFilterSearch)}</button>
            <div className="d-block d-md-none" style={{height: "20px"}}></div>
        </div>
    )
}

export default SideFilterComponent;
