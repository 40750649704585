import { FC } from "react";
import RelatedPropertiesComponentProps from "./RelatedPropertiesComponentProps";
import Carousel from 'react-multi-carousel';
import CardPropertyComponent from "../../../../../components/cardProperty/CardPropertyComponent";
import './RelatedPropertiesComponentStyles.scss';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const RelatedPropertiesComponent: FC<RelatedPropertiesComponentProps> = ({ properties }) => {
  return <div className="related_properties_component">
    <h2 className="title_detailed">Inmuebles relacionados</h2>
    <Carousel responsive={responsive} infinite>
      {(properties ?? Array(3).fill(undefined)).map((property, index) => <div className="px-2" style={{height: '90%'}} key={property?.id ?? index}>
        <CardPropertyComponent property={property} noRelevant={true} />
      </div>)}
    </Carousel>
  </div>
}

export default RelatedPropertiesComponent;