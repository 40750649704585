import MultimediaInterestPointEntity from "./MultimediaInterestPointEntity";

export enum MultimediaEntityType {video, image}
interface MultimediaEntity {
    id: string;
    url: string;
    interestsPoints: MultimediaInterestPointEntity[];
    type: MultimediaEntityType;
}

export default MultimediaEntity;