import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import PropertyEntity from '../../../../../../../domain/entities/PropertyEntity';
import { PropertyMarker } from './PropertyMarker';
import FriendlyUrls from '../../../../../../seo/FriendlyUrls';

export type ClusteredPropertyMarkersProps = {
    properties: PropertyEntity[];
    showOthers?: boolean;
};

/**
 * The ClusteredPropertyMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredPropertyMarkers = ({ properties, showOthers = true }: ClusteredPropertyMarkersProps) => {
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const [selectedPropertyKey, setSelectedPropertyKey] = useState<string | null>(null);

    const selectedProperty = useMemo(
        () =>
            properties && selectedPropertyKey
                ? properties.find(t => t.id === selectedPropertyKey)!
                : null,
        [properties, selectedPropertyKey]
    );

    // create the markerClusterer once the map is available and update it when
    // the markers are changed
    const map = useMap();
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        if (showOthers)
            clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    useEffect(() => {
        clusterer?.clearMarkers();
        console.log('show others', { showOthers, clusterer })
        if (showOthers) {
            clusterer?.addMarkers(Object.values(markers));
        }
    }, [showOthers]);

    // this callback will effectively get passsed as ref to the markers to keep
    // tracks of markers currently on the map
    const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
        setMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key]))
                return markers;

            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const { [key]: _, ...newMarkers } = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setSelectedPropertyKey(null);
    }, []);

    const handleMarkerClick = useCallback((property: PropertyEntity) => {
        setSelectedPropertyKey(property.id);
    }, []);

    return (
        <>
            {showOthers && properties.map(property => (
                <PropertyMarker
                    key={property.id}
                    property={property}
                    onClick={handleMarkerClick}
                    setMarkerRef={setMarkerRef}
                />
            ))}

            {selectedPropertyKey && (
                <InfoWindow
                    anchor={markers[selectedPropertyKey]}
                    headerContent={FriendlyUrls.getMediumDescriptionOfProperty(selectedProperty!)}
                    onCloseClick={handleInfoWindowClose}>
                    {FriendlyUrls.getUltraShortDescriptionOfProperty(selectedProperty!)}
                    <div>
                        <a style={{ textAlign: 'center', textDecoration: 'underline', color: 'blue', }} href={FriendlyUrls.getUrlProperty(selectedProperty!)}>Ver más</a>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};
