import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import MastersTablesRepository from "../../repositories/MastersTablesRepository";
import TypeOfPropertyEntity from "../../entities/TypeOfPropertyEntity";

interface props {
    mastersTablesRepository: MastersTablesRepository;
    mastersProvider: MastersProvider;
}

@injectable()
class GetAllTypeOfPropertiesUseCase {
    _mastersTablesRepository: MastersTablesRepository;
    _mastersProvider: MastersProvider;

    constructor(_props: props) {
        this._mastersTablesRepository = _props.mastersTablesRepository;
        this._mastersProvider = _props.mastersProvider;
    }

    public call = async (): Promise<TypeOfPropertyEntity[]> => {
        if(this._mastersProvider.Actions.typeOfProperties.length > 0) return this._mastersProvider.Actions.typeOfProperties;
        const typeOfProperties = await this._mastersTablesRepository.getTypesOfProperty();
        this._mastersProvider.Actions.setTypesOfProperties(typeOfProperties);
        return typeOfProperties;
    }
}

export default GetAllTypeOfPropertiesUseCase;

export const GetAllTypeOfPropertiesUseCaseName = "GetAllTypeOfPropertiesUseCase";