import di from "../../../../../dependencyInjection/DependencyInjection";
import PaginateResultEntity from "../../../../../domain/entities/PaginateResultEntity";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import { propertiesKeysFilters } from "../../../../../presentation/pages/properties/components/sideFilter/SideFilterComponent";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

//TODO create on web back
const SearchPropertiesApiImpl = async (params: {
    word: string, page: number, itemsPerPage: number,
    tagsId: string[],
    cityId?: string | undefined, zoneIds: string[], neighborhoodIds: string[],
    priceRange?: { min: number, max: number },
    rooms?: string[], restrooms?: string[],
    metersRange?: { min: number, max: number },
    garages?: string[], stratum?: string[],
    typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
    sortBy: string
}): Promise<PaginateResultEntity<PropertyEntity>> => {
    const _checkContentArray = (array: any[] | undefined) => {
        if (!array) return undefined;
        const filtred = array.filter((item) => item !== undefined && item !== null && item !== "")
        if (filtred.length === 0) return undefined;
        return filtred;
    }
    try {
        console.log('params', params)
        const body = {
            "searcher": params.word != "" ? params.word : undefined,
            "tag_ids": _checkContentArray(params.tagsId),
            "city_id": params.cityId ? params.cityId : undefined,
            "zone_ids": _checkContentArray(params.zoneIds),
            "neighborhood_ids": _checkContentArray(params.neighborhoodIds),
            "min_price": params.priceRange?.min && params.priceRange.min <= propertiesKeysFilters.price.min ? undefined : params.priceRange?.min,
            "max_price": params.priceRange?.max && params.priceRange.max >= propertiesKeysFilters.price.max ? undefined : params.priceRange?.max,
            "rooms": _checkContentArray(params.rooms),
            "min_area": params.metersRange?.min && params.metersRange.min <= propertiesKeysFilters.area.min ? undefined : params.metersRange?.min,
            "max_area": params.metersRange?.max && params.metersRange.max >= propertiesKeysFilters.area.max ? undefined : params.metersRange?.max,
            "parkings": _checkContentArray(params.garages),
            "stratum": _checkContentArray(params.stratum),
            "type_property_ids": _checkContentArray(params.typeOfPropertiesId),
            "type_business_ids": _checkContentArray(params.typeOfBusinessId),
            "is_new": params.isNew,
            'restrooms': _checkContentArray(params.restrooms),
            "items_per_page": params.itemsPerPage,
            "page": params.page,
        };
        const relativeUrl = "/property/search";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body);
        const parsedData = response.data.map((property: any) => PropertyDto.fromJSON(property));
        const mapped: PaginateResultEntity<PropertyEntity> = {
            data: parsedData,
            page: response.current_page,
            total: response.total,
            totalPage: response.last_page,
        }
        const rooms = params.rooms ?? [];
        const numberWithPlus = 4;
        if (numberWithPlus != null) {
            Array(4).fill(0).map((_, index) => index).filter((item) => rooms.includes(item.toString()))
        }
        return mapped;
    } catch (e) {
        console.log('callleing', e);
        const response: PaginateResultEntity<PropertyEntity> = {
            data: [],
            page: params.page,
            total: 0,
            totalPage: 0,
        }
        return response;
    }
}

export default SearchPropertiesApiImpl;