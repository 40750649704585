import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";
import di from "../../../../../dependencyInjection/DependencyInjection";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const SubscribeEmailApiImpl = async (email: string):Promise<Either<ExceptionEntity, void>> => {
    try{
        const relativeUrl = "/subscribeEmail";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, {email});
        return response;
    }catch(error){
        return left(ExceptionEntityFromError(error));
    }
}

export default SubscribeEmailApiImpl