import "./DeleteFilterComponentStyles.scss";
import { FC, useContext, useEffect } from "react";
import DeleteFilterComponentProps from "./DeleteFilterComponentProps";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import MastersContext from "../../../../../domain/providers/master/MastersContext";
import MastersContextType from "../../../../../domain/providers/master/MastersContextType";
import { MdClear } from "react-icons/md";
import ZoneEntity from "../../../../../domain/entities/ZoneEntity";
import NeighborhoodEntity from "../../../../../domain/entities/NeighborhoodEntity";
import { AutoCompleteItem } from "../../../../components/form/autocompleteWithCheck/AutoCompleteWithCheckProps";
import CurrencyParse from "../../../../utils/CurrencyParse";
import LocalizationContext from "../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import { propertiesKeysFilters } from "../sideFilter/SideFilterComponent";

const DeleteFilterComponent: FC<DeleteFilterComponentProps> = ({ formFunctions, onClearFilters }) => {
    const { setValue, watch, getValues } = formFunctions;
    const { cities, neighborhoods, zones, tags } = useContext(MastersContext) as MastersContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const tagsSelected = watch(propertiesKeysFilters.tags);
    const city = cities.find(city => city.id === watch(propertiesKeysFilters.city));
    const zonesSelected = watch(propertiesKeysFilters.zones);
    const neighborhoodsSelected = watch(propertiesKeysFilters.neighborhoods);
    const price = watch(propertiesKeysFilters.price.key);
    const rooms = watch(propertiesKeysFilters.rooms.key);
    const restrooms = watch(propertiesKeysFilters.restrooms.key);
    const area = watch(propertiesKeysFilters.area.key);
    const garages = watch(propertiesKeysFilters.garages.key);
    const stratum = watch(propertiesKeysFilters.stratum.key);
    const status = watch(propertiesKeysFilters.status);

    const _handleRemoveCity = () => {
        setValue(propertiesKeysFilters.city, "");
        setValue(propertiesKeysFilters.zones, "");
        setValue(propertiesKeysFilters.neighborhoods, "");
        onClearFilters();
    }

    const _handleRemoveZone = (zoneId: string) => {
        const newZones = zonesSelected.filter((z: ZoneEntity) => z.id != zoneId);
        setValue(propertiesKeysFilters.zones, newZones);
        //remove neighborhoods of this zone
        const _neighborhoods = watch(propertiesKeysFilters.neighborhoods);
        const newNeighborhoods = _neighborhoods ? _neighborhoods.filter((n: AutoCompleteItem<NeighborhoodEntity>) => n.aditionalValue.zone_id != zoneId) : [];
        setValue(propertiesKeysFilters.neighborhoods, newNeighborhoods);
        onClearFilters();
    }

    const _handleRemoveNeighborhood = (neighborhoodId: string) => {
        const newNeighborhoods = neighborhoodsSelected.filter((n: AutoCompleteItem<NeighborhoodEntity>) => n.id !== neighborhoodId);
        setValue(propertiesKeysFilters.neighborhoods, newNeighborhoods);
        onClearFilters();
    }

    const _handleRemoveTag = (tag: string) => {
        const newTags = tagsSelected.split(',').filter((t: string) => t !== tag).join(',');
        setValue(propertiesKeysFilters.tags, newTags);
        onClearFilters();
    }

    const _splitAndOrder = (value: string) => value.split(',').sort((a, b) => a.localeCompare(b));

    const _removeValueList = (key: string, value: string) => {
        const values = getValues(key).split(',').filter((v: string) => v !== value).join(',');
        setValue(key, values);
        onClearFilters();
    }

     const _handleSetValue = (key: string, value: string) => {
        setValue(key, value);
        onClearFilters();
    }


    return (
        <div className="delete_filter_component">
            {tagsSelected && tags && tags.filter((tag) => tagsSelected.split(',').includes(tag.id)).map((tag, index) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{i18n(tag.name)}</h2>
                <div className="icon hover" onClick={() => _handleRemoveTag(tag.id)}>
                    <MdClear />
                </div>
            </div>)}
            {city && <div className="delete_filter_item">
                <h2 className="h6">{city.name}</h2>
                <div className="icon hover" onClick={_handleRemoveCity}>
                    <MdClear />
                </div>
            </div>}
            {zonesSelected && zonesSelected.map((zone: AutoCompleteItem<ZoneEntity>, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{zone.label}</h2>
                <div className="icon hover" onClick={() => _handleRemoveZone(zone.id)}>
                    <MdClear />
                </div>
            </div>)}
            {neighborhoodsSelected && neighborhoodsSelected.map((neighborhood: AutoCompleteItem<NeighborhoodEntity>, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{neighborhood.label}</h2>
                <div className="icon hover" onClick={() => _handleRemoveNeighborhood(neighborhood.id)}>
                    <MdClear />
                </div>
            </div>)}
            {price?.max && price.max - price.min != propertiesKeysFilters.price.max && <div className="delete_filter_item">
                <h2 className="h6">Precio {CurrencyParse.toCop(price.min)} - {CurrencyParse.toCop(price.max)}</h2>
                <div className="icon hover" onClick={() => _handleSetValue(propertiesKeysFilters.price.key, "")}>
                    <MdClear />
                </div>
            </div>}
            {rooms && _splitAndOrder(rooms).map((room: string, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{room} Habitaciones</h2>
                <div className="icon hover" onClick={() => _removeValueList(propertiesKeysFilters.rooms.key, room)}>
                    <MdClear />
                </div>
            </div>)}
            {restrooms && _splitAndOrder(restrooms).map((bathroom: string, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{bathroom} Baños</h2>
                <div className="icon hover" onClick={() => _removeValueList(propertiesKeysFilters.restrooms.key, bathroom)}>
                    <MdClear />
                </div>
            </div>)}
            {area?.max && <div className="delete_filter_item">
                <h2 className="h6">Area: {area.min} - {area.max} m<sup>2</sup></h2>
                <div className="icon hover" onClick={() => _handleSetValue(propertiesKeysFilters.area.key, "")}>
                    <MdClear />
                </div>
            </div>}
            {garages && _splitAndOrder(garages).map((garage: string, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6">{garage} Garajes</h2>
                <div className="icon hover" onClick={() => _removeValueList(propertiesKeysFilters.garages.key, garage)}>
                    <MdClear />
                </div>
            </div>)}
            {stratum && _splitAndOrder(stratum).map((stratumItem: string, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6"> Estrato {stratumItem}</h2>
                <div className="icon hover" onClick={() => _removeValueList(propertiesKeysFilters.stratum.key, stratumItem)}>
                    <MdClear />
                </div>
            </div>)}
            {status && _splitAndOrder(status).map((statusItem: string, index: number) => <div className="delete_filter_item" key={index}>
                <h2 className="h6"> {i18n(statusItem)}</h2>
                <div className="icon hover" onClick={() => _removeValueList(propertiesKeysFilters.status, statusItem)}>
                    <MdClear />
                </div>
            </div>)}

        </div>
    );
};
export default DeleteFilterComponent;
