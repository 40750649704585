import CookiesEntity from "../../../../../domain/entities/CookiesEntity";

//TODO test api
const GetCookiesApiImpl = async (keyCookies: string): Promise<CookiesEntity> => {
    const defaultCookies: CookiesEntity = {
        marketing: true,
        analytics: true,
        userInterface: true,
    };
    try {
        const cookies = localStorage.getItem(keyCookies);
        if (!cookies) return defaultCookies;
        return JSON.parse(cookies);
    } catch (e) {
        return defaultCookies;
    }
}

export default GetCookiesApiImpl;
