import { injectable } from "inversify";
import BlogRepositoryImpl from "./BlogRepositoryImpl";
import BlogRepositoryFake from "./BlogRepositoryFake";

@injectable()
class BlogRepositoryDev extends BlogRepositoryImpl {
    blogFake: BlogRepositoryFake = new BlogRepositoryFake();

    getById = (id: string, language: string) => this.blogFake.getById(id, language);
    getMostImportantBlogs = (language: string) => this.blogFake.getMostImportantBlogs(language);
    search = (params: { title: string; page: number; itemsPerPage: number; }, language: string) => this.blogFake.search(params, language);
}

export default BlogRepositoryDev;