import di from "../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../domain/entities/PropertyEntity";
import LocalizationProvider, { LocalizationProviderName } from "../../domain/providers/localization/LocalizationProvider";
import KeyWordLocalization from "../providers/localization/KeyWordLocalization";
import { routes } from "../routes/Routes";
import StringUtils from "../utils/StringUtils";

const getUrlProperty = (property: PropertyEntity) => {
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    //TODO: Implement the logic to get the URL of the property as we did in the document
    const typeOfBusinesses = property.businesses?.map(business => i18n(business.typeOfBusiness.name)).join('-') ?? [];
    const typeOfProperties = property.typeProperty?.name ? i18n(property.typeProperty.name) : "";
    const neighborhood = property.location?.neighborhood?.name ?? "";
    const city = property.location?.neighborhood?.zone?.city?.name ?? "";
    const firstPart = [typeOfBusinesses, typeOfProperties, neighborhood, city].filter(part => part != "").join('-');
    const relativeUrl = routes.property.relativePath;
    const rooms = property.rooms == 0 ? "" : property.rooms > 1 ? i18n(KeyWordLocalization.SEOImagePropertyRoomsPlural, { rooms: property.rooms }) : i18n(KeyWordLocalization.SEOImagePropertyRooms, { rooms: property.rooms });
    const restrooms = property.restrooms == 0 ? "" : property.restrooms > 1 ? i18n(KeyWordLocalization.SEOImagePropertyrestroomsPlural, { restrooms: property.restrooms }) : i18n(KeyWordLocalization.SEOImagePropertyrestrooms, { restrooms: property.restrooms });
    const garage = property.garage == 0 ? "" : property.garage > 1 ? i18n(KeyWordLocalization.SEOImagePropertyGaragesPlural, { garages: property.garage }) : i18n(KeyWordLocalization.SEOImagePropertyGarages, { garages: property.garage });
    const secondPart = [rooms, restrooms, garage].filter(part => part != "").join('-');
    const propertyId = "id-" + property.id;
    const url = `${relativeUrl}/${firstPart}/${secondPart.length > 0 ? secondPart : property.name}/`;
    const urlFriendly = url.replace(/ /g, '-').replace(/,/g, '').replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u').replace(/ñ/g, 'n').toLowerCase();
    return urlFriendly + propertyId;
}

const getIdFromFriendlyUrl = (url: string) => {
    const id = url.split('id-')[1];
    return id;
}


const getShortDescriptionOfProperty = (property: PropertyEntity): string => {
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    let shortDescription = "";
    shortDescription += i18n(KeyWordLocalization.SEOImagesPropertyInBusiness, {
        property: i18n(property.typeProperty!.name),
        business: property.businesses?.map(business => i18n(business.typeOfBusiness.name)).join(`, `),
    })
    if (property.rooms > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyRoomsPlural, { rooms: property.rooms });
    else if (property.rooms == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyRooms, { rooms: property.rooms });
    if (property.restrooms > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyrestroomsPlural, { restrooms: property.restrooms });
    else if (property.restrooms == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyrestrooms, { restrooms: property.restrooms });
    if (property.garage > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyGaragesPlural, { garages: property.garage });
    else if (property.garage == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyGarages, { garages: property.garage });
    shortDescription += " " + (property.location?.neighborhood?.zone?.city?.name != null ? i18n(KeyWordLocalization.SEOImagePropertyCity, { city: property.location.neighborhood.zone.city.name }) : "");
    return shortDescription;
}
const getUltraShortDescriptionOfProperty = (property: PropertyEntity): string => {
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    let shortDescription = "";
    if (property.rooms > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyRoomsPlural, { rooms: property.rooms });
    else if (property.rooms == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyRooms, { rooms: property.rooms });
    if (property.restrooms > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyrestroomsPlural, { restrooms: property.restrooms });
    else if (property.restrooms == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyrestrooms, { restrooms: property.restrooms });
    if (property.garage > 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyGaragesPlural, { garages: property.garage });
    else if (property.garage == 1)
        shortDescription += " " + i18n(KeyWordLocalization.SEOImagePropertyGarages, { garages: property.garage });
    return shortDescription;
}
const getMediumDescriptionOfProperty = (property: PropertyEntity): string => {
    //the same of short, but with location, and the price
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    let shortDescription = "";
    shortDescription += i18n(KeyWordLocalization.SEOImagesPropertyInBusiness, {
        property: i18n(property.typeProperty!.name),
        business: property.businesses?.map(business => i18n(business.typeOfBusiness.name)).join(`, `),
    })
    shortDescription += " " + (property.location?.neighborhood?.zone?.city?.name != null ? i18n(KeyWordLocalization.SEOImagePropertyCity, { city: property.location.neighborhood.zone.city.name }) : "");
    shortDescription = StringUtils.makeFirstLetterUpperCase(shortDescription);
    return shortDescription;

}

const searchFriendlyUrl = (typesOfBusinesses: string[], typeOfProperties: string[]) => {
    const relativeUrl = routes.properties.relativePath;
    const typeOfBusiness = typesOfBusinesses.map((type: string) => di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n(type).replace(' ', '')).join('-');
    const typeOfProperty = typeOfProperties.map((type: string) => di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n(type).replace(' ', '')).join('-');
    return `${relativeUrl}/${typeOfBusiness}/${typeOfProperty}`;
}
export default {
    getUrlProperty,
    searchFriendlyUrl,
    getShortDescriptionOfProperty,
    getMediumDescriptionOfProperty,
    getUltraShortDescriptionOfProperty,
    getIdFromFriendlyUrl,
}