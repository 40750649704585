import { injectable } from "inversify";
import FirebaseRepository from "../../repositories/FirebaseRepository";

interface props {
    firebaseRepository: FirebaseRepository;
}

@injectable()
class FireBaseNavigateUseCase {
    _firebaseRepository: FirebaseRepository;

    constructor(_: props) {
        this._firebaseRepository = _.firebaseRepository;
    }

    public call = (page: string) => this._firebaseRepository.navigatePage(page);
}

export default FireBaseNavigateUseCase;

export const FireBaseNavigateUseCaseName = "FireBaseNavigateUseCase";
