import PropertyEntity from "../../../../../domain/entities/PropertyEntity";

const GetLikedPropertiesApiImpl = async (keyProperties: string): Promise<PropertyEntity[]> => {
    // get values saved from local storage
    try {
        const currentLikedProperties = localStorage.getItem(keyProperties);
        if (currentLikedProperties) {
            const properties: PropertyEntity[] = JSON.parse(currentLikedProperties);
            return properties;
        }
        return [];
    }catch (error){
        return [];
    }
}

export default GetLikedPropertiesApiImpl;