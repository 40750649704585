import { useRef, useState } from 'react';
import ProviderProps from '../../../domain/providers/ProviderProps';
import { injectable } from 'inversify';
import PreferencesContextType from '../../../domain/providers/preferences/PreferencesContextType';
import PreferencesContext, { defaultPreferencesContext } from '../../../domain/providers/preferences/PreferencesContext';
import PreferencesProvider from '../../../domain/providers/preferences/PreferencesProvider';
import PropertyEntity from '../../../domain/entities/PropertyEntity';

const _Actions: PreferencesContextType = defaultPreferencesContext;

const _Provider = ({ children }: ProviderProps) => {
  // setLikedProperties: (properties: PropertyEntity[]) => {},

  const [contactInfo, setContactInfo] = useState(defaultPreferencesContext.contactInfo);
  const [cookies, setCookies] = useState(defaultPreferencesContext.cookies);
  const [likedProperties, setLikedProperties] = useState(defaultPreferencesContext.likedProperties);
  const [propertiesAsGreed, setPropertiesAsGreed] = useState(defaultPreferencesContext.propertiesAsGreed);
  const refScrollableDiv = useRef<HTMLDivElement>(null);
  const thisPropertyLikesMe = (property: PropertyEntity) => likedProperties.some(p => p.id == property.id);

  _Actions.contactInfo = contactInfo;
  _Actions.setContactInfo = setContactInfo;
  _Actions.cookies = cookies;
  _Actions.setCookies = setCookies;
  _Actions.likedProperties = likedProperties;
  _Actions.setLikedProperties = setLikedProperties;
  _Actions.propertiesAsGreed = propertiesAsGreed;
  _Actions.setPropertiesAsGreed = setPropertiesAsGreed;
  _Actions.thisPropertyLikesMe = thisPropertyLikesMe;

  return (
    <PreferencesContext.Provider value={{
      contactInfo,
      setContactInfo,
      cookies,
      setCookies,
      likedProperties,
      setLikedProperties,
      thisPropertyLikesMe,
      propertiesAsGreed,
      setPropertiesAsGreed,
      refScrollableDiv,
    }}>
      {children}
    </PreferencesContext.Provider>
  );
};

@injectable()
class PreferencesProviderImpl implements PreferencesProvider {
  public context = PreferencesContext;

  public Provider = _Provider;

  Actions = _Actions
}

export default new PreferencesProviderImpl();

