export enum ButtonType {
    MAIN = 'main',
    MAIN_OUTLINE = 'main_outline',
    SECONDARY = 'secondary',
    SECONDARY_OUTLINE = 'secondary_outline',
    DANGER = 'danger',
}
export default interface ButtonComponentProps {
    onClick?: () => void;
    text: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    onClickIcon?: () => void;
    colorLine?: string;
    type?: ButtonType;
    height?: number | string;
    width?: number | string;
    isLoading?: boolean;
    typeAction?: "button" | "submit" | "reset" | undefined;
}