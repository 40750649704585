import { injectable } from "inversify";
import ContactRepository from "../../repositories/ContactRepository";
import ContactInfoEntity from "../../entities/ContactInfoEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";

interface Props {
    contactRepository: ContactRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class GetContactInfoPreferencesUseCase {
    _contactRepository: ContactRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: Props) {
        this._contactRepository = _.contactRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (): Promise<ContactInfoEntity | undefined> => {
        const response = await this._contactRepository.getContactPreferences();
        this._preferencesProvider.Actions.setContactInfo(response);
        return response;

    }
}

export default GetContactInfoPreferencesUseCase;

export const GetContactInfoPreferencesUseCaseName = "GetContactInfoPreferencesUseCaseName";