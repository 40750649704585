import { FC, useContext } from 'react';
import "./PrivacyPolicyPageStyles.scss";
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';

const _EnglishTC = () => {
    return <>
        <div>
            <h2 className='h3'>Content</h2>
            <ol>
                <li><a href="#personal_information">Personal Information</a></li>
                <li><a href="#consent_collection">Consent and Data Collection</a></li>
                <li><a href="#use_disclosure">Use and Disclosure of Personal Information</a></li>
                <li><a href="#data_analysis_advertising">Data Analysis, Advertising, and Tracking</a></li>
                <li><a href="#cookies">Cookies</a></li>
                <li><a href="#data_retention">Data Retention</a></li>
                <li><a href="#mobile_app">LAC Gestión Mobile App</a></li>
                <li><a href="#data_deletion">Data Deletion</a></li>
                <li><a href="#user_requests_rights">User Requests and Rights</a></li>
                <li><a href="#policy_update">Policy Update</a></li>
                <li><a href="#contact">Contact</a></li>
            </ol>
        </div >
        <div>
            <h2 id="personal_information">1. Personal Information</h2>
            <p>1.1. <strong>Data Collection:</strong> LAC Asesorías Inmobiliarias collects personal information from its users for the purpose of providing and improving its services. This information may include, among other things, names, email addresses, phone numbers, and any other information voluntarily provided by users through online forms, emails, or direct interactions.</p>
            <p>1.2. <strong>Children's Data:</strong> LAC Asesorías Inmobiliarias does not intentionally collect personal information from minors. In the event that it is discovered that personal information has been collected from a minor without verifiable consent from their parents or legal guardians, steps will be taken to remove such information from our servers as soon as possible. The exception to this policy is when legal guardians request a service on behalf of the minor in writing.</p>
            <p>1.3. <strong>Consent:</strong> By providing personal information to LAC Asesorías Inmobiliarias, users consent to the use and disclosure of such information in accordance with the terms of this privacy policy.</p>
            <p>1.4. <strong>Accuracy of Information:</strong> Users are responsible for ensuring the accuracy and updating of the personal information provided to LAC Asesorías Inmobiliarias. If there are changes in personal information, users are encouraged to update their contact details as soon as possible.</p>
            <p>1.5. <strong>Data Retention:</strong> LAC Asesorías Inmobiliarias will retain users' personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.</p>

            <h2 id="consent_collection">2. Consent and Data Collection</h2>
            <p>2.1. <strong>Voluntary Information:</strong> The collection of personal information by LAC Asesorías Inmobiliarias is done voluntarily by users. However, if a user refuses to provide certain information marked as mandatory, they may not be able to access all services offered by LAC Asesorías Inmobiliarias.</p>
            <p>2.2. <strong>Collection Methods:</strong> Personal information may be collected through online forms, emails, direct interactions, or any other method of information collection provided by LAC Asesorías Inmobiliarias.</p>
            <p>2.3. <strong>Obtaining Prior or Future Information:</strong> LAC Asesorías Inmobiliarias may collect previously obtained or future personal information to provide services such as rental, management, and others. This information is stored for administrative and legal purposes.</p>
            <p>2.4. <strong>Usage Information:</strong> LAC Asesorías Inmobiliarias collects usage information, including IP address, when users fill out online forms. Additionally, usage monitoring services are used to analyze and improve the user experience on the website.</p>

            <h2 id="use_disclosure">3. Use and Disclosure of Personal Information</h2>
            <p>3. <strong>Use and Disclosure of Personal Information:</strong> We use personal information to carry out our internal functions, but this may be disclosed to third parties to complete our functions, to whom we disclose the information, service providers, auditors, insurers, etc., as well as third parties who match the interest, for example a rental applicant, we share the information with the seller, we share it by legal requirement, we share it in case we require it for LAC protection for example legal processes, etc.</p>

            <h2 id="data_analysis_advertising">4. Data Analysis, Advertising, and Tracking</h2>
            <p>4. <strong>Data Analysis, Advertising, and Tracking:</strong> We may obtain anonymous information about people's interests, which are carried out and shared through google analytics and Facebook ads, this anonymous information is not associated with a physical person, reason why we cannot perform towards the person, however we can use it to send advertising campaigns based on the interests of the person, this information is obtained through Facebook and google providers, we also share their interaction with the site to them in order to provide more effective campaigns, this sharing of information can be deactivated through cookie settings

            </p>
            <h2 id="cookies">5. Cookies</h2>
            <p>5.1. <strong>Basic Functions:</strong> Mandatory cookies are necessary for the basic operation of the website. They include cookies that allow users to log in, add products to the shopping cart, and remember language preferences.</p>
            <p>5.2. <strong>Analysis:</strong> Analysis cookies allow us to recognize and count the number of visitors, as well as see how visitors move around the website. This helps us improve the way the website works.</p>
            <p>5.3. <strong>Digital Marketing:</strong> Digital marketing cookies are used to track visitor interaction with the website. The intention is to display ads that are relevant and engaging to the individual user, and therefore more valuable to publishers and third-party advertisers.</p>
            <p>5.4. <strong>Disabling Cookies:</strong> Users can disable digital marketing cookies from the cookie management option, which is displayed when interacting with the site at the bottom, giving the option to manage or accept all.</p>
            <h2 id="data_retention">6. Data Retention</h2>
            <p>6. <strong>Data Retention:</strong> We use technological and physical means to maintain data security and ensure its integrity and confidentiality.</p>


            <h2 id="mobile_app">7. LAC Gestión Mobile App</h2>
            <p>   7.1. <strong>Permission Usage:</strong> The app may request permissions to access device features such as the camera and location. These permissions are used to enhance the user experience and provide important app features such as reading QR codes and approximate and exact location for property posting</p>
            <p>7.2. <strong>Data deletion:</strong> Personal data collected through the LAC Gestión mobile app can be deleted by filling out the form <a href="https://forms.gle/rQLp9rFhuFvc7iTV8">here</a> or sending an email to asesorias@lac.com.co. Access to the app will be removed within 5 business days, and data deletion will take place within 60 calendar days, except in cases where retention is required by law as explained in the previous points.</p>
            <p>   7.3. <strong>Deletion from the App:</strong> The account can be deleted from the application in Settings → Delete Account. By doing so, access is removed immediately, and the data is retained as described above.</p>


            <h2 id="data_deletion">8. Data Deletion</h2>
            <p> 8. <strong>Data Deletion:</strong> Personal data can be deleted by filling out the form <a href="https://forms.gle/rQLp9rFhuFvc7iTV8">here</a> or sending an email to asesorias@lac.com.co. Access to the app will be removed within 5 business days, and data deletion will take place within 60 calendar days, except in cases where retention is required by law as explained in the previous points.</p>
            <p> 8.1. <strong>Deletion from the App:</strong> The account can be deleted from the app in Settings &rarr; Delete Account. By doing so, access is immediately removed, and data is retained as described above.</p>

            <h2 id="user_requests_rights">9. User Requests and Rights</h2>
            <p> 9. <strong>User Requests and Rights:</strong> Users have the right to request access, correction, deletion, or restriction of the processing of their personal information. To exercise these rights, users can contact us using the contact information provided in the Contact section. Additionally, users have the right to lodge a complaint with a data protection authority if they believe their rights have been infringed.</p>
            <p> 9.1. <strong>Children's Privacy:</strong> We do not intentionally collect information from minors. If a minor provides information without verifiable consent from their parents or guardians, steps will be taken to remove the information as soon as possible.</p>

            <h2 id="policy_update">10. Policy Update</h2>
            <p>10. <strong>Policy Update:</strong> LAC Asesorías Inmobiliarias reserves the right to update this privacy policy at any time. Users will be encouraged to periodically review this page for any changes. The date of the last update will be indicated at the end of this policy.</p>


            <h2 id="contact">11. Contact</h2>
            <ul>
                <li><strong>Phone:</strong> <a href="tel:+573162885158">3162885158</a></li>
                <li><strong>Address:</strong> Cra 1a4#66-20</li>
                <li><strong>Email:</strong> <a href="mailto:asesorias@lac.com.co">asesorias@lac.com.co</a></li>
                <li><strong>Website Feedback:</strong> <a href="mailto:Marlon.mendez@lac.com.co">Marlon.mendez@lac.com.co</a></li>
            </ul>
        </div >
    </>

}

const _SpanishTC = () => {
    return <>
        <div>
            <h2 className='h3'>Contenido</h2>
            <ol>
                <li><a href="#informacion_personal">Información Personal</a></li>
                <li><a href="#consentimiento_recopilacion">Consentimiento y Recopilación de Datos</a></li>
                <li><a href="#uso_divulgacion">Uso y Divulgación de Información Personal</a></li>
                <li><a href="#analisis_publicidad">Análisis de Datos, Publicidad y Seguimiento</a></li>
                <li><a href="#cookies">Cookies</a></li>
                <li><a href="#conservacion_datos">Conservación de Datos</a></li>
                <li><a href="#app_movil">Aplicación Móvil LAC Gestión</a></li>
                <li><a href="#eliminacion_datos">Eliminación de Datos</a></li>
                <li><a href="#solicitudes_derechos">Solicitudes y Derechos de los Usuarios</a></li>
                <li><a href="#actualizacion_politicas">Actualización de Políticas</a></li>
                <li><a href="#contacto">Contacto</a></li>
            </ol>
        </div>
        <div>
            <h2 id="informacion_personal">1. Información Personal</h2>
            <p>1.1. <strong>Recopilación de Datos:</strong> LAC Asesorías Inmobiliarias recopila información personal de sus usuarios con el fin de proporcionar y mejorar sus servicios. Esta información puede incluir, entre otras cosas, nombres, direcciones de correo electrónico, números de teléfono y cualquier otra información proporcionada voluntariamente por los usuarios a través de formularios en línea, correos electrónicos o interacciones directas.</p>
            <p>1.2. <strong>Datos de Menores:</strong> LAC Asesorías Inmobiliarias no recopila intencionalmente información personal de menores. En caso de que se descubra que se ha recopilado información personal de un menor sin el consentimiento verificable de sus padres o tutores legales, se tomarán medidas para eliminar dicha información de nuestros servidores lo antes posible. La excepción a esta política es cuando los tutores legales solicitan un servicio en nombre del menor por escrito.</p>
            <p>1.3. <strong>Consentimiento:</strong> Al proporcionar información personal a LAC Asesorías Inmobiliarias, los usuarios consienten el uso y divulgación de dicha información de acuerdo con los términos de esta política de privacidad.</p>
            <p>1.4. <strong>Exactitud de la Información:</strong> Los usuarios son responsables de garantizar la exactitud y actualización de la información personal proporcionada a LAC Asesorías Inmobiliarias. Si hay cambios en la información personal, se recomienda a los usuarios que actualicen sus datos de contacto lo antes posible.</p>
            <p>1.5. <strong>Retención de Datos:</strong> LAC Asesorías Inmobiliarias retendrá la información personal de los usuarios durante el tiempo que sea necesario para cumplir con los fines para los cuales fue recopilada, a menos que se requiera o permita un período de retención más prolongado por ley.</p>


            <h2 id="consentimiento_recopilacion">2. Consentimiento y Recopilación de Datos</h2>
            <p>2.1. <strong>Información Voluntaria:</strong> La recopilación de información personal por parte de LAC Asesorías Inmobiliarias se realiza de forma voluntaria por parte de los usuarios. Sin embargo, si un usuario se niega a proporcionar cierta información marcada como obligatoria, es posible que no pueda acceder a todos los servicios ofrecidos por LAC Asesorías Inmobiliarias.</p>
            <p>2.2. <strong>Métodos de Recopilación:</strong> La información personal puede recopilarse a través de formularios en línea, correos electrónicos, interacciones directas u cualquier otro método de recopilación de información proporcionado por LAC Asesorías Inmobiliarias.</p>
            <p>2.3. <strong>Obtención de Información Previa o Futura:</strong> LAC Asesorías Inmobiliarias puede recopilar información personal previamente obtenida o que se obtenga en el futuro para proporcionar servicios como alquiler, administración y otros. Esta información se guarda con el fin de administrar y tener la información legalmente requerida.</p>
            <p>2.4. <strong>Información de Uso:</strong> LAC Asesorías Inmobiliarias recopila información de uso, incluida la dirección IP, cuando los usuarios llenan formularios en línea. Además, se utilizan servicios de monitoreo de uso para analizar y mejorar la experiencia del usuario en el sitio web.</p>


            <h2 id="uso_divulgacion">3. Uso y Divulgación de Información Personal</h2>
            <p>3. <strong>Uso y Divulgación de Información Personal:</strong> Utilizamos la información personal para llevar a cabo nuestras funciones internas, pero esta puede ser divulgada a terceros con el fin de completar nuestras funciones, a quienes divulgamos la información, proveedores de servicios, auditores, aseguradoras, etc, además de terceros que coincidan en el interés, ejemplo un aspirante a arriendo le compartimos la información al vendedor, la compartimos por requerimiento legal, compartimos en caso de que lo requiramos para protección de LAC por ejemplo procesos legales, etc

            </p>
            <h2 id="analisis_publicidad">4. Análisis de Datos, Publicidad y Seguimiento</h2>
            <p>4. <strong>Análisis de Datos, Publicidad y Seguimiento:</strong> Podemos obtener información anónima de intereses de las personas, las cuales se realizan y comparten por medio de google analytics y Facebook ads, esta información anónima no esta asociada a una persona física, razón por la que no podemos realizar hacia la persona, sin embargo podemos utilizarla para enviar campañas publicitarias basada en los intereses de la persona,  esta información es obtenida por medio de los proveedores de Facebook y google, además compartimos su interacción con el sitio hacia ellos con el fin de brindar campañas más efectivas, este compartimiento de información puede ser desactivado por medio de la configuración de las cookies

            </p>
            <h2 id="cookies">5. Cookies</h2>
            <p>5.1. <strong>Funciones Básicas:</strong> Las cookies obligatorias son necesarias para el funcionamiento básico del sitio web. Incluyen cookies que permiten a los usuarios iniciar sesión, agregar productos al carrito de compras y recordar las preferencias de idioma.</p>
            <p>5.2. <strong>Análisis:</strong> Las cookies de análisis nos permiten reconocer y contar el número de visitantes, así como ver cómo se mueven los visitantes por el sitio web. Esto nos ayuda a mejorar la forma en que funciona el sitio web.</p>
            <p>5.3. <strong>Marketing Digital:</strong> Las cookies de marketing digital se utilizan para rastrear la interacción de los visitantes con el sitio web. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y anunciantes externos.</p>
            <p>5.4. <strong>Desactivación de Cookies:</strong> Los usuarios pueden desactivar las cookies de marketing digital desde la opción de administrar cookies, que se muestra al iniciar la interacción con el sitio en la parte inferior, dando la opción de administrar o de aceptar todas.</p>


            <h2 id="conservacion_datos">6. Conservación de Datos</h2>
            <p>6. <strong>Conservación de Datos:</strong> Utilizamos medios tecnológicos y físicos para mantener la seguridad de los datos y garantizar su integridad y confidencialidad.</p>


            <h2 id="app_movil">7. Aplicación Móvil LAC Gestión</h2>
            <p>7.1. <strong>Uso de Permisos:</strong> La aplicación puede solicitar permisos para acceder a funciones del dispositivo, como la cámara y la ubicación. Estos permisos se utilizan con el fin de mejorar la experiencia del usuario y proporcionar funciones importantes de la aplicación, como la lectura de códigos QR y la ubicación aproximada y exacta para la publicación de inmuebles. Los usuarios pueden optar por rechazar estos permisos, pero esto puede afectar el funcionamiento de ciertas funciones de la aplicación.</p>
            <p>7.2 <strong>Eliminación de datos:</strong> Los datos personales recopilados a través de la aplicación móvil LAC Gestión pueden ser eliminados por medio de llenar el formulario <a href="https://forms.gle/rQLp9rFhuFvc7iTV8">aquí</a> o enviando un correo a asesorias@lac.com.co. Los accesos a la aplicación se eliminarán en un periodo de 5 días hábiles, y la eliminación de los datos se hará en un plazo de 60 días calendario, excepto en los casos donde se pueda retener más por ley como se explica en los puntos anteriores.</p>
            <p>7.3. <strong>Eliminación desde la Aplicación:</strong> La cuenta puede ser eliminada desde la aplicación en Ajustes &rarr; Eliminar Cuenta. Al hacerlo, el acceso se elimina inmediatamente, y los datos se conservan de acuerdo a lo anteriormente descrito.</p>


            <h2 id="eliminacion_datos">8. Eliminación de Datos</h2>
            <p>8. <strong>Eliminación de Datos:</strong> Los datos personales pueden ser eliminados por medio de llenar el formulario <a href="https://forms.gle/rQLp9rFhuFvc7iTV8">aquí</a> o enviando un correo a asesorias@lac.com.co. Los accesos a la aplicación se eliminarán en un periodo de 5 días hábiles, y la eliminación de los datos se hará en un plazo de 60 días calendario, excepto en los casos donde se pueda retener más por ley como se explica en los puntos anteriores.</p>
            <p>8.1. <strong>Eliminación desde la Aplicación:</strong> La cuenta puede ser eliminada desde la aplicación en Ajustes &rarr; Eliminar Cuenta. Al hacerlo, el acceso se elimina inmediatamente, y los datos se conservan de acuerdo a lo anteriormente descrito.</p>

            <h2 id="solicitudes_derechos">10. Solicitudes y Derechos de los Usuarios</h2>
            <p>9. <strong>Solicitudes y Derechos de los Usuarios:</strong> Los usuarios tienen derecho a solicitar acceso, corrección, eliminación o restricción del procesamiento de su información personal. Para ejercer estos derechos, los usuarios pueden comunicarse con nosotros utilizando la información de contacto proporcionada en la sección de Contacto. Además, los usuarios tienen derecho a presentar una queja ante una autoridad de protección de datos si consideran que sus derechos han sido infringidos.</p>
            <p>9.1. <strong>Privacidad de Menores:</strong> No recopilamos intencionalmente información de menores de edad. Si un menor proporciona información sin el consentimiento verificable de sus padres o tutores, se tomarán medidas para eliminar la información lo antes posible.</p>


            <h2 id="actualizacion_politicas">11. Actualización de Políticas</h2>
            <p>10. <strong>Actualización de Políticas:</strong> LAC Asesorías Inmobiliarias se reserva el derecho de actualizar esta política de privacidad en cualquier momento. Se alentará a los usuarios a revisar periódicamente esta página para conocer cualquier cambio. La fecha de la última actualización se indicará al final de esta política.</p>


            <h2 id="contacto">11. Contacto</h2>
            <ul>
                <li><strong>Teléfono:</strong> <a href="tel:+573162885158">3162885158</a></li>
                <li><strong>Dirección:</strong> Cra 1a4#66-20</li>
                <li><strong>Correo electrónico:</strong> <a href="mailto:asesorias@lac.com.co">asesorias@lac.com.co</a></li>
                <li><strong>Comentarios sobre el sitio web y sus funciones:</strong> <a href="mailto:Marlon.mendez@lac.com.co">Marlon.mendez@lac.com.co</a></li>
            </ul>

        </div>
    </>
}

const PrivacyPolicyPage: FC<{}> = () => {
    const { i18n, localization } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="privacy_policy_page container-fluid">
        <div className="row">
            <div className="col-12 o-banner-container" style={{ backgroundImage: 'url(/assets/images/terms_policy_image.png' }}>
                <div className='container'>
                    <h1 className='o-banner-information-title h5 text_color_main text_bold'>{i18n(KeyWordLocalization.PrivacyPolicyPageTitle)}</h1>
                    <h2 className='h6 text-white font_bold'>{i18n(KeyWordLocalization.FavoritesPageLAC)}</h2>
                </div>
            </div>
        </div>
        <div className='py-5 container policy_and_terms_container'>
            {localization.code == 'en' ? <_EnglishTC /> : <_SpanishTC />}
        </div>
    </div>
};

export default PrivacyPolicyPage;