import LocationEntity from "../../../domain/entities/LocationEntity";
import NeighborhoodDto from "./NeighborhoodDto";
import PropertyDto from "./PropertyDto";

const fromJSON = (json: any): LocationEntity => {
    return {
        id: json['fake_id'],
        createdAt: json['created_at'] != null ? new Date(json['created_at']) : undefined,
        updatedAt: json['updated_at'] != null ? new Date(json['updated_at']) : undefined,
        address: json['address'],
        apartment: json['apartment'],
        mapsName: json['maps_name'],
        mapsAddress: json['maps_address'],
        mapsLat: json['maps_lat'],
        mapsLong: json['maps_lng'],
        placeId: json['place_id'],
        neighborhood: NeighborhoodDto.fromJSON(json['neighborhood']),
        property: json['property'] ? PropertyDto.fromJSON(json['property']) : undefined,
        propertyId: json['property_id'],
    };
}

export default {
    fromJSON,
}