import React, { useContext, useEffect, useState } from "react";
import RoutesComponentProps from "./RoutesComponentProps";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LayoutComponent from "../components/Layout/LayoutComponent";
import UserEntity from "../../domain/entities/UserEntity";
import UserContext from "../../domain/providers/user/UserContext";
import UserContextType from "../../domain/providers/user/UserContextType";
import di from "../../dependencyInjection/DependencyInjection";
import LoadUseCase, { LoadUseCaseName } from "../../domain/use_cases/default/LoadUseCase";
import Error404Page from "../pages/error404/Error404Page";
import routes from "./Routes";


const RoutesComponent: React.FC<RoutesComponentProps> = ({ children }) => {
    const { user } = useContext(UserContext) as UserContextType;
    const [loaded, setLoaded] = useState<boolean>(true);

    const _load = async () => {
        try {
            await di.get<LoadUseCase>(LoadUseCaseName).call();
        } catch (_) {
        }
        setLoaded(true);
    }

    useEffect(() => {
        _load();
    }, []);

    if (!loaded) return <div className="bg_1" style={{ width: "100vw", height: "100vh" }}>
        <LoadingComponent showLogo />
    </div>
    return <>
        <BrowserRouter>
            <Routes>
                {Object.values(routes).map((route: any) => <Route key={route.path} path={route.path} element={
                    route.auth(user) ? <LayoutComponent> {React.createElement(route.component, {}, undefined)}</LayoutComponent> : <LayoutComponent><Error404Page /></LayoutComponent>
                }>
                </Route>)}
                <Route path="*" element={<LayoutComponent><Error404Page /></LayoutComponent>} />
            </Routes>
        </BrowserRouter >
    </>
}

export default RoutesComponent;
export { RoutesComponent };
