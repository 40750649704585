import { injectable } from "inversify";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import PropertyRepositoryFake from "./PropertyRepositoryFake";
import PropertyRepositoryImpl from "./PropertyRepositoryImpl";

@injectable()
class PropertyRepositoryDev extends PropertyRepositoryImpl {
    KEY_PREFERENCES_LIKED_PROPERTIES = "likedProperties";
    KEY_PREFERENCES_SAW_PROPERTIES = "seen_properties";
    fakeRepository = new PropertyRepositoryFake();
    applyToRentProperty = (params: { message: string; propertyId: string; rentSolicitudeFile: File; tenant: { name: string; lastname: string; email: string; dial: string; phone: string; identification: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; }; coDebtor: { name: string; lastname: string; email: string; dial: string; phone: string; identification: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; }; }): Promise<Either<ExceptionEntity, void>> => this.fakeRepository.applyToRentProperty(params);
}

export default PropertyRepositoryDev;