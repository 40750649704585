import { FC } from "react";
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../../../routes/Routes';

const FooterLinksComponentEs: FC<{}> = () => {
    const { businesses, types, city } = useParams();

    const selling = businesses ? businesses.split('-').length > 1 || businesses.includes('venta') : true;
    const renting = businesses ? businesses.split('-').length > 1 || businesses.includes('alquiler') : true;
    const onlyCali = city ? city == 'cali' : false;
    const onlyJamundi = city == "jamundi";

    const links = [
        {
            name: 'Casas en venta en Cali',
            link: `${routes.properties.relativePath}/venta/casa/cali`,
            priority: 1,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Apartamentos en venta en Cali',
            link: `${routes.properties.relativePath}/venta/apartamento/cali`,
            priority: 1,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Apartaestudios en venta en Cali',
            link: `${routes.properties.relativePath}/venta/apartaestudio/cali`,
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Bodegas en venta en Cali',
            link: `${routes.properties.relativePath}/venta/bodega/cali`,
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Casas campestres en venta en Cali',
            link: `${routes.properties.relativePath}/venta/casa-campestre/cali`,
            priority: 5,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Fincas en venta en Cali',
            link: `${routes.properties.relativePath}/venta/finca/cali`,
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Locales en venta en Cali',
            link: `${routes.properties.relativePath}/venta/local/cali`,
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Lotes en venta en Cali',
            link: `${routes.properties.relativePath}/venta/lote/cali`,
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Oficinas en venta en Cali',
            link: `${routes.properties.relativePath}/venta/oficina/cali`,
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Granjas en venta en Cali',
            link: `${routes.properties.relativePath}/venta/granja/cali`,
            priority: 6,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            name: 'Casas en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/casa/cali`,
            priority: 1,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Apartamentos en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/apartamento/cali`,
            priority: 1,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Apartaestudios en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/apartaestudio/cali`,
            priority: 2,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Bodegas en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/bodega/cali`,
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Casas campestres en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/casa-campestre/cali`,
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Fincas en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/finca/cali`,
            priority: 3,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Locales en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/local/cali`,
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Lotes en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/lote/cali`,
            priority: 3,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Oficinas en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/oficina/cali`,
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Granjas en alquiler en Cali',
            link: `${routes.properties.relativePath}/alquiler/granja/cali`,
            priority: 6,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            name: 'Casas en venta Jamundí',
            link: `${routes.properties.relativePath}/venta/casa/jamundi`,
            priority: 1,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Apartamentos en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/apartamento/jamundi`,
            priority: 1,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Apartaestudios en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/apartaestudio/jamundi`,
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Bodegas en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/bodega/jamundi`,
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Casas campestres en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/casa-campestre/jamundi`,
            priority: 5,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Fincas en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/finca/jamundi`,
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Locales en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/local/jamundi`,
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Lotes en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/lote/jamundi`,
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Oficinas en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/oficina/jamundi`,
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Granjas en venta en Jamundí',
            link: `${routes.properties.relativePath}/venta/granja/jamundi`,
            priority: 6,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            name: 'Casas en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/casa/jamundi`,
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Apartamentos en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/apartamento/jamundi`,
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Apartaestudios en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/apartaestudio/jamundi`,
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Bodegas en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/bodega/jamundi`,
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Casas campestres en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/casa-campestre/jamundi`,
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Fincas en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/finca/jamundi`,
            priority: 3,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Locales en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/local/jamundi`,
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Lotes en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/lote/jamundi`,
            priority: 3,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Oficinas en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/oficina/jamundi`,
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            name: 'Granjas en alquiler en Jamundí',
            link: `${routes.properties.relativePath}/alquiler/granja/jamundi`,
            priority: 6,
            condition: onlyJamundi || (renting && !onlyCali)
        }
    ]

    const sortBy = (a: any, b: any): number => {
        if (a.priority != b.priority) {
            return a.priority - b.priority
        }
        else return a.name.localeCompare(b.name)
    }
    
    return <div className="container py-5 suggest_box">
        <div className="row">
            <div className="mb-3">
                <h2 className="w-100 text-center text_color_main h5">Sugerencias de busqueda</h2>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3  text-center text-sm-start">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(0, 5).map((l, i) => <div key={i} >
                    <h4 className='text_ultra_small'>
                        <Link to={l.link} className="text-capitalize">{l.name}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(5, 10).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={l.link} className="text-capitalize">{l.name}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-md-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(10, 15).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={l.link} className="text-capitalize">{l.name}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-lg-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(15, 20).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={l.link} className="text-capitalize">{l.name}</Link>
                    </h4>
                </div>)}
            </div>
        </div>
    </div>
}

export default FooterLinksComponentEs;