const KeyWordLocalization = {
    TRADE_MARK: 'TRADE_MARK',
    
    //#region---------------------- PAGES ----------------------//
    PropertiesPageResult: 'PropertiesPageResult',
    PropertiesPageLoading: 'PropertiesPageLoading',
    PropertiesPageSeeIn: 'PropertiesPageSeeIn',
    PropertiesPageGrid: 'PropertiesPageGrid',
    PropertiesPageList: 'PropertiesPageList',
    PropertiesPageOrderBy: 'PropertiesPageOrderBy',
    PropertiesPageFeatured: 'PropertiesPageFeatured',
    PropertiesPageMoreRecent: 'PropertiesPageMoreRecent',
    PropertiesPageMoreAncient: 'PropertiesPageMoreAncient',
    PropertiesPagePriceAsc: 'PropertiesPagePriceAsc',
    PropertiesPagePriceDesc: 'PropertiesPagePriceDesc',
    PropertiesPageMoreSeen: 'PropertiesPageMoreSeen',
    PropertiesPageMoreLiked: 'PropertiesPageMoreLiked',
    PropertiesPageTotalLoading: 'PropertiesPageTotalLoading',
    PropertiesPageClearAllFilters: 'PropertiesPageClearAllFilters',

    ContactUsPageTitle: 'ContactUsPageTitle',
    ContactUsPageTitle2: "ContactUsPageTitle2",
    ContactUsPageDescription: 'ContactUsPageDescription',
    ContactUsPageName: 'ContactUsPageName',
    ContactUsPageLastName: 'ContactUsPageLastName',
    ContactUsPageEmail: 'ContactUsPageEmail',
    ContactUsPagePhone: 'ContactUsPagePhone',
    ContactUsPageMessage: 'ContactUsPageMessage',
    ContactUsPageSuscribe: 'ContactUsPageSuscribe',
    ContactUsPageSavePreferences: 'ContactUsPageSavePreferences',
    ContactUsPageTermsAndConditions: 'ContactUsPageTermsAndConditions',
    ContactUsPageButton: 'ContactUsPageButton',
    ContactUsPageContactData: 'ContactUsPageContactData',
    ContactUsPageFaceToFaceCare: 'ContactUsPageFaceToFaceCare',
    ContactUsPageAddress: 'ContactUsPageAddress',
    ContactUsPageCareer: 'ContactUsPageCareer',
    ContactUsPageHouse: 'ContactUsPageHouse',
    ContactUsPageOpeningHours: 'ContactUsPageOpeningHours',
    ContactUsPageMondayToFriday: 'ContactUsPageMondayToFriday',
    ContactUsPageSaturday: 'ContactUsPageSaturday',

    FavoritesPageTitle: "FavoritesPageTitle",
    FavoritesPageLAC: "FavoritesPageLAC",
    FavoritesPageNoFavoritesMiniTitle: "FavoritesPageNoFavoritesMiniTitle",
    FavoritesPageNoFavoritesTitle: "FavoritesPageNoFavoritesTitle",
    FavoritesPageHowAddFavorites: "FavoritesPageHowAddFavorites",
    FavoritesPageSeeProperties: "FavoritesPageSeeProperties",

    TermsAndConditionsPageTitle: "TermsAndConditionsPageTitle",

    PrivacyPolicyPageTitle: "PrivacyPolicyPageTitle",
    //#endregion---------------------- PAGES ----------------------//

    //#region---------------------- COMPONENTS ----------------------//
    HeaderComponent_: 'HeaderComponent_',
    HeaderComponent_sell: 'HeaderComponent_sell',
    HeaderComponent_rent: 'HeaderComponent_rent',
    HeaderComponent_permutation: 'HeaderComponent_permutation',
    HeaderComponentMap: 'HeaderComponentMap',
    HeaderComponentProperties: "HeaderComponentProperties",
    HeaderComponentServices: "HeaderComponentServices",
    HeaderComponentServicesSell: "HeaderComponentServicesSell",
    HeaderComponentServicesBuy: "HeaderComponentServicesBuy",
    HeaderComponentServicesRent: "HeaderComponentServicesRent",
    HeaderComponentServicesAdministration: "HeaderComponentServicesAdministration",
    HeaderComponentServicesRealStateAdvice: "HeaderComponentServicesRealStateAdvice",
    HeaderComponentServicesInvestment: "HeaderComponentServicesInvestment",
    HeaderComponentAboutUs: "HeaderComponentAboutUs",
    HeaderComponentTools: "HeaderComponentTools",
    HeaderComponentToolsNotarialChargesCalculator: "HeaderComponentToolsNotarialChargesCalculator",
    HeaderComponentToolsGuides: "HeaderComponentToolsGuides",
    HeaderComponentToolsAddRentPayment: "HeaderComponentToolsAddRentPayment",
    HeaderComponentToolsCreditCalculator: "HeaderComponentToolsCreditCalculator",
    HeaderComponentContactUs: "HeaderComponentContactUs",
    HeaderComponentBlog: "HeaderComponentBlog",
    HeaderComponentFavorites: "HeaderComponentFavorites",

    FooterComponentContact: "FooterComponentContact",

    PropertydFavoriteComponentWithoutFavorites: "PropertydFavoriteComponentWithoutFavorites", //icon

    SideFilterWordSearcher: "SideFilterWordSearcher",
    SideFilterWordSearch: "SideFilterWordSearch",
    SideFilterWordSort: "SideFilterWordSort",
    SideFilterWordSortNewest: "SideFilterWordSortNewest",
    SideFilterWordSortOldest: "SideFilterWordSortOldest",
    SideFilterWordSortPriceAsc: "SideFilterWordSortPriceAsc",
    SideFilterWordSortPriceDesc: "SideFilterWordSortPriceDesc",
    SideFilterWordSortMoreLiked: "SideFilterWordSortMoreLiked",
    SideFilterWordSortMoreSeen: "SideFilterWordSortMoreSeen",
    SideFilterCity: "SideFilterCity",
    SideFilterCharacteristics: "SideFilterCharacteristics",
    SideFilterPrice: "SideFilterPrice",
    SideFilterRooms: "SideFilterRooms",
    SideFilterBathrooms: "SideFilterBathrooms",
    SideFilterArea: "SideFilterArea",
    SideFilterGarage: "SideFilterGarage",
    SideFilterStratum: "SideFilterStratum",
    SideFilterState: "SideFilterstate",
    SideFilterSearch: "SideFilterSearch",

    PaginatorComponentShowing: "PaginatorComponentShowing", //showing, total

    TopSearcherBusinessType: "TopSearcherBusinessType",
    TopSearcherPropertyType: "TopSearcherPropertyType",

    CardPropertyComponentArea: "CardPropertyComponentArea",
    CardPropertyComponentRooms: "CardPropertyComponentRooms",
    CardPropertyComponentRestrooms: "CardPropertyComponentRestrooms",
    CardPropertyComponentFloors: "CardPropertyComponentFloors",

    SideComponentTags: "SideComponentTags",
    SideComponentLocation: "SideComponentLocation",
    SideComponentNeighborhood: "SideComponentNeighborhood",
    SideComponentZones: "SideComponentZones",

    ContactPropertyComponentContactMe: 'ContactPropertyComponentContactMe',
    ContactPropertyComponentWhatsAppLink: 'ContactPropertyComponentWhatsAppLink', //link
    ContactPropertyComponentMailSubject: 'ContactPropertyComponentMailSubject',
    ContactPropertyComponentMailBody: 'ContactPropertyComponentMailBody', //link

    ContactFormPropertyComponentName: "ContactFormPropertyComponentName",
    ContactFormPropertyComponentLastname: "ContactFormPropertyComponentLastname",
    ContactFormPropertyComponentEmail: "ContactFormPropertyComponentEmail",
    ContactFormPropertyComponentPhone: "ContactFormPropertyComponentPhone",
    ContactFormPropertyComponentDial: "ContactFormPropertyComponentDial",
    ContactFormPropertyComponentMessage: "ContactFormPropertyComponentMessage",
    ContactFormPropertyComponentSubscribe: "ContactFormPropertyComponentSubscribe",
    ContactFormPropertyComponentSavePreference: "ContactFormPropertyComponentSavePreference",
    ContactFormPropertyComponentAccept: "ContactFormPropertyComponentAccept",
    ContactFormPropertyComponentSubmit: "ContactFormPropertyComponentSubmit",
    ContactFormPropertyComponentPhoneNoIndex: "ContactFormPropertyComponentPhoneNoIndex",

    NeedLoanComponentTitle: "NeedLoanComponentTitle",
    NeedLoanComponentWithoutAsociatedText: "NeedLoanComponentWithoutAsociatedText",
    NeedLoanComponentMortgage: "NeedLoanComponentMortgage",
    NeedLoanComponentLeasing: "NeedLoanComponentLeasing",
    NeedLoanComponentFinance: "NeedLoanComponentFinance",
    NeedLoancomponentMonthlyAproximately: "NeedLoancomponentMonthlyAproximately",
    NeedLoanComponentWantFinance: "NeedLoanComponentWantFinance",
    NeedLoanComponentClarifications: "NeedLoanComponentClarifications",
    NeedLoanComponentWhatsApp: "NeedLoanComponentWhatsApp",

    HelpSearchPropertyComponentTitle: "HelpSearchPropertyComponentTitle",
    HelpSearchPropertyComponentBody: "HelpSearchPropertyComponentBody",  
    HelpSearchPropertyComponentButton: "HelpSearchPropertyComponentButton",
    HelperSearchPropertyComponentWhatsapp: "HelperSearchPropertyComponentWhatsapp",
    HelpSearchPropertyComponentHelpImageTitle: "HelpSearchPropertyComponentHelpImageTitle",

    ShareLinkModalComponentWhatsapp: "ShareLinkModalComponentWhatsapp",
    ShareLinkModalComponentFacebook: "ShareLinkModalComponentFacebook",
    ShareLinkModalComponentX: "ShareLinkModalComponentX",
    ShareLinkModalComponentTelegram: "ShareLinkModalComponentTelegram",
    ShareLinkModalComponentCopyClipboard: "ShareLinkModalComponentCopyClipboard",
    ShareLinkModalComponentLine: "ShareLinkModalComponentLine",
    ShareLinkModalComponentLinkedIn: "ShareLinkModalComponentLinkedIn",
    ShareLinkModalComponentEmail: "ShareLinkModalComponentEmail",

    LoadingComponentLoading: "LoadingComponentLoading",

    FinishModalErrorBody: "FinishModalErrorBody",
    FinishModalErrorTitle: "FinishModalErrorTitle",
    FinishModalSuccessBody: "FinishModalSuccessBody",
    FinishModalSuccessTitle: "FinishModalSuccessTitle",
    FinishModalSuccessButtonText: "FinishModalSuccessButtonText",

    //#endregion---------------------- COMPONENTS ----------------------//

    //#region---------------------- OTHERS ----------------------//
    SEOProperty: "SEOProperty",
    SEOImagesPropertyInBusiness: "SEOImagesPropertyInBusiness", //property, business
    SEOImagePropertyrestrooms: "SEOImagePropertyrestrooms", //restrooms
    SEOImagePropertyrestroomsPlural: "SEOImagePropertyrestroomsPlural", //restrooms
    SEOImagePropertyRooms: "SEOImagePropertyRooms", //rooms
    SEOImagePropertyRoomsPlural: "SEOImagePropertyRoomsPlural", //rooms
    SEOImagePropertyGarages: "SEOImagePropertyGarages", //garages
    SEOImagePropertyGaragesPlural: "SEOImagePropertyGaragesPlural", //garages
    SEOImagePropertyArea: "SEOImagePropertyArea", //area
    SEOImagePropertyCity: "SEOImagePropertyCity", //city
    SEOImagePropertyStratum: "SEOImagePropertyStratum", //stratum
    SEOPrice: "SEOPrice", //business
    SEOPropertiesAvailables: "SEOPropertiesAvailables",
    SEOPropertiesPropertyAvailables: "SEOPropertiesPropertyAvailables", //property
    SEOImagesPropertyInBusinessWithCity: "SEOImagesPropertyInBusinessWithCity", //property, business, city
    SEOImagesPropertyInBusinessWithoutBusinessWithCity: "SEOImagesPropertyInBusinessWithoutBusinessWithCity", //property, city

    GeneralDescriptionMetaTag: "GeneralDescriptionMetaTag",
    GeneralKeywordsMetaTag: "GeneralKeywordsMetaTag",
    GeneralTitleMetaTag: "GeneralTitleMetaTag",

    PropertiesMetaTagComponentTitle: "PropertiesMetaTagComponentTitle", //typeProperty, city
    PropertiesMetaTagComponentInvestmentProperty: "PropertiesMetaTagComponentInvestmentProperty",
    PropertiesMetaTagComponentInvestmentTitle: "PropertiesMetaTagComponentInvestmentTitle", //typeProperty, city, //typeBusiness
    PropertiesMetaTagComponentDescription: "PropertiesMetaTagComponentDescription", //
    PropertiesMetaTagComponentInvestmentDescription: "PropertiesMetaTagComponentInvestmentDescription", //city
    PropertiesMetaTagComponentKeywords: "PropertiesMetaTagComponentKeywords", //typeProperty, typeBusiness, city, tags

    DetailedPropertyMetaTagComponentNotFoundTitle: "DetailedPropertyMetaTagComponentNotFoundTitle",
    DetailedPropertyMetaTagComponentNotFoundSubtitle: "DetailedPropertyMetaTagComponentNotFoundTitle", //ID
    DetailedPropertyMetaTagComponentTitle: "DetailedPropertyMetaTagComponentTitle", //typeProperty, business, neighborhood, city
    DetailedPropertyMetaTagComponentSubtitle: "DetailedPropertyMetaTagComponentSubtitle", //typeProperty, business, zone, neighborhood, city, area, rooms, restrooms, stratum, prices, garages
    DetailedPropertyMetaTagComponentKeyWords: "DetailedPropertyMetaTagComponentKeyWords", //typeProperty. business, neighborhood, city, zone, area, garage, rooms, stratum

    FavoritesPageMetaTagComponentTitle: "FavoritesPageMetaTagComponentTitle",
    FavoritesPageMetaTagComponentDescription: "FavoritesPageMetaTagComponentDescription",
    FavoritesPageMetaTagComponentKeyWords: "FavoritesPageMetaTagComponentKeyWords",

    ContactUsPageMetaTagComponentTitle: "ContactUsPageMetaTagComponentTitle",
    ContactUsPageMetaTagComponentDescription: "ContactUsPageMetaTagComponentDescription",
    ContactUsPageMetaTagComponentKeyWords: "ContactUsPageMetaTagComponentKeyWords",

    TermsAndConditionsPageMetaTagComponentTitle: "TermsAndConditionsPageMetaTagComponentTitle",
    TermsAndConditionsPageMetaTagComponentDescription: "TermsAndConditionsPageMetaTagComponentDescription",
    TermsAndConditionsPageMetaTagComponentKeyWords: "TermsAndConditionsPageMetaTagComponentKeyWords",

    PrivacyPolicyPageMetaTagComponentTitle: "PrivacyPolicyPageMetaTagComponentTitle",
    PrivacyPolicyPageMetaTagComponentDescription: "PrivacyPolicyPageMetaTagComponentDescription",
    PrivacyPolicyPageMetaTagComponentKeyWords: "PrivacyPolicyPageMetaTagComponentKeyWords",

    OtherIn: "OtherIn",
    OtherColombia: "OtherColombia",
    OtherCaliOrJamundi: "OtherCaliOrJamundi",

    AdrianaShortName: "AdrianaShortName",
    AdrianaPhone: "AdrianaPhone",
    AdrianaPhone2: "AdrianaPhone2",
    AdrianaEmail: "AdrianaEmail",
    MarlonShortName: "MarlonShortName",
    MarlonPhone: "MarlonPhone",
    MarlonEmail: "MarlonEmail",
    //#endregion---------------------- SEO ----------------------//

    //#region---------------------- VALIDATORS ----------------------//
    ValidatorRequired: 'ValidatorRequired',
    ValidatorEmail: 'ValidatorEmail',
    ValidatorMinLength: 'ValidatorMinLength',
    ValidatorMaxLength: 'ValidatorMaxLength',
    ValidatorMinValue: 'ValidatorMinValue',
    ValidatorMaxValue: 'ValidatorMaxValue',
    ValidatorMustBeNumber: 'ValidatorMustBeNumber',
    ValidatorSpecialCharactersIsRequired: 'ValidatorSpecialCharactersIsRequired',
    ValidatorUpperCaseIsRequired: 'ValidatorUpperCaseIsRequired',
    ValidatorLowerCaseIsRequired: 'ValidatorLowerCaseIsRequired',
    ValidatorNumberIsRequired: 'ValidatorNumberIsRequired',
    ValidatorMustBeEqual: 'ValidatorMustBeEqual',
    ValidatorSpecialCharacterIsNotAllowed: 'ValidatorSpecialCharacterIsNotAllowed',
    ValidatorUpperCaseIsNotAllowed: 'ValidatorUpperCaseIsNotAllowed',
    ValidatorLowerCaseIsNotAllowed: 'ValidatorLowerCaseIsNotAllowed',
    ValidatorNumberIsNotAllowed: 'ValidatorNumberIsNotAllowed',
    ValidatorIsNotName: 'ValidatorIsNotName',
    ValidatorIsNotPhone: 'ValidatorIsNotPhone',
    ValidatorGreaterThan: 'ValidatorGreaterThan', //value
    ValidatorLessThan: 'ValidatorLessThan', //value
    ValidatorMustHavePartBeforeAt: 'ValidatorMustHavePartBeforeAt',
    //#endregion---------------------- VALIDATORS ----------------------//

    //#region---------------------- SERVER TABLES ----------------------//
    //type business
    sell: 'sell',
    rent: 'rent',
    permutation: 'permutation',

    //type properties
    apartment: 'apartment',
    studioApartment: 'studioApartment',
    warehouse: 'warehouse',
    house: 'house',
    countryHouse: 'countryHouse',
    estate: 'estate',
    local: 'local',
    lot: 'lot',
    office: 'office',
    farm: "farm",
    apartmentPlural: 'apartmentPlural',
    studioApartmentPlural: 'studioApartmentPlural',
    warehousePlural: 'warehousePlural',
    housePlural: 'housePlural',
    countryHousePlural: 'countryHousePlural',
    estatePlural: 'estatePlural',
    localPlural: 'localPlural',
    lotPlural: 'lotPlural',
    officePlural: 'officePlural',
    farmPlural: "farmPlural",

    //tag filters
    outstanding: "outstanding",
    new: "new",
    unique_oportunity: "unique_oportunity",
    investment_oportunity: "investment_oportunity",
    coming_soon: "coming_soon",

    //#endregion---------------------- SERVER TABLES ----------------------//


    //interest points
    attached: 'attached',
    integralKitchen: 'integralKitchen',
    ceramicFloor: 'ceramicFloor',
    lavanderyZone: 'lavanderyZone',
    auxiliarRestroom: 'auxiliarRestroom',
    singleFamily: 'singleFamily',
    closet: 'closet',
    panoramicView: 'panoramicView',
    pavedAccess: 'pavedAccess',
    schoolsSoon: 'schoolsSoon',
    universitySoon: 'universitySoon',
    publicTransport: 'publicTransport',
    supermarket: 'supermarket',
    park: 'park',
    urbanSoon: 'urbanSoon',
    onMainRoad: 'onMainRoad',

    MasterItemEntityKitchenIntegralKitchen: "MasterItemEntityKitchenIntegralKitchen",

    //banner
    what_can_we_help_you: 'what_can_we_help_you',
    what_can_we_help_you_subtitle: 'what_can_we_help_you_subtitle',
    properties_banner: 'properties_banner',
    properties_banner_subtitle: 'properties_banner_subtitle',
    about_us_banner: 'about_us_banner',
    about_us_banner_subtitle: 'about_us_banner_subtitle',
    contact_banner_subtitle: 'contact_banner_subtitle',
    i_need_real_state_advice: 'i_need_real_state_advice',
    i_want_to_sell_my_property: 'i_want_to_sell_my_property',
    see_more: 'see_more',
    see_less: 'see_less',

    //property
    cop: 'cop',
    and: 'and',
    in_neighborhood: 'in_neighborhood',
    of: 'of',
    basic_features: 'basic_features',
    aditional_features: 'aditional_features',
    description: 'description',
    likes_apply_to: 'likes_apply_to',
    apply: 'apply',


    //outstanding
    last_properties: 'last_properties',
    outstanding_properties: 'outstanding_properties',

    //features
    area: 'area',
    restrooms: 'restrooms',
    rooms: 'rooms',
    private_area: 'private_area',
    builded_area: 'builded_area',
    type_kitchen: 'type_kitchen',
    build_year: 'build_year',
    stratum: 'stratum',
    floors: 'floors',
    parkings: 'parkings',
    state_property: 'state_property',
    price_admin: 'price_admin',
    new_property: 'new_property',
    used_property: 'used_property',
    share_property: 'share_property',
    select_where_you_want_to_share_this_property: 'select_where_you_want_to_share_this_property',
    download_pdf: 'download_pdf',
    zones: 'zones',
    neighborhoods: 'neighborhoods',
    status: 'status',

    //contact
    contact_form_name: 'contact_form_name',
    contact_form_lastname: 'contact_form_lastname',
    contact_form_document_number: 'contact_form_document_number',
    contact_form_document: 'contact_form_document',
    contact_form_email: 'contact_form_email',
    contact_form_phone: 'contact_form_phone',
    contact_form_comment: 'contact_form_comment',
    contact_form_receive_notifications: 'contact_form_receive_notifications',
    contact_form_contact_me: 'contact_form_contact_me',
    contact_form_accept: 'contact_form_accept',
    contact_form_dial: 'contact_form_dial',
    contact_form_social_message: 'contact_form_social_message',

    //services
    services_title: 'services_title',
    //Error page
    ups: 'oops!_this_page_is_not_available',
    this_happens_because_the_page_was_moved: 'this_happens_because_the_page_was_moved',
    home_page: 'home_page',
    go_back_to_home: 'go_back_to_home',

    //Privacy policy
    privacy_policy_title: 'privacy_policy_title',
    privacy_policy_html: 'privacy_policy_html',

    //terms and conditions
    terms_and_conditions_title: 'terms_and_conditions_title',
    terms_and_conditions_html: 'terms_and_conditions_html',

    //utils
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',

    //comments
    comment_it: 'comment_it',
    comment_set_private: 'comment_set_private',
    comment: 'comment',

    //administration service
    administration_title: 'administration_title',
    administration_content: 'administration_content',
    administration_aditional_1: 'administration_aditional_1',

    //selling service
    selling_title: 'selling_title',
    selling_content: 'selling_content',

    //advice service
    advice_title: 'advice_title',
    advice_content: 'advice_content',

    //about us
    about_us_title: 'about_us_title',
    about_us_content: 'about_us_content',

    //Change Password
    change_of_password: 'change_of_password',
    new_password: 'new_password',
    confirm_the_new_password: 'confirm_the_new_password',
    change_my_password: 'change_my_password',

    //Recovery Password
    recover_password: 'recover_password',
    please_enter_the_email_address_asociated: 'please_enter_the_email_address_asociated',
    email: 'email',
    continue: 'continue',

    //validators
    validator_required: 'validator_required',
    validator_not_email: 'validator_not_email',
    validator_not_phone: 'validator_not_phone',
    validator_min_length: 'validator_min_length',
    validator_max_length: 'validator_max_length',
    validator_min_document: 'validator_min_document',
    validator_max_document: 'validator_max_document',

    //login
    login_title: 'login_title',
    login_email: 'login_email',
    login_password: 'login_password',
    login_button: 'login_button',
    login_forget_psw: 'login_forget_psw',
    login_welcome: 'login_welcome',
    login_mssg: 'login_mssg',
    login_contact_btn: 'login_contact_btn',

    //Apply to property
    appy_to_property: 'apply_to_property',
    tenant_data: 'tenant_data',
    search_file: 'search_file',
    or_drag_the_file: 'or_drag_the_file',
    guarantor_data: 'guarantor_data',
    additional_data: 'additional_data',
    comments: 'comments',
    write_here: 'write_here',
    contact_a: 'contact_a',

    //Documents for apply to property
    copy_of_identity_document: 'copy_of_identity_document',
    working_letter: 'working_letter',
    removable_payment: 'removable_payment',
    lease_application: 'lease_application',
    link: 'link',

    //Drive,
    drive_my_files: "drive_my_files",
    drive_shared_with_me: "drive_shared_with_me",
    drive_estates: "drive_estates",
    drive_paper_bin: "drive_paper_bin",
    drive_main_folders: "drive_main_folders",
    drive_purchase_and_sale_contracts: "drive_purchase_and_sale_contracts",
    drive_rental_contracts: "drive_rental_contracts",
    drive_search: "drive_search",
    drive_folders: "drive_folders",
    drive_files: "drive_files",
    drive_created: "drive_created",
    drive_created_by: "drive_created_by",
    drive_property: "drive_property",
    drive_powers: "drive_powers",
    drive_services: "drive_services",
    drive_identity_documents: "drive_identity_documents",
    drive_removable_payment: "removable_payment",
    drive_employment_letters: "drive_employment_letters",
    drive_rental_application_forms: "drive_rental_application_forms",
    drive_search_on_cloud: "drive_search_on_cloud",

    //modals
    save_changes: "save_changes",
    close: "close",
    select_languaje: "select_languaje",
    loading_modal: "loading_modal",
    error_modal: "error_modal",
    wrong_wredentials: "wrong_wredentials",
    unkown_error: "unkown_error",
    sucessful_modal: "sucessful_modal",
    sucessufl_text_modal: "sucessufl_text_modal",
    subtitle_loading_modal: "subtitle_loading_modal",
    terms_title: "terms_title",
    policies_title: "policies_title",

    ErrorSavingPreferences: "ErrorSavingPreferences",
    UnknownError: "UnknownError",
}

export default KeyWordLocalization;