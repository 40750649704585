import { Either } from "fp-ts/lib/Either";
import SubscribeRepository from "../../repositories/SubscribeRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { injectable } from "inversify";

interface props {
    subscribeRepository: SubscribeRepository;
}

@injectable()
class SendUnsubscribeCodeUseCase {
    _subscribeRepository: SubscribeRepository;

    constructor(_: props) {
        this._subscribeRepository = _.subscribeRepository;
    }

    public call = (email: string): Promise<Either<ExceptionEntity, void>> => this._subscribeRepository.sendUnsubscribeCode(email);
}

export default SendUnsubscribeCodeUseCase;

export const SendUnsubscribeCodeUseCaseName = "SendUnsubscribeCodeUseCase";