import "./HeaderPropertyComponentStyles.scss";
import { FC, useContext, useState } from "react";
import HeaderPropertyComponentProps from "./HeaderPropertyComponentProps";
import FriendlyUrls from "../../../../../seo/FriendlyUrls";
import CurrencyParse from "../../../../../utils/CurrencyParse";
import KeyWordLocalization from "../../../../../providers/localization/KeyWordLocalization";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import di from "../../../../../../dependencyInjection/DependencyInjection";
import LikePropertyByIdUseCase, { LikePropertyByIdUseCaseName } from "../../../../../../domain/use_cases/properties/LikePropertyByIdUseCase";
import UnlikePropertyByIdUseCase, { UnlikePropertyByIdUseCaseName } from "../../../../../../domain/use_cases/properties/UnlikePropertyByIdUseCase";
import ModalsContext from "../../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import ShareLinkModalComponent from "../../../../../components/modals/shareLink/ShareLinkModalComponent";
import IconsSVG from "../../../../../components/icons/IconsSVG";
import CapitalizeFirstLetter from "../../../../tools/capitalizeFirstLetter/capitalizeFirstLetter";
import PropertyEntity from "../../../../../../domain/entities/PropertyEntity";

const HeaderPropertyComponent: FC<HeaderPropertyComponentProps> = ({ property }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [liked, setLiked] = useState(property.liked);
    const _handleLike = () => liked ? _unlikeProperty() : _likeProperty();
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;


    const _likeProperty = () => {
        di.get<LikePropertyByIdUseCase>(LikePropertyByIdUseCaseName).call(property);
        setLiked(true);
    }

    const _unlikeProperty = () => {
        di.get<UnlikePropertyByIdUseCase>(UnlikePropertyByIdUseCaseName).call(property.id);
        setLiked(false);
    }


    const _handleShare = () => {
        openModalCustom(undefined, 'Share', <ShareLinkModalComponent link={window.location.href} />);
    }

    const getPropertyTitle = (property: PropertyEntity) => {

        let business: String = "";
        const businessNames: String[] = property.businesses!.map(business => CapitalizeFirstLetter(i18n(business.typeOfBusiness.name)));
        if (businessNames?.length === 1) {
            business = businessNames[0];
        } else {
            const lastIndex: number = businessNames.length - 1;
            business = businessNames?.slice(0, lastIndex).join(", ") + " | " + businessNames?.[lastIndex];
        }
        return `${CapitalizeFirstLetter(i18n(property.typeProperty!.name))} en ${business}, ${CapitalizeFirstLetter(property.location!.neighborhood.zone!.name)}, ${property.location?.neighborhood.name} en ${CapitalizeFirstLetter(property.location!.neighborhood.zone!.city!.name)}`;
    }
    return <div className="header_property_component my-4">
        <div className="row">
            <div className="col-12 d-flex justif-content-between">
                <div className="flex-grow-1">
                    <h1 className="header_property_component__title">
                        {getPropertyTitle(property)}
                    </h1>

                </div>
            </div>
        </div>
        <div className="d-flex justify-content-between">
            {property.tag && <div>
                <div className="tag_container" style={{ backgroundColor: property.tag.color }}>
                    <span className="tag">{i18n(property.tag.name)}</span>
                </div>
            </div>}
            <div className="line_icons ms-2 justify-content-end">
                <div className="line_icon" style={{ boxShadow: 'none' }}>
                    <span className="material-symbols-outlined">
                        visibility
                    </span>
                    <span className="hidden_text">{property.seenCount} Visitas</span>
                </div>
            </div>
        </div>
    </div >
}

export default HeaderPropertyComponent;