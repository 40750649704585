import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import CookiesRepository from "../../repositories/CookiesRepository";
import CookiesEntity from "../../entities/CookiesEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";

interface Props {
    cookiesRepository: CookiesRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class GetCookiesUseCase {
    _cookiesRepository: CookiesRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: Props) {
        this._cookiesRepository = _.cookiesRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (): Promise<CookiesEntity> => {
        const response = await this._cookiesRepository.getCookies();
        this._preferencesProvider.Actions.setCookies(response);
        return response;
    }
}

export default GetCookiesUseCase;

export const GetCookiesUseCaseName = "GetCookiesUseCase";