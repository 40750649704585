import { injectable } from "inversify";
import CookiesRepository from "../../../domain/repositories/CookiesRepository";
import CookiesEntity from "../../../domain/entities/CookiesEntity";
import GetCookiesApiImpl from "./api/impl/GetCookiesApiImpl";
import UpdateCookiesApiImpl from "./api/impl/UpdateCookiesApiImpl";
import ShouldShowCookiesBannerApiImpl from "./api/impl/ShouldShowCookiesBannerApiImpl";

@injectable()
class CookiesRepositoryImpl implements CookiesRepository{
    COOKIES_KEY: string = 'cookies_preferences';

    getCookies = (): Promise<CookiesEntity> => GetCookiesApiImpl(this.COOKIES_KEY);
    updateCookies = (cookies: CookiesEntity): Promise<void> => UpdateCookiesApiImpl(this.COOKIES_KEY, cookies);
    shouldShowCookiesBanner = (): Promise<boolean> => ShouldShowCookiesBannerApiImpl(this.COOKIES_KEY);
}

export default CookiesRepositoryImpl;