export default interface ExceptionEntity {
    code?: string,
}

export const ExceptionEntityFromError = (error: any): ExceptionEntity => {
    try {
        return {
            code: 'ErrorHost' + error.code,
        }
    } catch (error) {
        return {}
    }
}
