import { FC } from "react";
import ZoomedCarouselComponentProps from "./ZoomedCarouselComponentProps";
import { Carousel } from 'react-responsive-carousel';

const ZoomedCarouselComponent: FC<ZoomedCarouselComponentProps> = ({ multimediaItems, currentImage, onChange, shortDescription, onClose }) => {
    const onNext = () => {
        if (currentImage < multimediaItems.length - 1) onChange(currentImage + 1);
    }
    const onPrevious = () => {
        if (currentImage > 0) onChange(currentImage - 1);
    }
    return <div className="zoomed_carousel">
        <div className="bg_closer" onClick={onClose}>
            <span className="material-symbols-outlined mt-3 me-3" >
                close
            </span>
        </div>
        <div className="container_carousel_zoom">
            <Carousel swipeable showArrows emulateTouch selectedItem={currentImage} onChange={onChange} dynamicHeight showThumbs={false}>
                {multimediaItems.map((item, index) =>
                    <div className="carousel_image_container_zoom" key={index}>
                        <img
                            key={index}
                            src={item.url}
                            alt={shortDescription}
                            className={index === currentImage ? 'selected' : ''}
                        />
                    </div>
                )}
            </Carousel>
        </div>
    </div>
}

export default ZoomedCarouselComponent;