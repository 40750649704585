import "./CarouselPropertyComponentStyles.scss";
import { FC, useContext, useState } from "react";
import CarouselPropertyComponentProps from "./CarouselPropertyComponentProps";
import ZoomedCarouselComponent from "./zoomedCarousel/ZoomedCarouselComponent";
import { Carousel } from 'react-responsive-carousel';
import IconsSVG from "../../../../../components/icons/IconsSVG";
import di from "../../../../../../dependencyInjection/DependencyInjection";
import LikePropertyByIdUseCase, { LikePropertyByIdUseCaseName } from "../../../../../../domain/use_cases/properties/LikePropertyByIdUseCase";
import UnlikePropertyByIdUseCase, { UnlikePropertyByIdUseCaseName } from "../../../../../../domain/use_cases/properties/UnlikePropertyByIdUseCase";
import ModalsContext from "../../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import ShareLinkModalComponent from "../../../../../components/modals/shareLink/ShareLinkModalComponent";

const CarouselPropertyComponent: FC<CarouselPropertyComponentProps> = ({ property, multimediaItems, shortDescription }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [showZoomedCarousel, setShowZoomedCarousel] = useState(false);
    const [liked, setLiked] = useState(property.liked);
    const _handleLike = () => liked ? _unlikeProperty() : _likeProperty();
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;


    const _likeProperty = () => {
        di.get<LikePropertyByIdUseCase>(LikePropertyByIdUseCaseName).call(property);
        setLiked(true);
    }

    const _unlikeProperty = () => {
        di.get<UnlikePropertyByIdUseCase>(UnlikePropertyByIdUseCaseName).call(property.id);
        setLiked(false);
    }

    const _handleClose = () => {
        setShowZoomedCarousel(false);
    }

    const _handleShare = () => {
        openModalCustom(undefined, 'Share', <ShareLinkModalComponent link={window.location.href} />);
    }

    return <div className="carousel_property_component">
        <div className="carousel_container">
            <div className="line_icons">
                <div className="line_icon" onClick={_handleLike}>
                    {liked && <IconsSVG.FavoriteFilled color="red" />}
                    {!liked && <span className="material-symbols-outlined">
                        favorite
                    </span>}
                    <span className="hidden_text">{property.likedCount} Me gusta</span>
                </div>
                <div className="line_icon" onClick={_handleShare}>
                    <span className="material-symbols-outlined">
                        share
                    </span>
                    <span className="hidden_text">Compartir</span>
                </div>
            </div>

            <Carousel swipeable showArrows emulateTouch selectedItem={currentImage} onChange={setCurrentImage} onClickItem={() => setShowZoomedCarousel(true)}>
                {multimediaItems.map((item, index) =>
                    <div className="carousel_image_container" key={index}
                    >
                        <img
                            key={index}
                            src={item.url}
                            alt={shortDescription}
                            title={shortDescription}
                            className={index === currentImage ? 'selected' : ''}
                        />
                    </div>
                )}
            </Carousel>
        </div>
        {showZoomedCarousel && <ZoomedCarouselComponent multimediaItems={multimediaItems} currentImage={currentImage} onChange={setCurrentImage} shortDescription={shortDescription} onClose={_handleClose} />}
    </div>
}

export default CarouselPropertyComponent;