import "./NoResultsPropertiesComponentStyles.scss";
import { FC } from "react";
import NoResultsPropertiesCompontentProps from "./NoResultsPropertiesCompontentProps";

const NoResultsPropertiesCompontent: FC<NoResultsPropertiesCompontentProps> = () => {
    return (
        <div className="no_results_properties_component">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src="/assets/images/no_results.png" alt="No results found" className="img-fluid" />
                    </div>
                    <div className="col-12">
                        <div className="no_results_title">No results found</div>
                        <div className="no_results_subtitle">We couldn't find any properties that match your search criteria.</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NoResultsPropertiesCompontent;