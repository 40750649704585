import { injectable } from "inversify";
import PropertyRepository from "../../../domain/repositories/PropertyRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import PaginateResultEntity from "../../../domain/entities/PaginateResultEntity";
import PropertyEntity from "../../../domain/entities/PropertyEntity";
import OldSearchPropertiesApiImpl from "./api/impl/OldSearchPropertiesApiImpl";
import SearchPropertiesApiImpl from "./api/impl/SearchPropertiesApiImpl";
import LocationEntity from "../../../domain/entities/LocationEntity";
import SearchPropertiesWithMapApiImpl from "./api/impl/SearchPropertiesWithMapApiImpl";
import GetPropertyByIdApiImpl from "./api/impl/GetPropertyByIdApiImpl";
import LikePropertyApiImpl from "./api/impl/LikePropertyApiImpl";
import UnlikePropertyApiImpl from "./api/impl/UnlikePropertyApiImpl";
import GetLikedPropertiesApiImpl from "./api/impl/GetLikedPropertiesApiImpl";
import GetOustandedAndRecentPropertiesApiImpl from "./api/impl/GetOustandedAndRecentPropertiesApiImpl";
import ApplyToRentPropertyApiImpl from "./api/impl/ApplyToRentPropertyApiImpl";
import MarkAsSeenPropertyApiImpl from "./api/impl/MarkAsSeenPropertyApiImpl";
import IsPropertySeenApiImpl from "./api/impl/IsPropertySeenApiImpl";
import GetPropertyAndCheckIfISeenApiImpl from "./api/impl/GetPropertyAndCheckIfISeenApiImpl";

@injectable()
class PropertyRepositoryImpl implements PropertyRepository {
    KEY_PREFERENCES_LIKED_PROPERTIES = "likedProperties";
    KEY_PREFERENCES_SAW_PROPERTIES = "seen_properties";
    KEY_PREFERENCES_LIKED_ANY_TIME = "likedPropertiesAnyTime";
    getAllPropertiesLocations = (paramsSearch: { word: string; page: number; itemsPerPage: number; typeOfPropertiesId: string[]; typeOfBusinessId: string[]; isNew?: boolean | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }, mapLimits: { latMax: number; latMin: number; longMax: number; longMin: number; }): Promise<LocationEntity[]> => SearchPropertiesWithMapApiImpl(paramsSearch, mapLimits);
    oldSearchProperties = (params: { word: string; page: number; itemsPerPage: number; typeOfProperty?: string | undefined; typeOfBusiness?: string | undefined; isNew?: boolean | undefined; cityId?: string | undefined; zoneId?: string | undefined; neighborhoodId?: string | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }): Promise<PaginateResultEntity<PropertyEntity>> => OldSearchPropertiesApiImpl(params);
    searchProperties = (params: {
        word: string, page: number, itemsPerPage: number,
        tagsId: string[],
        cityId?: string | undefined, zoneIds: string[], neighborhoodIds: string[],
        priceRange?: { min: number, max: number },
        rooms?: string[], restrooms?: string[],
        metersRange?: { min: number, max: number },
        garages?: string[], stratum?: string[],
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        sortBy: string
    }): Promise<PaginateResultEntity<PropertyEntity>> => SearchPropertiesApiImpl(params);
    getPropertyById = async (id: string): Promise<PropertyEntity | undefined> => GetPropertyAndCheckIfISeenApiImpl(id, this.KEY_PREFERENCES_SAW_PROPERTIES);
    likeProperty = (property: PropertyEntity): Promise<Either<ExceptionEntity, void>> => LikePropertyApiImpl(property, this.KEY_PREFERENCES_LIKED_PROPERTIES, this.KEY_PREFERENCES_LIKED_ANY_TIME);
    unLikeProperty = (id: string): Promise<Either<ExceptionEntity, void>> => UnlikePropertyApiImpl(id, this.KEY_PREFERENCES_LIKED_PROPERTIES);
    getLikedProperties = (): Promise<PropertyEntity[]> => GetLikedPropertiesApiImpl(this.KEY_PREFERENCES_LIKED_PROPERTIES);
    getOutstandedAndRecentProperties = (): Promise<Either<ExceptionEntity, { outstanded: PropertyEntity[]; recent: PropertyEntity[]; mostLiked: PropertyEntity[] }>> => GetOustandedAndRecentPropertiesApiImpl();
    applyToRentProperty = (params: {
        message: string; propertyId: string; rentSolicitudeFile: File;
        tenant: { name: string; lastname: string; email: string; identification: string; dial: string; phone: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; };
        coDebtor: { name: string; lastname: string; email: string; identification: string; dial: string; phone: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; };
    }): Promise<Either<ExceptionEntity, void>> => ApplyToRentPropertyApiImpl(params);

}

export default PropertyRepositoryImpl;