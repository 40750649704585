import { FC, useContext } from 'react';
import './ErrorPage.scss';
import MastersContext from '../../../domain/providers/master/MastersContext';
import MastersContextType from '../../../domain/providers/master/MastersContextType';
//TODO convert to TS
const Error404Page: FC<{}> = () => {
    const { typesOfBusinesses, typeOfProperties } = useContext(MastersContext) as MastersContextType;
    return <div className='container o-error-container'>
        error 404
    </div>
};

export default Error404Page;