import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import di from "../../../../../dependencyInjection/DependencyInjection";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import IsPropertySeenApiImpl from "./IsPropertySeenApiImpl";

const _removeLocal = (key: string, idProperty: string) => {
    // get values saved from local storage, filter out the value to be removed, and save the array again
    const currentLikedProperties = localStorage.getItem(key);
    if (currentLikedProperties) {
        const parsed = JSON.parse(currentLikedProperties);
        const updated = parsed.filter((property: PropertyEntity) => property.id !== idProperty);
        localStorage.setItem(key, JSON.stringify(updated));
    }
}

// TODO: create back and connect
const _sendToServer = async (idProperty: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        const relativeUrl = "/unlikeProperty";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, idProperty);
        return response;
    } catch (error) {
        return left(ExceptionEntityFromError(error));
    }
}

const UnlikePropertyApiImpl = async (idProperty: string, keySaveLocal: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        const isLiked = await IsPropertySeenApiImpl(idProperty, keySaveLocal);
        if (isLiked) return right(undefined);
        _removeLocal(keySaveLocal, idProperty);
        return right(undefined);
        // return await _sendToServer(idProperty);
    } catch (e) {
        return left(ExceptionEntityFromError(e));
    }
}

export default UnlikePropertyApiImpl;
