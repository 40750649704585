import LocalizationEntity from "../../../../../domain/entities/LocalizationEntity";
import LocalizationList from "./dictionaries/LocalizationList";

const GetCurrentLanguageApiImpl = (keyStorage: string): LocalizationEntity => {
    //TODO disabled english is out for now
    document.documentElement.lang = 'es';
    return LocalizationList.find(l => l.code === 'es') as LocalizationEntity;
    const languageCode = localStorage.getItem(keyStorage);
    if (!languageCode){
        const browserLanguage = navigator.language.split('-')[0];
        if (LocalizationList.find(l => l.code === browserLanguage)){
            return LocalizationList.find(l => l.code === browserLanguage) as LocalizationEntity;
        }
        return LocalizationList.find(l => l.code === 'es') as LocalizationEntity;
    }
    return LocalizationList.find(l => l.code === languageCode) as LocalizationEntity;
}

export default GetCurrentLanguageApiImpl;