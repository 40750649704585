import { injectable } from "inversify";
import PropertyRepository from "../../repositories/PropertyRepository";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";

interface props {
    propertyRepository: PropertyRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class SearchPropertiesUseCase {
    _propertyRepository: PropertyRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
        this._preferencesProvider = _.preferencesProvider
    }

    public call = async (params: {
        word: string, page: number, itemsPerPage: number,
        tagsId: string[],
        cityId?: string | undefined, zoneIds: string[], neighborhoodIds: string[],
        priceRange?: { min: number, max: number },
        rooms?: string[], restrooms?: string[],
        metersRange?: { min: number, max: number },
        garages?: string[], stratum?: string[],
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        sortBy: string
    }) => {
        const response = await this._propertyRepository.searchProperties(params);
        //get liked properties
        response.data.forEach((property) => {
            property.liked = this._preferencesProvider.Actions.thisPropertyLikesMe(property);
        });
        return response;
    }
}

export default SearchPropertiesUseCase;

export const SearchPropertiesUseCaseName = "SearchPropertiesUseCase";