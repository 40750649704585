import { injectable } from "inversify";
import di from "../../../dependencyInjection/DependencyInjection";
import NeighborhoodEntity from "../../entities/NeighborhoodEntity";
import GetAllCitiesUseCase, { GetAllCitiesUseCaseName } from "./GetAllCitiesUseCase";
import ZoneEntity from "../../entities/ZoneEntity";
import CityEntity from "../../entities/CityEntity";

@injectable()
class SearchNeighborhoodUseCase {

    public call = async (word: string, city: CityEntity | undefined, zones: ZoneEntity[]): Promise<NeighborhoodEntity[]> => {
        let neighboorhoods: NeighborhoodEntity[];
        if(zones != undefined) {
            neighboorhoods = zones.flatMap(zone => zone.neighborhoods);
        }else if(city != undefined) {
            neighboorhoods = city.zones.flatMap(zone => zone.neighborhoods);
        }else {
            const cities = await di.get<GetAllCitiesUseCase>(GetAllCitiesUseCaseName).call();
            neighboorhoods = cities.flatMap(city => city.zones.flatMap(zone => zone.neighborhoods));
        }
        return neighboorhoods.filter(neighborhood => neighborhood.name.toLowerCase().includes(word.toLowerCase()));

    }
}

export default SearchNeighborhoodUseCase;

export const SearchNeighborhoodUseCaseName = "SearchNeighborhoodUseCase";