import ContactInfoEntity from "../../../../../domain/entities/ContactInfoEntity";

//TODO test api
const GetContactPreferencesApiImpl = async (preferencesKey: string): Promise<ContactInfoEntity | undefined> => {
    try {
        const preferences = localStorage.getItem(preferencesKey);
        if (!preferences) return undefined;
        return JSON.parse(preferences);
    } catch (error) {
        return undefined;
    }
}

export default GetContactPreferencesApiImpl;
