import { Either } from "fp-ts/lib/Either";
import SubscribeRepository from "../../repositories/SubscribeRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { injectable } from "inversify";

interface props {
    subscribeRepository: SubscribeRepository;
}

@injectable()
class ConfirmUnsubscribeUseCase {
    _subscribeRepository: SubscribeRepository;

    constructor(_: props) {
        this._subscribeRepository = _.subscribeRepository;
    }

    public call = (email: string, code: string, reason: string): Promise<Either<ExceptionEntity, void>> => this._subscribeRepository.confirmUnsubscribe(email, code, reason);
}

export default ConfirmUnsubscribeUseCase;

export const ConfirmUnsubscribeUseCaseName = "ConfirmUnsubscribeUseCase";