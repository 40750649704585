import { useState } from 'react';
import ProviderProps from '../../../domain/providers/ProviderProps';
import MastersContextType from '../../../domain/providers/master/MastersContextType';
import MastersContext, { defaultMastersContext } from '../../../domain/providers/master/MastersContext';
import CityEntity from '../../../domain/entities/CityEntity';
import NeighborhoodEntity from '../../../domain/entities/NeighborhoodEntity';
import ZoneEntity from '../../../domain/entities/ZoneEntity';
import TypeOfBusinessEntity from '../../../domain/entities/TypeOfBusinessEntity';
import TypeOfPropertyEntity from '../../../domain/entities/TypeOfPropertyEntity';
import { injectable } from 'inversify';
import MastersProvider from '../../../domain/providers/master/MastersProvider';
import TagEntity from '../../../domain/entities/TagEntity';

const _Actions: MastersContextType = defaultMastersContext;

const _Provider = ({ children }: ProviderProps) => {
  const [cities, setCities] = useState<CityEntity[]>(defaultMastersContext.cities);
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodEntity[]>(defaultMastersContext.neighborhoods);
  const [zones, setZones] = useState<ZoneEntity[]>(defaultMastersContext.zones);
  const [tags, setTags] = useState<TagEntity[]>(defaultMastersContext.tags);
  const [typesOfBusinesses, setTypesOfBusinesses] = useState<TypeOfBusinessEntity[]>(defaultMastersContext.typesOfBusinesses);
  const [typeOfProperties, setTypesOfProperties] = useState<TypeOfPropertyEntity[]>(defaultMastersContext.typeOfProperties);

  _Actions.cities = cities;
  _Actions.setCities = setCities;
  _Actions.neighborhoods = neighborhoods;
  _Actions.setNeighborhoods = setNeighborhoods;
  _Actions.zones = zones;
  _Actions.setZones = setZones;
  _Actions.typesOfBusinesses = typesOfBusinesses;
  _Actions.setTypesOfBusinesses = setTypesOfBusinesses;
  _Actions.typeOfProperties = typeOfProperties;
  _Actions.setTypesOfProperties = setTypesOfProperties;
  _Actions.tags = tags;
  _Actions.setTags = setTags;

  return (
    <MastersContext.Provider value={{
      cities,
      setCities,
      neighborhoods,
      setNeighborhoods,
      zones,
      setZones,
      typesOfBusinesses,
      setTypesOfBusinesses,
      typeOfProperties,
      setTypesOfProperties,
      tags,
      setTags,
    }}>
      {children}
    </MastersContext.Provider>
  );
};

@injectable()
class MastersProviderImpl implements MastersProvider {
  public context = MastersContext;

  public Provider = _Provider;

  Actions = _Actions
}

export default new MastersProviderImpl();

