import { title } from "process";
import { FC, useContext } from "react";
import { Helmet } from "react-helmet-async";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";

const FavoritesPageMetaTagComponent: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    return <>
        <Helmet>
            <title>{i18n(KeyWordLocalization.FavoritesPageMetaTagComponentTitle)}</title>
            <link rel="image_src" href="/assets/promotional/link_preview.jpg" type="image/png" />
            <meta name="description" content={i18n(KeyWordLocalization.FavoritesPageMetaTagComponentDescription)} />
            <meta name="keywords" content={i18n(KeyWordLocalization.FavoritesPageMetaTagComponentKeyWords)} />
            <link rel="canonical" href={window.location.pathname} />
            <meta name="robots" content="index, no-follow" />
        </Helmet>
    </>
}

export default FavoritesPageMetaTagComponent;