import { injectable } from "inversify";
import CookiesRepository from "../../repositories/CookiesRepository";

interface Props {
    cookiesRepository: CookiesRepository;
}

@injectable()
class ShouldShowCookiesUseCase {
    _cookiesRepository: CookiesRepository;

    constructor(_: Props) {
        this._cookiesRepository = _.cookiesRepository;
    }

    public call = async (): Promise<boolean> => {
        const response = await this._cookiesRepository.shouldShowCookiesBanner();
        return response;
    }
}

export default ShouldShowCookiesUseCase;

export const ShouldShowCookiesUseCaseName = "ShouldShowCookiesUseCase";