import { useParams } from 'react-router-dom';
import { FC, useContext, useState, useEffect } from "react";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import "./NeedLoanComponentStyles.scss";
import KeyWordLocalization from "../../../../../providers/localization/KeyWordLocalization";
import NeedLoanComponentProps from './NeedLoanComponentProps';
import CurrencyParse from '../../../../../utils/CurrencyParse';

const NeedLoanComponent: FC<NeedLoanComponentProps> = ({ property }) => {
    const [isLeasing, setIsLeasing] = useState<boolean>(false);
    const [quotaValue, setQuotaValue] = useState<string>("0");
    const [totalFinance, setTotalFinance] = useState<string>("0");
    const [financePercent, setFinancePercent] = useState<string>("85%");
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const TMV = 0.0113;
    const n = 240;

    const _calculateLoan = () => {
        const limitsLoan = {
            leasingVis: 0.8,
            mortgageVis: 0.8,
            leasingNoVis: 0.85,
            mortgageNoVis: 0.7
        }
        let limitToUse = 0.7;
        if (isLeasing && property.vis) limitToUse = limitsLoan.leasingVis;
        else if (isLeasing && !property.vis) limitToUse = limitsLoan.leasingNoVis;
        else if (!isLeasing && property.vis) limitToUse = limitsLoan.mortgageVis;
        else if (!isLeasing && !property.vis) limitToUse = limitsLoan.mortgageNoVis;

        //calculate
        const finance = (property.businesses?.find(business => business.typeOfBusiness.name === "sell")?.price || 0) * limitToUse;
        const quota = ((TMV * finance) / (1 - (1 + TMV) ** -n));
        setFinancePercent((limitToUse * 100) + "%");
        const financeCop = CurrencyParse.toCop(finance);
        const quotaCop = CurrencyParse.toCop(quota);
        setTotalFinance(financeCop);
        setQuotaValue(quotaCop);
    }

    useEffect(() => {
        _calculateLoan();
    }, [isLeasing])

    const sendWhatsappFinance = () => {
        const phoneNumber = '573113065939';
        const message = `${i18n(KeyWordLocalization.NeedLoanComponentWhatsApp)} ${window.location.href}`;
        const whatsappUrl = encodeURI(`https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}`);

        window.open(whatsappUrl, '_blank');
    }

    if((property.businesses?.find(business => business.typeOfBusiness.name === "sell")?.price || 0) == 0) return <></>

    return (
        <div className="need_loan_component">
            <h2 className="title_detailed">{i18n(KeyWordLocalization.NeedLoanComponentTitle)}</h2>
            <div className="d-flex align-items-center text_gap mb-4">{i18n(KeyWordLocalization.NeedLoanComponentWithoutAsociatedText)}</div>
            <div className="row flex-column flex-md-row align-items-center justify-content-around mt-4 mb-4">
                <div className="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-md-center">
                    <div className={`type_credit_button ${!isLeasing ? "type_credit_button_selected" : "type_credit_button_unselected"}`} onClick={() => setIsLeasing(false)}>{i18n(KeyWordLocalization.NeedLoanComponentMortgage)}</div>
                    <div className={`type_credit_button ${isLeasing ? "type_credit_button_selected" : "type_credit_button_unselected"}`} onClick={() => setIsLeasing(true)}>{i18n(KeyWordLocalization.NeedLoanComponentLeasing)}</div>
                </div>
                <div className="col-12 col-md-3 d-flex flex-column align-items-center mt-4 mt-md-0">
                    <div className="quota_text">{i18n(KeyWordLocalization.NeedLoanComponentFinance)}</div>
                    <div className="value_text">{totalFinance}</div>
                </div>
                <div className="col-12 col-md-3 d-flex flex-column align-items-center mt-4 mt-md-0">
                    <div className="quota_text">{i18n(KeyWordLocalization.NeedLoancomponentMonthlyAproximately)}</div>
                    <div className="value_text">{quotaValue}</div>
                </div>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="mbtn btn_orange loan-btn pt-2 pb-2" onClick={sendWhatsappFinance}>
                        <span>{i18n(KeyWordLocalization.NeedLoanComponentWantFinance)}</span>
                    </div>
                </div>
            </div>
            <p className='loan-note mt-4 mb-4'>{i18n(KeyWordLocalization.NeedLoanComponentClarifications, { percent: financePercent })}</p>
        </div>
    )
}

export default NeedLoanComponent;