import './FooterComponentStyles.scss';
import { FC, useContext } from "react";
import FooterComponentProps from "./FooterComponentProps";
import FooterLinksComponentEs from './langs/FooterLinksComponent';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import { MdOutlineLocationOn, MdOutlineMail, MdWhatsapp } from 'react-icons/md';
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes/Routes';

const pagesWithbgInverted: string[] = [
    routes.contact.relativePath, routes.terms_and_conditions.relativePath, routes.privacy_policy.relativePath,
    routes.favorites.relativePath,];
const FooterComponent: FC<FooterComponentProps> = () => {
    const { localization, i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const bgInverted = pagesWithbgInverted.includes(window.location.pathname);

    return <footer className={`footer_component ${bgInverted && 'inverted_bg'}`}>
        {localization.code == "es" && <FooterLinksComponentEs />}
        <div className="bg_light pt-5 footer_info">
            <div className="container contact_info">
                <div className="row d-flex justify-content-md-between">
                    <div className="col-12 my-3 col-md-6 col-lg-3 block_info">
                        <h2 className='h5 text_color_main'>{i18n(KeyWordLocalization.FooterComponentContact)}</h2>
                        <div className='d-flex flex-column align-items-center align-items-md-start'>
                            <h3>
                                Luz Adriana Castañeda
                            </h3>
                            <h4><MdOutlineMail size={24} /> <a href="mailto:asesorias@lac.com.co">asesorias@lac.com.co</a></h4>
                            <h4>
                                <MdWhatsapp size={24} />
                                <div>
                                    <a href="https://wa.me/573162885158?text=Buenas tardes, me cómunico desde la página de LAC..."> (+57) 316 2885158</a>
                                    <br />
                                    <a href='https://wa.me/573122958733?text=Buenas tardes, me cómunico desde la página de LAC...'> (+57) 312 2958733</a>
                                </div>
                            </h4>
                            <h4 className='d-flex'>
                                <MdOutlineLocationOn size={36} className='d-none d-md-block' />
                                <div>
                                    <MdOutlineLocationOn size={24} className='d-md-none' />
                                    <a href="https://maps.app.goo.gl/wZduc7j8c7DDT5Q99">Carrera 1a4 # 66 - 20, Casa 39, Cali, Colombia </a>
                                </div>
                            </h4>
                        </div>
                    </div>
                    <div className="col-12 my-3 col-md-6 col-lg-3">
                        <div className='block_info' style={{ marginTop: '2.3em' }}>
                            <h3>
                                Marlon Méndez
                            </h3>
                            <h4 ><MdOutlineMail size={24} /> <a href="mailto:marlon.mendez@lac.com.co">marlon.mendez@lac.com.co</a></h4>
                            <h4>
                                <MdWhatsapp size={24} />
                                <div>
                                    <a href="https://wa.me/573247541281?text=Buenas tardes, me cómunico desde la página de LAC..."> (+57) 324 7541281</a>
                                </div>
                            </h4>
                        </div>

                        <div className='my-3 block_info'>
                            <h3 className='mb-3'>¿Deseás que te llamemos?</h3>
                            <Link to={routes.contact.relativePath} className='mbtn btn_orange py-2 text-white'>Contáctanos</Link>
                        </div>
                    </div>
                    <div className="col-12 my-3 col-md-6 col-lg-3 block_info">
                        <h3 className='h5 mt-0 text_color_main'>Nuestros horarios</h3>
                        <div>
                            <h4 className='mb-0 d-flex flex-column align-items-md-start'>
                                Lunes a viernes &nbsp;
                                <span className='normal_text'>8:00am - 6:00pm</span>
                            </h4>
                        </div>
                        <div>
                            <h4 className='mb-0 d-flex flex-column justify-content-center justify-content-md-start'>
                                Sábados &nbsp;
                                <span className='normal_text'>8:00am - 12:00pm</span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-12 my-3 col-md-6 col-lg-3 col-md-3">
                        <h2 className='h5 text_color_main'>Siguenos</h2>
                        <div className='w-100 block_info'>
                            <h3>
                                Redes sociales
                            </h3>
                            <div className="d-flex align-items-center">
                                <a href="https://web.facebook.com/LAC-Asesoria-inmobiliaria-109776714968726" className='social_tag'>
                                    <FaFacebookF size={28} />
                                </a>
                                <a href="https://wa.me/573162885158" className='social_tag'>
                                    <MdWhatsapp size={36} />
                                </a>
                                <a href="https://instagram.com/lac.asesorainmbiliaria?utm_medium=copy_link" className='social_tag'>
                                    <FaInstagram size={36} />
                                </a>
                            </div>

                        </div>
                        {/* <div className="w-100 mt-1">
                            <h3 className='normal_text'>También recibe nuestras novedades en nuestro Newsletter</h3>
                            <button className='mbtn btn_orange py-0'><h4 className='normal_text h6'>Suscribirse</h4></button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container last_line">
                <div className="row">
                    <div className='my-1 col-12 col-md-6 footer_tyc'>©2024 lac.com.co, todos los derechos reservados</div>
                    <div className="my-1 col-6 col-md-3">
                        <Link to={routes.terms_and_conditions.relativePath} className='footer_tyc'>Términos y condiciones </Link>
                    </div>
                    <div className="my-1 col-6 col-md-3">
                        <Link to={routes.privacy_policy.relativePath} className='footer_tyc'>Politicas de privacidad </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default FooterComponent;