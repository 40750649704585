import PersonEntity from "../../../domain/entities/PersonEntity"

const fromJSON = (json: any): PersonEntity => {
    return {
        id: json['fake_id'],
        fullname: json['fullname'],
        email: json['email'],
        phone: json['phone'],
        cellphone: json['cellphone'],
    }
}

export default {
    fromJSON,
}