import di from "../../../../../dependencyInjection/DependencyInjection";
import CityEntity from "../../../../../domain/entities/CityEntity";
import CityDto from "../../../../dto/impl/CityDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const GetAllCitiesApiImpl = async (): Promise<CityEntity[]> => {
    try {
        const relativeUrl = "/api/cities/getAll/withNeighboorhoods";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        return response.map((item: any) => CityDto.fromJSON(item));
    } catch (e) {
        return [];
    }
};

export default GetAllCitiesApiImpl;