import { Either, left, right } from "fp-ts/lib/Either";
import ContactInfoEntity from "../../../../../domain/entities/ContactInfoEntity";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import di from "../../../../../dependencyInjection/DependencyInjection";

//TODO test api
const SendContactApiImpl = async (contactInfo: ContactInfoEntity, subscribe: boolean): Promise<Either<ExceptionEntity, void>> => {
    try {
        const relativeUrl = '/contactMe';
        const body = {
            "name": contactInfo.name,
            "lastname": contactInfo.lastname,
            "email": contactInfo.email,
            "phone_dial": contactInfo.dial,
            "phone": contactInfo.phone,
            "user_content": contactInfo.userContent,
            "subscribe": subscribe,
            "isNotProperty": true,
        }
        await di.get<HostApi>(HostApiName).post(relativeUrl, body);
        console.log('send contact api impl')
        return right(undefined);
    } catch (error) {
        console.error('exception on SendContactApiImpl', error);
        return left(ExceptionEntityFromError(error));
    }
}

export default SendContactApiImpl;
