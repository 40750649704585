import { injectable } from "inversify";
import RestApi from "./RestApi";
import Constants, { ConstantsName } from "./constants/Constants";
import di from "../../dependencyInjection/DependencyInjection";

@injectable()
class HostApi extends RestApi {
    public url = di.get<Constants>(ConstantsName).HOST_URL;
}

export default HostApi;

export const HostApiName = "HostApi";