/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: June 4, 2024
 *
 * Description:
 * This file is the interface for the Constants object, which is used to store the
 * constants of the application, and used for dependency injection to switch between envs
 * 
 * Note:
 *  The HOST_URL is the base url for the api, it can changes if the server changes, for example
 *  when I use the pc as server and the mobile as client, the url will depend on the IP of the pc
 ********************************************************************************/

export default interface Constants {
    HOST_URL: string;
    HOST_IMAGE_URL: string;
    DEFAULT_IMAGE_URL: string;
    GOOGLE_MAPS_API_KEY: string;
}

export const ConstantsName = "Constants";