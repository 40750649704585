import ZoneEntity from "../../../domain/entities/ZoneEntity"
import CityDto from "./CityDto"
import NeighborhoodDto from "./NeighborhoodDto"

const fromJSON = (data: any): ZoneEntity => {
    return {
        id: data.id,
        name: data.name,
        neighborhoods: data.neighborhoods ? data.neighborhoods.map((item: any) => NeighborhoodDto.fromJSON(item)) : [],
        city: data.city ? CityDto.fromJSON(data.city) : undefined,
    }
}

export default {
    fromJSON,
}