import type { Marker } from '@googlemaps/markerclusterer';
import React, { useCallback, useContext } from 'react';
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import PropertyEntity from '../../../../../../../domain/entities/PropertyEntity';
import { MdBuild, MdBuildCircle, MdHome, MdHomeWork, MdHouse, MdLocationPin, MdPin } from 'react-icons/md';
import LocalizationContext from '../../../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../../../domain/providers/localization/LocalizationContextType';
import CurrencyParse from '../../../../../../utils/CurrencyParse';

export type PropertyMarkerProps = {
  property: PropertyEntity;
  onClick: (property: PropertyEntity) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  outstanding?: boolean;
};

/**
 * Wrapper Component for an AdvancedMarker for a single property.
 */
export const PropertyMarker = (props: PropertyMarkerProps) => {
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  const { property, onClick, setMarkerRef, outstanding = false } = props;

  const handleClick = useCallback(() => onClick(property), [onClick, property]);
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, property.id),
    [setMarkerRef, property.id]
  );

  if (!property.location?.mapsLat || !property.location?.mapsLong) return null;
  if (!property.businesses || property.businesses.length == 0) return null;

  return (
    <AdvancedMarker
      style={outstanding ? { zIndex: 1000070 } : {}}
      position={{
        lat: property.location!.mapsLat!,
        lng: property.location!.mapsLong!
      }} ref={ref} onClick={handleClick}>
      <div className="d-flex flex-column align-items-center" style={{ zIndex: outstanding ? 2 : 1, }}>
        {outstanding ? <div className="bordered_marked" style={{ fontSize: '1.6em', backgroundColor: property.businesses.length == 2 ? "var(--color-green)" : property.businesses[0].typeOfBusiness.color, color: 'white', fontWeight: 'bold', borderRadius: 5, padding: '0.5em 0.5em', boxShadow: "0 2px 2px 0px #cecece ", }}>
          Ubicación inmueble
        </div> :
          <div className="bordered_marked" style={{ fontSize: '1.3em', backgroundColor: 'white', borderRadius: 5, padding: '0.5em 0.5em', boxShadow: "0 2px 2px 0px #cecece " }}>
            {property.businesses?.map((business, index) => <div key={index} className='text-capitalize' style={{ color: business.typeOfBusiness.color }} > {i18n(business.typeOfBusiness.name)}: {CurrencyParse.toCop(business.price)}</div>)}
          </div>
        }
        {outstanding ?
          <div style={{ background: property.businesses.length == 2 ? "var(--color-green)" : property.businesses[0].typeOfBusiness.color, borderRadius: '100%', padding: "0.5em", boxShadow: "0 2px 2px 0px #cecece " }} className='d-flex justify-content-center align-items-center'>
            <MdHouse size={20} color="white" />
          </div> :
          <div style={{ background: "white", borderRadius: '100%', padding: "0.5em", boxShadow: "0 2px 2px 0px #cecece " }} className='d-flex justify-content-center align-items-center'>
            <MdHouse size={20} color={property.businesses.length == 2 ? "var(--color-green)" : property.businesses[0].typeOfBusiness.color} />
          </div>}
      </div>
    </AdvancedMarker>
  );
};
