import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import MastersTablesRepository from "../../repositories/MastersTablesRepository";
import TypeOfBusinessEntity from "../../entities/TypeOfBusinessEntity";

interface props {
    mastersTablesRepository: MastersTablesRepository;
    mastersProvider: MastersProvider;
}

@injectable()
class GetAllTypeOfBusinessesUseCase {
    _mastersTablesRepository: MastersTablesRepository;
    _mastersProvider: MastersProvider;

    constructor(_props: props) {
        this._mastersTablesRepository = _props.mastersTablesRepository;
        this._mastersProvider = _props.mastersProvider;
    }

    public call = async (): Promise<TypeOfBusinessEntity[]> => {
        if(this._mastersProvider.Actions.typesOfBusinesses.length > 0) return this._mastersProvider.Actions.typesOfBusinesses;
        const typesOfBusiness = await this._mastersTablesRepository.getTypesOfBusinesses();
        this._mastersProvider.Actions.setTypesOfBusinesses(typesOfBusiness);
        return typesOfBusiness;
    }
}

export default GetAllTypeOfBusinessesUseCase;

export const GetAllTypeOfBusinessesUseCaseName = "GetAllTypeOfBusinessesUseCase";