import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import MastersTablesRepository from "../../repositories/MastersTablesRepository";
import CityEntity from "../../entities/CityEntity";

interface props {
    mastersTablesRepository: MastersTablesRepository;
    mastersProvider: MastersProvider;
}

@injectable()
class GetAllCitiesUseCase {
    _mastersTablesRepository: MastersTablesRepository;
    _mastersProvider: MastersProvider;

    constructor(_props: props) {
        this._mastersTablesRepository = _props.mastersTablesRepository;
        this._mastersProvider = _props.mastersProvider;
    }

    public call = async (): Promise<CityEntity[]> => {
        if(this._mastersProvider.Actions.cities.length > 0) return this._mastersProvider.Actions.cities;
        const cities = await this._mastersTablesRepository.getCities();
        const zones = cities.flatMap(city => city.zones);
        const neighborhoods = zones.flatMap(zone => zone.neighborhoods);
        this._mastersProvider.Actions.setCities(cities);
        this._mastersProvider.Actions.setZones(zones);
        this._mastersProvider.Actions.setNeighborhoods(neighborhoods);
        return cities;
    }
}

export default GetAllCitiesUseCase;

export const GetAllCitiesUseCaseName = "GetAllCitiesUseCase";