import React from "react";
import ModalsContextType from "./ModalsContextType";

const ModalsContext = React.createContext<ModalsContextType>({
    //custom
    openModalCustom: (size: "lg" | "xl" | "sm" | undefined, title: string | undefined, content: any, params?: {
        closeButton?: boolean,
        hideOnTap?: boolean,
    }) => { },
    closeModalCustom: () => { },
    isOpenModalCustom: false,
    customModal: undefined,

    //delete
    openModalDelete: (title: string, content: string, warningMessage: string, keyMessage: string, onConfirm: () => void) => { },
    closeModalDelete: () => { },
    isOpenModalDelete: false,
    deleteModal: undefined,

    //create
    openModalCreate: (title: string, name: string, content: string, onConfirm: () => void) => { },
    closeModalCreate: () => { },
    isOpenModalCreate: false,
    changeContent: (title: string, name: string, content: string, onConfirm: () => void) => { },
    createModal: undefined,

    //toast
    addToast: (message: string, type: "success" | "error" | "warning" | "alert" | "default", params: any, removeAll?: boolean | undefined) => { },
});

export default ModalsContext;