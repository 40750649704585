import React from "react";
import PreferencesContextType from "./PreferencesContextType";
import ContactInfoEntity from "../../entities/ContactInfoEntity";
import PropertyEntity from "../../entities/PropertyEntity";
import CookiesEntity from "../../entities/CookiesEntity";

export const defaultPreferencesContext: PreferencesContextType = {
    contactInfo: undefined,
    setContactInfo: (contactInfo: ContactInfoEntity | undefined) => {},
    cookies: {analytics: true, marketing: true, userInterface: true},
    setCookies: (cookies: CookiesEntity) => {},
    likedProperties: [],
    setLikedProperties: (properties: PropertyEntity[]) => {},
    thisPropertyLikesMe: (property: PropertyEntity) => false,
    propertiesAsGreed: true,
    setPropertiesAsGreed: (asGreed: boolean) => {},
    refScrollableDiv: undefined,
};
const PreferencesContext = React.createContext<PreferencesContextType>(defaultPreferencesContext);

export default PreferencesContext;