import './MapsClosePropertiesComponentStyles.scss';
import { FC, useState } from "react";
import MapsClosePropertiesComponentProps from "./MapsClosePropertiesComponentProps";
import { Map, InfoWindow, AdvancedMarker } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SearchPropertiesUseCase, { SearchPropertiesUseCaseName } from "../../../../../../domain/use_cases/properties/SearchPropertiesUseCase";
import di from "../../../../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../../../../../domain/entities/PropertyEntity";
import { ClusteredPropertyMarkers } from "./cluster/ClusteredPropertiesMarkers";
import { PropertyMarker } from "./cluster/PropertyMarker";

const MapsClosePropertiesComponent: FC<MapsClosePropertiesComponentProps> = ({ property, relatedProperties, height = '100vh' }) => {

    const [showOthers, setShowOthers] = useState(false);

    if (!property?.location?.mapsLong || !property?.location?.mapsLat) return <></>;
    if (relatedProperties == undefined) return <div className="loader_preview" style={{ width: '100%', height: height }}></div>;

    return <div className="maps_close_properties_component">
        <div className="line_icon" onClick={() => setShowOthers(!showOthers)}>
            <span className="material-symbols-outlined">
                {showOthers ? 'visibility' : 'visibility_off'}
            </span>
            <span>
                {showOthers ? 'Ocultar otros inmuebles' : 'Mostrar otros inmuebles'}
            </span>
        </div>
        <Map
            style={{ width: '100%', height: height, minHeight: '300px' }}
            defaultCenter={{ lat: property.location.mapsLat, lng: property.location.mapsLong }}
            defaultZoom={13}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId={'map_of_close_properties'}
            styles={[
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }
            ]}
        >
            <ClusteredPropertyMarkers properties={relatedProperties} showOthers={showOthers} />
            <PropertyMarker property={property} onClick={() => { }} setMarkerRef={() => { }} outstanding />
        </Map>
    </div>
}

export default MapsClosePropertiesComponent;