import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import PaginateResultEntity from "../entities/PaginateResultEntity";
import PropertyEntity from "../entities/PropertyEntity";
import LocationEntity from "../entities/LocationEntity";

export default interface PropertyRepository {
    oldSearchProperties(params: {
        word: string, page: number, itemsPerPage: number,
        typeOfProperty?: string | undefined, typeOfBusiness?: string | undefined, isNew?: boolean | undefined,
        cityId?: string | undefined, zoneId?: string | undefined, neighborhoodId?: string | undefined,
        minPrice?: number | undefined, maxPrice?: number | undefined,
    }): Promise<PaginateResultEntity<PropertyEntity>>;
    searchProperties(params: {
        word: string, page: number, itemsPerPage: number,
        tagsId: string[],
        cityId?: string | undefined, zoneIds: string[], neighborhoodIds: string[],
        priceRange?: { min: number, max: number },
        rooms?: string[], restrooms?: string[],
        metersRange?: { min: number, max: number },
        garages?: string[], stratum?: string[],
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        sortBy: string
    }): Promise<PaginateResultEntity<PropertyEntity>>;
    getPropertyById(id: string): Promise<PropertyEntity | undefined>;
    likeProperty(property: PropertyEntity): Promise<Either<ExceptionEntity, void>>;
    unLikeProperty(id: string): Promise<Either<ExceptionEntity, void>>;
    getLikedProperties(): Promise<PropertyEntity[]>;
    getOutstandedAndRecentProperties(): Promise<Either<ExceptionEntity, {
        outstanded: PropertyEntity[],
        recent: PropertyEntity[]
        mostLiked: PropertyEntity[],
    }>>;
    getAllPropertiesLocations(paramsSearch: {
        word: string, page: number, itemsPerPage: number,
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        minPrice?: number | undefined, maxPrice?: number | undefined,
    }, mapLimits: {
        latMax: number, latMin: number, longMax: number, longMin: number
    }): Promise<LocationEntity[]>;
    applyToRentProperty(params: {
        message: string, propertyId: string, rentSolicitudeFile: File,
        tenant: { name: string, lastname: string, email: string, dial: string, phone: string, identification: string, identificationFile: File, laboralLetterFile: File, paymentFile: File[], }
        coDebtor: { name: string, lastname: string, email: string, dial: string, phone: string, identification: string, identificationFile: File, laboralLetterFile: File, paymentFile: File[] }
    }): Promise<Either<ExceptionEntity, void>>;
}

export const PropertyRepositoryName = "PropertyRepository";