import { injectable } from "inversify";
import ContactRepository from "../../repositories/ContactRepository";
import { Either, isLeft } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import ModalsProvider from "../../providers/modal/ModalsProvider";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";
import KeyWordLocalization from "../../../presentation/providers/localization/KeyWordLocalization";
import ContactInfoEntity from "../../entities/ContactInfoEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";

interface Props {
    contactRepository: ContactRepository;
    modalsProvider: ModalsProvider;
    localizationProvider: LocalizationProvider;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class PostContactPropertyUseCase {
    _contactRepository: ContactRepository;
    _modalsProvider: ModalsProvider;
    _localizationProvider: LocalizationProvider;
    _preferencesProvider: PreferencesProvider;

    constructor(_: Props) {
        this._contactRepository = _.contactRepository;
        this._modalsProvider = _.modalsProvider;
        this._localizationProvider = _.localizationProvider;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (contactInfo: ContactInfoEntity, subscribe: boolean, propertyId: string, savePreferences: boolean): Promise<Either<ExceptionEntity, void>> => {
        const response = await this._contactRepository.storeContactMeForProperty(contactInfo, subscribe, propertyId);
        const _savePref = async () => {
            const preferences = await this._contactRepository.storeContactPreferences(contactInfo);
            this._preferencesProvider.Actions.setContactInfo(contactInfo);
            const i18n = this._localizationProvider.Actions.i18n;
            if(isLeft(preferences)){
                this._modalsProvider.Actions.addToast(i18n(preferences.left.code ?? KeyWordLocalization.ErrorSavingPreferences), "error", null);
            }
        
        }
        if(savePreferences) _savePref();
        return response;
    }
}

export default PostContactPropertyUseCase;

export const PostContactPropertyUseCaseName = "PostContactPropertyUseCaseName";