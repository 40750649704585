import { injectable } from "inversify";
import di from "../../../dependencyInjection/DependencyInjection";
import GetAllCitiesUseCase, { GetAllCitiesUseCaseName } from "./GetAllCitiesUseCase";
import GetAllTypeOfBusinessesUseCase, { GetAllTypeOfBusinessesUseCaseName } from "./GetAllTypeOfBusinessesUseCase";
import GetAllTypeOfPropertiesUseCase, { GetAllTypeOfPropertiesUseCaseName } from "./GetAllTypeOfPropertiesUseCase";
import MastersTablesRepository from "../../repositories/MastersTablesRepository";
import MastersProvider from "../../providers/master/MastersProvider";
import CityEntity from "../../entities/CityEntity";
import NeighborhoodEntity from "../../entities/NeighborhoodEntity";
import ZoneEntity from "../../entities/ZoneEntity";
import TypeOfBusinessEntity from "../../entities/TypeOfBusinessEntity";
import TagEntity from "../../entities/TagEntity";


interface Props {
    mastersTablesRepository: MastersTablesRepository;
    mastersProvider: MastersProvider;
}

@injectable()
class GetAllMastersTablesUseCase {
    _mastersTablesRepository: MastersTablesRepository;
    _mastersProvider: MastersProvider;

    constructor(_props: Props) {
        this._mastersTablesRepository = _props.mastersTablesRepository;
        this._mastersProvider = _props.mastersProvider;
    }

    public call = async (): Promise<{
        cities: CityEntity[],
        neighborhoods: NeighborhoodEntity[],
        zones: ZoneEntity[],
        typesOfBusinesses: TypeOfBusinessEntity[],
        typesOfProperty: TypeOfBusinessEntity[]
        tags: TagEntity[]
    }> => {
        const response = await this._mastersTablesRepository.getAll();
        const {
            cities,
            neighborhoods,
            zones,
            typesOfBusinesses,
            typesOfProperty,
            tags,
        } = response;

        this._mastersProvider.Actions.setCities(cities);
        this._mastersProvider.Actions.setNeighborhoods(neighborhoods);
        this._mastersProvider.Actions.setZones(zones);
        this._mastersProvider.Actions.setTypesOfBusinesses(typesOfBusinesses);
        this._mastersProvider.Actions.setTypesOfProperties(typesOfProperty);
        this._mastersProvider.Actions.setTags(tags);
        return response;
    }
}

export default GetAllMastersTablesUseCase;

export const GetAllMastersTablesUseCaseName = "GetAllMastersTablesUseCase";