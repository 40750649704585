import { injectable } from "inversify";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import PreferencesRepository from "../../repositories/PreferencesRepository";

interface props {
    preferencesProvider: PreferencesProvider,
    preferencesRepository: PreferencesRepository,
}

@injectable()
export default class GetDefaultGridUseCase {
    _preferencesProvider: PreferencesProvider;
    _preferencesRepository: PreferencesRepository;

    constructor(private props: props) {
        this._preferencesProvider = props.preferencesProvider;
        this._preferencesRepository = props.preferencesRepository;
    }

    public call = async (): Promise<boolean> => {
        const response = await this._preferencesRepository.getSeeAsGrid();
        this._preferencesProvider.Actions.setPropertiesAsGreed(response);
        return response;
    }
}

export const GetDefaultGridUseCaseName = "GetDefaultGridUseCase";