import TypeOfBusinessEntity from "../../../domain/entities/TypeOfBusinessEntity";

const fromJSON = (data: any): TypeOfBusinessEntity => {
    return {
        id: data.fake_id,
        name: data.name,
        color: data.name == 'sell' ? 'var(--color-orange)' : 'var(--color-blue)',
        colorHex: data.name == 'sell' ? '#E76C30' : '#4C959E',
    }
}

export default {
    fromJSON,
}