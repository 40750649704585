import { useEffect } from 'react';
import './App.scss';
import "bootstrap/dist/css/bootstrap.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-multi-carousel/lib/styles.css';
import 'react-phone-number-input/style.css'
// import { Provider } from "inversify-react";
import RoutesComponent from './presentation/routes/RoutesComponent';
import { HelmetProvider } from "react-helmet-async";
import di from './dependencyInjection/DependencyInjection';
import LocalizationProvider, { LocalizationProviderName } from './domain/providers/localization/LocalizationProvider';
import MastersProvider, { MastersProviderName } from './domain/providers/master/MastersProvider';
import ModalsProvider, { ModalsProviderName } from './domain/providers/modal/ModalsProvider';
import PreferencesProvider, { PreferencesProviderName } from './domain/providers/preferences/PreferencesProvider';
import UserProvider, { UserProviderName } from './domain/providers/user/UserProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import Constants, { ConstantsName } from './data/settings/constants/Constants';

const App = () => {
  const localizationProvider = di.get<LocalizationProvider>(LocalizationProviderName);
  const mastersProvider = di.get<MastersProvider>(MastersProviderName);
  const modalsProvider = di.get<ModalsProvider>(ModalsProviderName);
  const preferencesProvider = di.get<PreferencesProvider>(PreferencesProviderName);
  const userProvider = di.get<UserProvider>(UserProviderName);
  return <HelmetProvider>
    <APIProvider apiKey={di.get<Constants>(ConstantsName).GOOGLE_MAPS_API_KEY}>
      <localizationProvider.Provider>
        <modalsProvider.Provider>
          <mastersProvider.Provider>
            <preferencesProvider.Provider>
              <userProvider.Provider>
                <RoutesComponent />
              </userProvider.Provider>
            </preferencesProvider.Provider>
          </mastersProvider.Provider>
        </modalsProvider.Provider>
      </localizationProvider.Provider>
    </APIProvider>
  </HelmetProvider>

}

export default App;
