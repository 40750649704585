import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";
import di from "../../../../../dependencyInjection/DependencyInjection";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const ApplyToRentPropertyApiImpl = async (params: {
    message: string; propertyId: string; rentSolicitudeFile: File;
    tenant: { name: string; lastname: string; identification: string; email: string; dial: string; phone: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; };
    coDebtor: { name: string; lastname: string; identification: string; email: string; dial: string; phone: string; identificationFile: File; laboralLetterFile: File; paymentFile: File[]; };
}): Promise<Either<ExceptionEntity, void>> => {
    try {
        const relativeUrl = "/applyToRentProperty";
        const body = {
            "name": params.tenant.name,
            "lastname": params.tenant.lastname,
            "identification": params.tenant.identification,
            "email": params.tenant.email,
            "phone": params.tenant.phone,
            "person_identification_file": params.tenant.identificationFile, //FILE
            "person_working_letter_file": params.tenant.laboralLetterFile, //FILE
            "person_removable_payment_files": params.tenant.paymentFile, //FILE
            "person_lease_application": params.rentSolicitudeFile, //FILE
            "cosigner_name": params.coDebtor.name,
            "cosigner_email": params.coDebtor.email,
            "cosigner_phone": params.coDebtor.phone,
            "cosigner_identification_file": params.coDebtor.identification, //FILE
            "cosigner_working_letter_file": params.coDebtor.laboralLetterFile, //FILE
            "cosigner_removable_payment_files": params.coDebtor.paymentFile, //FILE
            "subscribe": params.message,
            "content": params.message,
        }
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body);
        return response;
    } catch (e) {
        return left(ExceptionEntityFromError(e));
    }
}

export default ApplyToRentPropertyApiImpl