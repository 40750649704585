import PaginateResultEntity from '../../../domain/entities/PaginateResultEntity';
import PropertyEntity from '../../../domain/entities/PropertyEntity';
import SearcherBarComponent from '../../components/searcherBar/SearcherBarComponent';
import './PropertiesPageStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import SideFilterComponent, { propertiesKeysFilters } from './components/sideFilter/SideFilterComponent';
import { useForm } from 'react-hook-form';
import DeleteFilterComponent from './components/deleteFilter/DeleteFilterComponent';
import { MdClearAll } from 'react-icons/md';
import di from '../../../dependencyInjection/DependencyInjection';
import CardPropertyComponent from '../../components/cardProperty/CardPropertyComponent';
import PaginatorComponent from '../../components/paginator/PaginatorComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import NoResultsPropertiesCompontent from './components/noResults/NoResultsPropertiesCompontent';
import PreferencesContext from '../../../domain/providers/preferences/PreferencesContext';
import PreferencesContextType from '../../../domain/providers/preferences/PreferencesContextType';
import SetDefaultGridUseCase, { SetDefaultGridUseCaseName } from '../../../domain/use_cases/preferences/SetDefaultGridUseCase';
import { useNavigate, useParams } from 'react-router-dom';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';
import StringUtils from '../../utils/StringUtils';
import MastersContext from '../../../domain/providers/master/MastersContext';
import MastersContextType from '../../../domain/providers/master/MastersContextType';
import { routes } from '../../routes/Routes';
import SearchPropertiesUseCase, { SearchPropertiesUseCaseName } from '../../../domain/use_cases/properties/SearchPropertiesUseCase';
import ZoneEntity from '../../../domain/entities/ZoneEntity';
import NeighborhoodEntity from '../../../domain/entities/NeighborhoodEntity';
import { AutoCompleteItem } from '../../components/form/autocompleteWithCheck/AutoCompleteWithCheckProps';
import PropertiesMetaTagComponent from '../../seo/metas/PropertiesMetaTagComponent';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const PropertiesPage: FC<{}> = () => {
    const { businesses, types, city } = useParams();
    const { propertiesAsGreed } = useContext(PreferencesContext) as PreferencesContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { typesOfBusinesses, typeOfProperties, tags, cities, neighborhoods, zones } = useContext(MastersContext) as MastersContextType;
    const [loading, setLoading] = useState(true);
    const formInfo = useForm();
    const navigate = useNavigate();
    const { register, handleSubmit, getValues, setValue, watch, reset, formState: { errors } } = formInfo;
    const [resultSearch, setResultSearch] = useState<PaginateResultEntity<PropertyEntity>>({
        data: [],
        page: 1,
        total: 0,
        totalPage: 0
    });
    const [modal, setModal] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const wordQuery = queryParams.get(i18n(propertiesKeysFilters.word));
    const pageQuery = queryParams.get(i18n(propertiesKeysFilters.page));
    const _getTitlePage = (): string => {
        let typeBusinesses, typeProperty;

        if (!types || types?.split('-').length != 1) {
            typeProperty = i18n(KeyWordLocalization.SEOProperty);
        } else if (typeOfProperties.some((type) => i18n(type.name).toLowerCase() == types?.split('-')[0].toLowerCase())) {
            const type = typeOfProperties.find((type) => i18n(type.name).toLowerCase() == types?.split('-')[0].toLowerCase());
            typeProperty = i18n(type?.name + "Plural");
        } else {
            typeProperty = i18n(KeyWordLocalization.SEOProperty);
        }

        if (businesses?.split('-').length == 1 && typesOfBusinesses.some((type) => i18n(type.name).toLowerCase() == businesses?.split('-')[0].toLowerCase())) {
            typeBusinesses = i18n(businesses!.split('-')[0]);
        }

        if (typeBusinesses && city) return i18n(KeyWordLocalization.SEOImagesPropertyInBusinessWithCity, {
            property: typeProperty, business: typeBusinesses, city: city
        });
        else if (typeBusinesses) return i18n(KeyWordLocalization.SEOImagesPropertyInBusiness, {
            property: typeProperty, business: typeBusinesses,
        });
        else if (city) return typeProperty + ' ' + i18n(KeyWordLocalization.SEOImagePropertyCity, {
            property: typeProperty, city: city
        });

        else if (types) return i18n(KeyWordLocalization.SEOPropertiesPropertyAvailables, {
            property: typeProperty,
        });

        else return i18n(KeyWordLocalization.SEOPropertiesAvailables);
    }

    const toggle = () => setModal(!modal);

    const _onValueChange = () => {
        // const values = getValues();
        const values = getValues();
        //convert values to query
        const urlParams = new URLSearchParams();

        if (values[propertiesKeysFilters.area.key]?.min || values[propertiesKeysFilters.area.key]?.max) urlParams.append(i18n(propertiesKeysFilters.area.key), values[propertiesKeysFilters.area.key].min + '-' + values[propertiesKeysFilters.area.key].max);
        if (values[propertiesKeysFilters.restrooms.key]) urlParams.append(i18n(propertiesKeysFilters.restrooms.key), values[propertiesKeysFilters.restrooms.key].split(',').join('-'));
        if (values[propertiesKeysFilters.garages.key]) urlParams.append(i18n(propertiesKeysFilters.garages.key), values[propertiesKeysFilters.garages.key].split(',').join('-'));
        if (values[propertiesKeysFilters.price.key]?.min || values[propertiesKeysFilters.price.key]?.max) urlParams.append(i18n(propertiesKeysFilters.price.key), values[propertiesKeysFilters.price.key].min + '-' + values[propertiesKeysFilters.price.key].max);
        if (values[propertiesKeysFilters.rooms.key]) urlParams.append(i18n(propertiesKeysFilters.rooms.key), values[propertiesKeysFilters.rooms.key].split(',').join('-'));
        if (values[propertiesKeysFilters.sort.key]) urlParams.append(i18n(propertiesKeysFilters.sort.key), values[propertiesKeysFilters.sort.key].split(',').join('-'));
        if (values[propertiesKeysFilters.status]) urlParams.append(i18n(propertiesKeysFilters.status), values[propertiesKeysFilters.status].split(',').join('-'));
        if (values[propertiesKeysFilters.stratum.key]) urlParams.append(i18n(propertiesKeysFilters.stratum.key), values[propertiesKeysFilters.stratum.key].split(',').join('-'));
        if (values[propertiesKeysFilters.tags]) {
            const idsTags = values[propertiesKeysFilters.tags].split(',');
            const tagsSelected = tags.filter((tag) => idsTags.includes(tag.id));
            urlParams.append(i18n(propertiesKeysFilters.tags), tagsSelected.map((tag) => i18n(tag.name)).join('-'));
        }
        if (values[propertiesKeysFilters.page]) urlParams.append(i18n(propertiesKeysFilters.page), values[propertiesKeysFilters.page]);
        if (values[propertiesKeysFilters.zones]) urlParams.append(i18n(propertiesKeysFilters.zones), values[propertiesKeysFilters.zones].map((neig: any) => neig.label).join('-'));
        if (values[propertiesKeysFilters.neighborhoods]) urlParams.append(i18n(propertiesKeysFilters.neighborhoods), values[propertiesKeysFilters.neighborhoods].map((neig: any) => neig.label).join('-'));
        if (values[propertiesKeysFilters.city] && values[propertiesKeysFilters.city] != city) {
            const cityFound = cities.find((city) => city.id == values[propertiesKeysFilters.city]);
            navigate(routes.properties.pathTo(businesses, types, cityFound?.name) + '?' + urlParams.toString(), {
                replace: true
            });
            _handleSearch();
        } else {
            _handleSearch();
            navigate(routes.properties.pathTo(businesses, types, city) + '?' + urlParams.toString(), {
                replace: true
            });
        }
    }

    const _handleSearch = async () => {
        if (typeOfProperties.length == 0 || tags.length == 0 || cities.length == 0 || typesOfBusinesses.length == 0) return;
        setLoading(true);
        const vals = getValues();
        console.log('prev', vals)
        const rooms = vals[propertiesKeysFilters.rooms.key] && vals[propertiesKeysFilters.rooms.key]?.split(',');
        const stratum = vals[propertiesKeysFilters.stratum.key] && vals[propertiesKeysFilters.stratum.key]?.split(',');
        const restrooms = vals[propertiesKeysFilters.restrooms.key] && vals[propertiesKeysFilters.restrooms.key]?.split(',');
        const garages = vals[propertiesKeysFilters.garages.key] && vals[propertiesKeysFilters.garages.key]?.split(',');
        const status = vals[propertiesKeysFilters.status] && vals[propertiesKeysFilters.status]?.split(',');
        const result = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
            word: vals[propertiesKeysFilters.word],
            page: vals[propertiesKeysFilters.page] ?? 1,
            itemsPerPage: propertiesKeysFilters.itemsPerPage,
            typeOfPropertiesId: vals[propertiesKeysFilters.typeProperties] && vals[propertiesKeysFilters.typeProperties].map((type: any) => type.id),
            typeOfBusinessId: vals[propertiesKeysFilters.typeBusinesses] && vals[propertiesKeysFilters.typeBusinesses].map((type: any) => type.id),
            isNew: status.length == 2 || status.length == 0 ? undefined : status.includes('new'),
            cityId: vals[propertiesKeysFilters.city],
            zoneIds: vals[propertiesKeysFilters.zones] ? vals[propertiesKeysFilters.zones]?.map((zone: ZoneEntity) => zone.id) : [],
            neighborhoodIds: vals[propertiesKeysFilters.neighborhoods] ? vals[propertiesKeysFilters.neighborhoods]?.map((neighborhood: NeighborhoodEntity) => neighborhood.id) : [],
            priceRange: vals[propertiesKeysFilters.price.key] && vals[propertiesKeysFilters.price.key].max && (vals[propertiesKeysFilters.price.key].max - vals[propertiesKeysFilters.price.key].min) != (propertiesKeysFilters.price.max - propertiesKeysFilters.price.min) ? vals[propertiesKeysFilters.price.key] : undefined,
            rooms: rooms.length != 0 && rooms.length != propertiesKeysFilters.rooms.maxToShow ? rooms : undefined,
            stratum: stratum.length != 0 && stratum.length != propertiesKeysFilters.stratum.maxToShow ? stratum : undefined,
            restrooms: restrooms.length != 0 && restrooms.length != propertiesKeysFilters.restrooms.maxToShow ? restrooms : undefined,
            garages: garages.length != 0 && garages.length != propertiesKeysFilters.garages.maxToShow ? garages : undefined,
            metersRange: vals[propertiesKeysFilters.area.key] && vals[propertiesKeysFilters.area.key].max && (vals[propertiesKeysFilters.area.key].max - vals[propertiesKeysFilters.area.key].min) != (propertiesKeysFilters.area.max - propertiesKeysFilters.area.min) ? vals[propertiesKeysFilters.area.key] : undefined,
            tagsId: vals[propertiesKeysFilters.tags]?.split(',').map((tag: string) => tag),
            sortBy: vals[propertiesKeysFilters.sort.key],
        });
        console.log('result of search', result);
        setLoading(false);
        setResultSearch(result);

    }


    const _loadValues = () => {
        console.log('load value is been called', { types, city, businesses });
        if (queryParams.has(i18n(propertiesKeysFilters.area.key))) {
            const area = queryParams.get(i18n(propertiesKeysFilters.area.key))!.split('-');
            setValue(propertiesKeysFilters.area.key, { min: parseInt(area[0]), max: parseInt(area[1]) });
        }
        if (queryParams.has(i18n(propertiesKeysFilters.restrooms.key))) {
            setValue(propertiesKeysFilters.restrooms.key, queryParams.get(i18n(propertiesKeysFilters.restrooms.key))!.split('-').join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.garages.key))) {
            setValue(propertiesKeysFilters.garages.key, queryParams.get(i18n(propertiesKeysFilters.garages.key))!.split('-').join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.price.key))) {
            const price = queryParams.get(i18n(propertiesKeysFilters.price.key))!.split('-');
            setValue(propertiesKeysFilters.price.key, { min: parseInt(price[0]), max: parseInt(price[1]) });
        }
        if (queryParams.has(i18n(propertiesKeysFilters.rooms.key))) {
            setValue(propertiesKeysFilters.rooms.key, queryParams.get(i18n(propertiesKeysFilters.rooms.key))!.split('-').join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.sort.key))) {
            setValue(propertiesKeysFilters.sort.key, queryParams.get(i18n(propertiesKeysFilters.sort.key))!);
        }
        if (queryParams.has(i18n(propertiesKeysFilters.status))) {
            setValue(propertiesKeysFilters.status, queryParams.get(i18n(propertiesKeysFilters.status))!.split('-').join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.stratum.key))) {
            setValue(propertiesKeysFilters.stratum.key, queryParams.get(i18n(propertiesKeysFilters.stratum.key))!.split('-').join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.tags))) {
            const tagsSelected = queryParams.get(i18n(propertiesKeysFilters.tags))!.split('-');
            const tagsIds = tags.filter((tag) => tagsSelected.includes(i18n(tag.name))).map((tag) => tag.id);
            setValue(propertiesKeysFilters.tags, tagsIds.join(','));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.page))) {
            setValue(propertiesKeysFilters.page, queryParams.get(i18n(propertiesKeysFilters.page)));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.zones))) {
            const zonesQuery = queryParams.get(i18n(propertiesKeysFilters.zones))!.split('-');
            const realZones = zones.filter((zone) => zonesQuery.some((zoneQuery) => zone.name.toLowerCase() == zoneQuery.toLowerCase()));
            setValue(propertiesKeysFilters.zones, realZones.map<AutoCompleteItem<ZoneEntity>>((zone) => {
                return {
                    aditionalValue: zone,
                    id: zone.id,
                    label: zone.name
                }
            }));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.neighborhoods))) {
            const neighborhoodsQury = queryParams.get(i18n(propertiesKeysFilters.neighborhoods))!.split('-');
            const realNeighborhoods = neighborhoods.filter((neighborhood) => neighborhoodsQury.some((neighborhoodQuery) => neighborhood.name.toLowerCase() == neighborhoodQuery.toLowerCase()));
            setValue(propertiesKeysFilters.neighborhoods, realNeighborhoods.map<AutoCompleteItem<NeighborhoodEntity>>((neighborhood) => {
                return {
                    aditionalValue: neighborhood,
                    id: neighborhood.id,
                    label: neighborhood.name
                }
            }));
        }
        if (queryParams.has(i18n(propertiesKeysFilters.word))) {
            setValue(propertiesKeysFilters.word, queryParams.get(i18n(propertiesKeysFilters.word)));
        }else {
            setValue(propertiesKeysFilters.word, undefined);
        }
        if (city) {
            const cityFound = cities.find((cityI) => cityI.name.toLowerCase() == city.toLowerCase());
            setValue(propertiesKeysFilters.city, cityFound?.id);
        }else{
            setValue(propertiesKeysFilters.city, undefined);
        }
        if (types) {
            const typesParams = types.split('-');
            const typesAvailable = typeOfProperties.filter((type) => typesParams.some((paramType) => i18n(type.name).toLowerCase() == paramType.toLowerCase()));
            const parsedTypes = typesAvailable.map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));
            setValue(propertiesKeysFilters.typeProperties, parsedTypes);
        } else {
            setValue(propertiesKeysFilters.typeProperties, []);
        }
        if (businesses) {
            const businessesParam = businesses.split('-');
            const typesAvailable = typesOfBusinesses.filter((type) => businessesParam.some((paramType) => i18n(type.name).toLowerCase() == paramType.toLowerCase()));
            const parsedTypes = typesAvailable.map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));
            setValue(propertiesKeysFilters.typeBusinesses, parsedTypes);
        } else {
            setValue(propertiesKeysFilters.typeBusinesses, []);
        }

        _handleSearch();
    }

    const _handleClearFilter = () => {
        const values = getValues();
        Object.keys(values).forEach((key) => {
            setValue(key, '');
        });
        navigate(routes.properties.pathTo(businesses, types), {
            replace: true
        });
    }


    const _handleChangeGrid = (value: boolean) => di.get<SetDefaultGridUseCase>(SetDefaultGridUseCaseName).call(value);

    const _searcherChangeValue = (key: string, value: any) => {
        setValue(key, value);
        _onValueChange();
    }

    useEffect(() => {
        _loadValues();
    }, [city, types, businesses, cities, typeOfProperties, typesOfBusinesses, tags, wordQuery, pageQuery]);

    // Close the modal if the screen is bigger
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width >= 768 && width <= 1028 && modal) {
                toggle(); // Close the window if the window is in range and is open
            }
        };

        window.addEventListener('resize', handleResize);

        // Limpieza al desmontar el componente
        return () => window.removeEventListener('resize', handleResize);
    }, [modal, toggle]);

    if (typeOfProperties.length == 0 && typesOfBusinesses.length == 0 && tags.length == 0) return <LoadingComponent fullScreen showLogo />;
    if (typeOfProperties.length == 0 && typesOfBusinesses.length == 0 && tags.length == 0) return <LoadingComponent fullScreen showLogo />;
    return <>
        <PropertiesMetaTagComponent />
        <div className="properties_page">
            <SearcherBarComponent onChangeValue={_searcherChangeValue} />
            <form onSubmit={handleSubmit(_handleSearch)}>
                <div className="w-100 bg_white">
                    <div className="container">
                        <div className="row py-3 d-flex align-items-end align-items-md-start">
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center mt-3">
                                <h1 className="title_h1">{StringUtils.makeFirstLetterUpperCase(_getTitlePage())}</h1>
                                <h2 className='mini_title_h2'>{i18n(KeyWordLocalization.TRADE_MARK)}</h2>
                            </div>

                            <></><div className="col-12 col-md-3 pl-md-0 d-none d-md-block">
                                <label>{i18n(KeyWordLocalization.PropertiesPageSeeIn)} </label>
                                <div className="dropdown_hover dropdown_grid">
                                    <div className="dropdown_title form-control" onClick={() => _handleChangeGrid(!propertiesAsGreed)}>
                                        {propertiesAsGreed ? <div className="d-flex align-items-center"><span className="material-symbols-outlined">apps</span> {i18n(KeyWordLocalization.PropertiesPageGrid)}</div> : <div className="d-flex align-items-center"><span className="material-symbols-outlined">list</span> {i18n(KeyWordLocalization.PropertiesPageList)}</div>}
                                    </div>
                                    <div className="white_space_content"></div>
                                    <div className="dropdown_content text-capitalize form-control">
                                        <div className="dropdown-item d-flex align-items-center" onClick={() => _handleChangeGrid(true)}><span className="material-symbols-outlined">apps</span> {i18n(KeyWordLocalization.PropertiesPageGrid)}</div>
                                        <div className="dropdown-item d-flex align-items-center" onClick={() => _handleChangeGrid(false)}><span className="material-symbols-outlined">list</span> {i18n(KeyWordLocalization.PropertiesPageList)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 col-md-3 pl-md-0">
                                <label>{`${i18n(KeyWordLocalization.PropertiesPageOrderBy)}:`}</label>
                                <select className="form-control" {...register(propertiesKeysFilters.sort.key, {
                                    onChange: _onValueChange
                                })}>
                                    <option value="">{i18n(KeyWordLocalization.PropertiesPageFeatured)}</option>
                                    <option value={propertiesKeysFilters.sort.values.newest}>{i18n(KeyWordLocalization.PropertiesPageMoreRecent)}</option>
                                    <option value={propertiesKeysFilters.sort.values.oldest}>{i18n(KeyWordLocalization.PropertiesPageMoreAncient)}</option>
                                    <option value={propertiesKeysFilters.sort.values.price_asc}>{i18n(KeyWordLocalization.PropertiesPagePriceAsc)}</option>
                                    <option value={propertiesKeysFilters.sort.values.price_desc}>{i18n(KeyWordLocalization.PropertiesPagePriceDesc)}</option>
                                    <option value={propertiesKeysFilters.sort.values.more_liked}>{i18n(KeyWordLocalization.PropertiesPageMoreLiked)}</option>
                                    <option value={propertiesKeysFilters.sort.values.more_seen}>{i18n(KeyWordLocalization.PropertiesPageMoreSeen)}</option>
                                </select>
                            </div>
                            <div className="col-4 col-md-3 d-block d-md-none">
                                <div className='mbtn btn_orange w-100 btn_set_filters' onClick={toggle}>
                                    <span className="material-symbols-outlined">
                                        tune
                                    </span> Filtrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg_light">
                    <div className="container">
                        <div className="row">
                            <Modal isOpen={modal} toggle={toggle} fullscreen>
                                <ModalHeader toggle={toggle}>Filtros</ModalHeader>
                                <ModalBody>
                                    <SideFilterComponent formFunctions={formInfo} onChangeValueFilter={_onValueChange} toggleModal={toggle} />
                                </ModalBody>
                            </Modal>
                            <div className="col-0 col-md-3 d-none d-md-block">
                                <SideFilterComponent formFunctions={formInfo} onChangeValueFilter={_onValueChange} />
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="w-100 d-flex flex-column-reverse flex-md-row my-3">
                                    <div className="flex-grow-1">
                                        <DeleteFilterComponent formFunctions={formInfo} onClearFilters={_onValueChange} />
                                    </div>
                                    {Object.values(getValues()).some((value: any) => {
                                        if (Array.isArray(value)) return value.filter((vl) => vl != '').length > 0;
                                        if (typeof value == "string") return value != '';
                                        if (typeof value == "object") return value?.min || value?.max;
                                        return false;
                                    }) ? <div className="mbtn hover btn_orange_outline btn_filters mb-3" onClick={_handleClearFilter}>
                                        <MdClearAll size={20} />
                                        {i18n(KeyWordLocalization.PropertiesPageClearAllFilters)}</div> : ''}
                                </div>
                                {!loading && resultSearch.data.length === 0 && <NoResultsPropertiesCompontent />}
                                <div className="row d-flex align-items-stretch">
                                    {(loading || resultSearch.data.length > 0) && (resultSearch.data.length > 0 ? resultSearch.data : Array(propertiesKeysFilters.itemsPerPage).fill(undefined)).map((property, index) => <div key={index} className={propertiesAsGreed ? "col-12 col-sm-6 col-md-6 col-lg-4" : "col-12 list"} >
                                        <CardPropertyComponent seeAsGrid={propertiesAsGreed} property={property} />
                                    </div>)}
                                </div>
                                <div className="w-100 pb-3">
                                    <PaginatorComponent resultData={resultSearch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>
}

export default PropertiesPage;