import { FC, useContext } from "react";
import { MdPhone, MdWhatsapp, MdMail } from "react-icons/md";
import LocalizationContext from "../../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../../../../providers/localization/KeyWordLocalization";
import ContactPersonItemComponentProps from "./ContactPersonItemComponentProps";

const ContactPersonItemComponent: FC<ContactPersonItemComponentProps> = ({ person, phone2 }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const prefix = Intl.DateTimeFormat().resolvedOptions().timeZone == "America/Bogota" ? '' : '+57';
    const _getPhoneLink = (phone: string) => `tel:${prefix}${phone.replace(/ /g, '')}`;
    const _getWhatsappLink = (phone: string) => `https://wa.me/57${phone.replace(/ /g, '')}?text=${i18n(KeyWordLocalization.ContactPropertyComponentWhatsAppLink, {
        link: window.location.href
    })}`;
    const _getMailLink = (mail: string) => `mailto:${mail}?subject=${i18n(KeyWordLocalization.ContactPropertyComponentMailSubject)}&body=${i18n(KeyWordLocalization.ContactPropertyComponentMailBody, {
        link: window.location.href
    })}`;
    return <div className="contact_box_call mb-3">
        <strong>{person.fullname}</strong>
        <div className={`${prefix != "" && 'flex-row-reverse'} d-flex mb-2 mt-1`}>
            <div className={`mbtn btn_orange_outline ${prefix == "" && "flex-grow-1"}`}>
                <a href={(_getPhoneLink(person.cellphone))}>
                    <MdPhone size={20} /> {prefix == "" && person.cellphone}
                </a>
            </div>
            <div className="divider" style={{ width: '0.5em', height: '100%' }}></div>
            <div className={`mbtn btn_green_outline ${prefix != "" && "flex-grow-1"}`}>
                <a href={(_getWhatsappLink(person.cellphone))}>
                    <MdWhatsapp size={20} /> {prefix != "" && (prefix + ' ' + person.cellphone)}
                </a>
            </div>
        </div>
        {phone2 && <div className={`${prefix != "" && 'flex-row-reverse'} d-flex my-2`}>
            <div className={`mbtn btn_orange_outline ${prefix == "" && "flex-grow-1"}`}>
                <a href={(_getPhoneLink(phone2))}>
                    <MdPhone size={20} /> {prefix == "" && phone2}
                </a>
            </div>
            <div className="divider" style={{ width: '0.5em', height: '100%' }}></div>
            <div className={`mbtn btn_green_outline ${prefix != "" && "flex-grow-1"}`}>
                <a href={(_getWhatsappLink(phone2))}>
                    <MdWhatsapp size={20} /> {prefix != "" && (prefix + ' ' + phone2)}
                </a>
            </div>
        </div>}
        <div className="w-100 mbtn btn_orange_outline">
            <a href={_getMailLink(person.email)} >
                <MdMail size={20} /> {person.email}
            </a>
        </div>
    </div>
}

export default ContactPersonItemComponent;