/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the implementation of the Constants interface, and it is used to store the
 * constants of the dev enviroment
 ********************************************************************************/

import Constants from "./Constants";

const ConstantsImpl: Constants = {
    HOST_URL: "https://lac.com.co/api",
    HOST_IMAGE_URL: "https://lac.com.co",
    DEFAULT_IMAGE_URL: "https://lac.com.co/data/properties/MQ==-Paraiso_de_comfandi_C/frtjen1QuBMkPvTk7Gt4VVPuA3Cr3AFKpyJhoIRN.jpg",
    GOOGLE_MAPS_API_KEY: "AIzaSyAgzTsrnQSU6kg_3BCwXGEPMoJeNuaoxzM",
}

export default ConstantsImpl;
