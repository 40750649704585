import './LayoutComponent.scss';
import { FC, useContext, useEffect } from "react";
import LayoutComponentProps from "./LayoutComponentProps";
import { useLocation } from 'react-router-dom';
import HeaderComponent from './header/HeaderComponent';
import FooterComponent from './footer/FooterComponent';
import PreferencesContext from '../../../domain/providers/preferences/PreferencesContext';
import PreferencesContextType from '../../../domain/providers/preferences/PreferencesContextType';

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
    const { pathname } = useLocation();
    const {refScrollableDiv} = useContext(PreferencesContext) as PreferencesContextType;

    useEffect(() => {
        console.log('scrolling to top', pathname);
        refScrollableDiv?.current?.scrollTo(0, 0);
    }, [pathname]);

    return <div className='layout_component'>
        <HeaderComponent />
        <div className="scrolled_layout" id="scrolled_layout" ref={refScrollableDiv}>
            <div className="content_layout">
                {children}
            </div>
            <FooterComponent />
        </div>
    </div>
}

export default LayoutComponent;